var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
var BDollar = function (props) {
    return (React.createElement(SvgIcon, __assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 140 140", width: 140, height: 140 }, props, { key: "bdollar" }),
        React.createElement("defs", null,
            React.createElement("linearGradient", { id: "bdollar_gradient", gradientUnits: "userSpaceOnUse", x1: 11.546, y1: 67.643, x2: 96.123, y2: 67.643, gradientTransform: "translate(16.165 2.358)" },
                React.createElement("stop", { offset: 0, stopColor: "#ff9c00" }),
                React.createElement("stop", { offset: 1, stopColor: "#ffe226" }))),
        React.createElement("circle", { cx: 70, cy: 70, r: 70, fill: "#fff" }),
        React.createElement("path", { fill: "url(#bdollar_gradient)", d: "M70.095 39.016H58.533v23.078h11.562c11.752.058 21.044 10.715 18.774 22.885-1.425 7.644-7.574 13.782-15.219 15.198-10.237 1.896-19.386-4.388-22.065-13.425a19.123 19.123 0 01-.799-5.449V37.6c0-11.703-10.331-21.19-23.075-21.19v64.891c0 1.337.07 2.657.192 3.963 2.12 22.697 22.246 40.245 45.915 38.157 20.561-1.813 37.029-18.69 38.378-39.287 1.609-24.56-17.89-45.064-42.101-45.118z" })));
};
export default BDollar;
