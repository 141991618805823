var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { memoizeOne } from 'memoize-one';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { setSummaryData } from '../store/actions';
import Wallets from './Wallets';
import { accountFromCurrentPath } from './routes';
import { calculateNetProfit, getHeaders, isValidAddress } from './utils';
import { API_BASE_URL } from './constants';
var useStyles = makeStyles(function (theme) {
    return createStyles({
        paper: {
            marginBottom: theme.spacing(1),
            padding: theme.spacing(3),
        },
    });
});
var getIntlDateFormat = function (locale) {
    if (locale === void 0) { locale = 'en-US'; }
    return new Intl.DateTimeFormat(locale);
};
export var memoizedIntlDateFormat = memoizeOne(getIntlDateFormat);
var getIntlTimeFormat = function (locale) {
    if (locale === void 0) { locale = 'en-US'; }
    return new Intl.DateTimeFormat(locale, {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
    });
};
export var memoizedIntlTimeFormat = memoizeOne(getIntlTimeFormat);
;
var TimeMachine = function (props) {
    var _a;
    var enqueueSnackbar = useSnackbar().enqueueSnackbar;
    var classes = useStyles();
    var locale = props.settings.locale || 'en-US';
    var t = useTranslation().t;
    var _b = useState(false), loadingAvailableSnapshots = _b[0], setLoadingAvailableSnapshots = _b[1];
    var _c = useState([]), availableSnapshots = _c[0], setAvailableSnapshots = _c[1];
    var _d = useState(undefined), selectedSnapshotDayIndex = _d[0], setSelectedSnapshotDayIndex = _d[1];
    var _e = useState(undefined), selectedSnapshotTimeIndex = _e[0], setSelectedSnapshotTimeIndex = _e[1];
    var _f = useState(false), loadingSnapshots = _f[0], setLoadingSnapshots = _f[1];
    var _g = useState(undefined), accountData = _g[0], setAccountData = _g[1];
    useEffect(function () {
        var account = accountFromCurrentPath();
        if (isValidAddress(account)) {
            setLoadingAvailableSnapshots(true);
            fetch(API_BASE_URL + "/accounts/" + account + "/snapshots", { headers: getHeaders(props.connectedAccounts, account) })
                .then(function (response) { return response.json(); })
                .then(function (data) {
                if (data.success) {
                    var lastDay_1 = undefined;
                    var snapshots_1 = [];
                    data.result.sort().map(function (timestamp) {
                        var date = new Date(timestamp * 1000);
                        var dateOnly = new Date(date.toDateString());
                        if (lastDay_1 == undefined || lastDay_1.date.getTime() != dateOnly.getTime()) {
                            lastDay_1 = {
                                date: dateOnly,
                                times: []
                            };
                            snapshots_1.push(lastDay_1);
                        }
                        lastDay_1.times.push({
                            timestamp: timestamp,
                            date: date
                        });
                    });
                    setAvailableSnapshots(snapshots_1);
                }
                else {
                    enqueueSnackbar(t(data.message), { variant: 'error' });
                }
                setLoadingAvailableSnapshots(false);
            })
                .catch(function () {
                setLoadingAvailableSnapshots(false);
                enqueueSnackbar(t('Error retrieving snapshots, please try again'), { variant: 'error' });
            });
        }
    }, []);
    useEffect(function () {
        var account = accountFromCurrentPath();
        if (isValidAddress(account) && selectedSnapshotDayIndex !== undefined && selectedSnapshotTimeIndex !== undefined) {
            setLoadingSnapshots(true);
            var selectedSnapshotTime = availableSnapshots[selectedSnapshotDayIndex].times[selectedSnapshotTimeIndex];
            fetch(API_BASE_URL + "/accounts/" + account + "/snapshots/" + selectedSnapshotTime.timestamp, { headers: getHeaders(props.connectedAccounts, account) })
                .then(function (response) { return response.json(); })
                .then(function (data) {
                if (data.success) {
                    var accountData_1 = calculateNetProfit(data.result);
                    setAccountData(accountData_1);
                    props.setSummaryData(accountData_1.wallets, accountData_1.conversionRates, true);
                }
                else {
                    enqueueSnackbar(t(data.message), { variant: 'error' });
                }
                setLoadingSnapshots(false);
            })
                .catch(function () {
                setLoadingSnapshots(false);
                enqueueSnackbar(t('Error retrieving snapshot data, please try again'), { variant: 'error' });
            });
        }
    }, [selectedSnapshotTimeIndex]);
    var handleChangeDay = function (event) {
        setSelectedSnapshotTimeIndex(undefined);
        setSelectedSnapshotDayIndex(event.target.value);
    };
    var handleChangeTime = function (event) {
        if (event.target.value == "" || event.target.value == undefined) {
            setSelectedSnapshotTimeIndex(undefined);
            return;
        }
        setSelectedSnapshotTimeIndex(event.target.value);
    };
    var snapshotDayString = function (snapshot, locale) {
        var dateTimeFormat = memoizedIntlDateFormat(locale);
        return dateTimeFormat.format(snapshot.date);
    };
    var snapshotTimeString = function (time, locale) {
        var dateTimeFormat = memoizedIntlTimeFormat(locale);
        return dateTimeFormat.format(time.date);
    };
    return (React.createElement(Box, null,
        loadingAvailableSnapshots && React.createElement(Box, { p: 3, width: "100%", textAlign: "center" },
            React.createElement(CircularProgress, null)),
        !loadingAvailableSnapshots && (React.createElement(Box, { px: 1 },
            React.createElement(Paper, { className: classes.paper },
                React.createElement(Grid, { container: true, spacing: 2 },
                    React.createElement(Grid, { item: true, xs: 6 },
                        React.createElement(FormControl, { className: classes.formControl, fullWidth: true },
                            React.createElement(InputLabel, { htmlFor: "id-snapshot-day" }, t('Snapshot Day')),
                            React.createElement(NativeSelect, { fullWidth: true, value: selectedSnapshotDayIndex, onChange: handleChangeDay, input: React.createElement(Input, { name: "snapshot-day", id: "id-snapshot-day" }) },
                                React.createElement("option", { value: "" }),
                                availableSnapshots.map(function (day, index) {
                                    return React.createElement("option", { key: "opt-" + day.date.toString(), value: index }, snapshotDayString(day, locale));
                                })))),
                    React.createElement(Grid, { item: true, xs: 6 },
                        React.createElement(FormControl, { className: classes.formControl, fullWidth: true },
                            React.createElement(InputLabel, { htmlFor: "id-snapshot" }, t('Time')),
                            React.createElement(NativeSelect, { fullWidth: true, value: selectedSnapshotTimeIndex, onChange: handleChangeTime, input: React.createElement(Input, { name: "snapshot", id: "id-snapshot" }) },
                                React.createElement("option", { value: "" }),
                                selectedSnapshotDayIndex != undefined && (((_a = availableSnapshots[selectedSnapshotDayIndex]) === null || _a === void 0 ? void 0 : _a.times) || []).map(function (time, index) {
                                    return React.createElement("option", { key: "opt-" + time.timestamp, value: index }, snapshotTimeString(time, locale));
                                })))))))),
        selectedSnapshotTimeIndex != undefined && loadingSnapshots && React.createElement(Box, { p: 3, width: "100%", textAlign: "center" },
            React.createElement(CircularProgress, null)),
        selectedSnapshotTimeIndex != undefined && !loadingSnapshots && accountData && React.createElement(Wallets, { wallets: accountData.wallets, conversionRates: accountData.conversionRates })));
};
var mapStateToProps = function (state) { return (__assign({}, state)); };
var mapDispatchToProps = function (dispatch) {
    return bindActionCreators({ setSummaryData: setSummaryData }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(TimeMachine);
