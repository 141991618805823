import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { fixExternalUrl, shortenAddress } from '../utils';
var useStyles = makeStyles(function (theme) {
    return createStyles({
        table: {
            width: '100%',
            overflowX: 'auto',
        },
        disabled: {
            color: theme.palette.text.disabled
        },
        marginLeft: {
            marginLeft: theme.spacing(2)
        },
        container: {
            overflow: 'auto'
        },
        box: {
            display: 'flex'
        }
    });
});
;
var CustomInvestmentsTable = function (props) {
    var classes = useStyles();
    var t = useTranslation().t;
    var handleClickDelete = function (customInvestment, index) { return function () {
        props.onClickDelete(customInvestment, index);
    }; };
    var handleClickEdit = function (customInvestment, index) { return function () {
        props.onClickEdit(customInvestment, index);
    }; };
    return (React.createElement(Box, { className: classes.container },
        React.createElement(Table, { className: classes.table, size: "small" },
            React.createElement(TableHead, null,
                React.createElement(TableRow, null,
                    React.createElement(TableCell, { width: "30%" }, t('Token Address')),
                    React.createElement(TableCell, { width: "48%" }, t('Name')),
                    React.createElement(TableCell, { width: "20%" }, t('Amount')),
                    React.createElement(TableCell, { width: "1%" }, t('Link')),
                    React.createElement(TableCell, { width: "1%" }))),
            React.createElement(TableBody, null, props.customInvestments.map(function (customInvestment, index) { return (React.createElement(TableRow, { key: customInvestment.tokenAddress + "_" + index },
                React.createElement(TableCell, null, shortenAddress(customInvestment.tokenAddress)),
                React.createElement(TableCell, null, customInvestment.name),
                React.createElement(TableCell, null, customInvestment.amount),
                React.createElement(TableCell, null, customInvestment.link && (React.createElement(Link, { target: "_blank", rel: "noopener", href: fixExternalUrl(customInvestment.link) },
                    React.createElement(OpenInNewIcon, null)))),
                React.createElement(TableCell, null,
                    React.createElement(Box, { className: classes.box },
                        React.createElement(IconButton, { size: "small", onClick: handleClickDelete(customInvestment, index) },
                            React.createElement(DeleteForeverOutlinedIcon, null)),
                        React.createElement(IconButton, { className: classes.marginLeft, size: "small", onClick: handleClickEdit(customInvestment, index) },
                            React.createElement(EditOutlinedIcon, null)))))); })))));
};
export default CustomInvestmentsTable;
