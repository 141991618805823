var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
var ApeSwap = function (props) {
    return (React.createElement(SvgIcon, __assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 450.5 40", width: 40, height: 40, className: "prefix__sc-bdvvaa prefix__jogPTX" }, props, { key: "ape-swap" }),
        React.createElement("defs", { id: "prefix__defs835" },
            React.createElement("style", { id: "style833" }, ".prefix__cls-1{fill:#a16552}.prefix__cls-2{fill:#f5dcb4}.prefix__cls-3{fill:#4e3f3f}.prefix__cls-5{fill:#5d5360}.prefix__cls-6{fill:#fff}")),
        React.createElement("circle", { id: "prefix__path893", cx: 225.25, cy: 20, r: 225.25, fill: "#fff", stroke: "#fff", strokeWidth: 0 }),
        React.createElement("path", { className: "prefix__cls-1", d: "M76.293-34.56a140.69 140.69 0 00-5 36.64v2.57a188.47 188.47 0 01-5 44.45c-6.9-2.45-14-7.41-20.78-16-17.84-22.6-11.91-58.84 10.09-67.37 8.32-3.22 15.28-2.54 20.72-.43a.74.74 0 00-.03.14z", id: "prefix__path837" }),
        React.createElement("path", { className: "prefix__cls-2", d: "M76.293-34.56a140.69 140.69 0 00-5 36.64v2.57a188.47 188.47 0 01-5 44.45c-6.9-2.45-14-7.41-20.78-16-17.84-22.6-11.91-58.84 10.09-67.37 8.32-3.22 15.28-2.54 20.72-.43a.74.74 0 00-.03.14z", id: "prefix__path839" }),
        React.createElement("path", { className: "prefix__cls-1", d: "M405.003 33.11c-6.77 8.57-13.88 13.53-20.78 16a188.85 188.85 0 01-5-44.44V2.1a140.21 140.21 0 00-5.05-36.78c5.44-2.11 12.4-2.79 20.72.43 22 8.51 27.94 44.75 10.11 67.36z", id: "prefix__path841" }),
        React.createElement("path", { className: "prefix__cls-2", d: "M405.003 33.11c-6.77 8.57-13.88 13.53-20.78 16a188.85 188.85 0 01-5-44.44V2.1a140.21 140.21 0 00-5.05-36.78c5.44-2.11 12.4-2.79 20.72.43 22 8.51 27.94 44.75 10.11 67.36z", id: "prefix__path843" }),
        React.createElement("path", { className: "prefix__cls-1", d: "M336.453 49.56a39.84 39.84 0 00-4.38 34.18c2.93 8.62 5.55 18.38 5.55 25.64-.02 53.12-50.31 96.12-112.37 96.12s-112.38-43-112.38-96.12c0-7.26 2.62-17 5.55-25.64a39.81 39.81 0 00-4.37-34.17 61.8 61.8 0 01-9.72-33.14c0-35.25 30-63.84 67.05-63.84a69.31 69.31 0 0134.15 8.9 39.88 39.88 0 0039.44 0 69.23 69.23 0 0134.14-8.89c37 0 67.06 28.59 67.06 63.84a60.89 60.89 0 01-5.57 25.51 61.61 61.61 0 01-4.15 7.61z", id: "prefix__path845" }),
        React.createElement("path", { className: "prefix__cls-2", d: "M336.453 49.56a39.84 39.84 0 00-4.38 34.18c2.93 8.62 5.55 18.38 5.55 25.64-.02 53.12-50.31 96.12-112.37 96.12s-112.38-43-112.38-96.12c0-7.26 2.62-17 5.55-25.64a39.81 39.81 0 00-4.37-34.17 61.8 61.8 0 01-9.72-33.14c0-35.25 30-63.84 67.05-63.84a69.31 69.31 0 0134.15 8.9 39.88 39.88 0 0039.44 0 69.23 69.23 0 0134.14-8.89c37 0 67.06 28.59 67.06 63.84a60.89 60.89 0 01-5.57 25.51 61.61 61.61 0 01-4.15 7.61z", id: "prefix__path847" }),
        React.createElement("path", { className: "prefix__cls-3", d: "M168.013 121.26a6.45 6.45 0 00-6.41 7.29c4 28.45 31 50.45 63.65 50.45s59.67-22 63.65-50.45a6.45 6.45 0 00-6.4-7.29z", id: "prefix__path849" }),
        React.createElement("path", { d: "M188.283 168.41a69.83 69.83 0 0073.94 0c-4.64-16.06-19.47-12.36-37-12.36s-32.3-3.7-36.94 12.36z", id: "prefix__path851", fill: "#e6646e" }),
        React.createElement("path", { className: "prefix__cls-1", d: "M402.503-46.14c-13.3-5.16-23.85-2.44-31.27 1.83a163.15 163.15 0 00-6.25-15.69q-2.58-5.62-5.57-11.09c-20.35-37.23-54.57-68.13-93.81-81.19l-.8-21.14-25.42 15.26-3.06-.34c-1.88-.17-3.74-.3-5.64-.38l-3.71-16-1.09-4.62c-1.27 0-2.51 0-3.72.14-23.65 1.92-35.42 23.41-37.26 27.09-39.3 13.06-73.46 43.97-93.81 81.18q-3 5.46-5.57 11.09a167 167 0 00-6.26 15.69l-.95-.53c-7.36-3.95-17.58-6.23-30.31-1.3-27.95 10.84-35.49 56.9-12.84 85.64 9.24 11.68 19 18.08 28.32 20.93a73.2 73.2 0 00-.35 1.58 109.88 109.88 0 00-2.53 23.49c0 54.83 40.44 102 98.38 122.73a191.69 191.69 0 0042.06 9.87 201.87 201.87 0 0022.44 1.4h1.78c90.93 0 164.64-60 164.64-134a109.88 109.88 0 00-2.53-23.51c-.12-.53-.23-1.06-.36-1.58 9.37-2.85 19.09-9.25 28.32-20.94 22.66-28.71 15.11-74.78-12.83-85.61zM76.293-34.56a140.69 140.69 0 00-5 36.64v2.57a188.47 188.47 0 01-5 44.45c-6.9-2.45-14-7.41-20.78-16-17.84-22.6-11.91-58.84 10.09-67.37 8.32-3.22 15.28-2.54 20.72-.43a.74.74 0 00-.03.14zm264.31 76.51a61.61 61.61 0 01-4.15 7.61 39.84 39.84 0 00-4.38 34.18c2.93 8.62 5.55 18.38 5.55 25.64-.02 53.12-50.31 96.12-112.37 96.12s-112.38-43-112.38-96.12c0-7.26 2.62-17 5.55-25.64a39.81 39.81 0 00-4.37-34.17 61.8 61.8 0 01-9.72-33.14c0-35.25 30-63.84 67.05-63.84a69.31 69.31 0 0134.15 8.9 39.88 39.88 0 0039.44 0 69.23 69.23 0 0134.14-8.89c37 0 67.06 28.59 67.06 63.84a60.89 60.89 0 01-5.57 25.51zm64.4-8.84c-6.77 8.57-13.88 13.53-20.78 16a188.85 188.85 0 01-5-44.44V2.1a140.21 140.21 0 00-5.05-36.78c5.44-2.11 12.4-2.79 20.72.43 22 8.51 27.94 44.75 10.11 67.36z", id: "prefix__path853" }),
        React.createElement("rect", { className: "prefix__cls-3", x: 9.829, y: 209.926, width: 10.8, height: 24.52, rx: 5.24, transform: "rotate(-65.45)", id: "prefix__rect855" }),
        React.createElement("rect", { className: "prefix__cls-3", x: -177.363, y: 175.325, width: 10.8, height: 24.52, rx: 5.24, transform: "rotate(-114.55)", id: "prefix__rect857" }),
        React.createElement("path", { className: "prefix__cls-3", d: "M167.513 41.93c-10.63 0-19.25-8.16-19.25-18.24v-6.08c0-10.07 8.62-18.24 19.25-18.24s19.24 8.17 19.24 18.24v6.08c0 10.08-8.61 18.24-19.24 18.24z", id: "prefix__path859" }),
        React.createElement("path", { className: "prefix__cls-5", d: "M167.513-.63v21.28a9.63 9.63 0 0019.24 0v-3c0-10.15-8.61-18.28-19.24-18.28z", id: "prefix__path861" }),
        React.createElement("ellipse", { className: "prefix__cls-6", cx: 167.513, cy: 11.53, rx: 6.42, ry: 6.08, id: "prefix__ellipse863" }),
        React.createElement("path", { className: "prefix__cls-3", d: "M282.993 41.93c-10.63 0-19.24-8.16-19.24-18.24v-6.08c0-10.07 8.61-18.24 19.24-18.24 10.63 0 19.25 8.17 19.25 18.24v6.08c0 10.08-8.64 18.24-19.25 18.24z", id: "prefix__path865" }),
        React.createElement("path", { className: "prefix__cls-5", d: "M282.993-.63v21.28a9.64 9.64 0 0019.25 0v-3c0-10.15-8.64-18.28-19.25-18.28z", id: "prefix__path867" }),
        React.createElement("ellipse", { className: "prefix__cls-6", cx: 282.993, cy: 11.53, rx: 6.42, ry: 6.08, id: "prefix__ellipse869" }),
        React.createElement("path", { d: "M226.973-174.84c-4.31-.42-13.74 6.35-25 18.1-2.81 2.93-5.75 6.18-8.75 9.7-22.15 25.95-47.8 66.87-56.62 108.86-14.74 70.5-20.73 152.3 5.29 197.67 22.05 38.44 59.11 58.59 59.11 58.59a191.69 191.69 0 01-42.06-9.87c-57.91-20.71-98.34-67.9-98.34-122.71a109.88 109.88 0 012.54-23.51c.11-.53.23-1.06.35-1.58-9.37-2.91-19.09-9.27-28.33-20.91-22.65-28.7-15.11-74.76 12.84-85.6 12.73-4.93 23-2.65 30.31 1.3l.95.53a167 167 0 016.26-15.69q2.58-5.62 5.57-11.09c20.35-37.25 54.51-68.16 93.81-81.22 1.84-3.68 13.61-25.17 37.26-27.09 1.21-.14 2.44-.14 3.72-.14z", id: "prefix__path871", opacity: 0.35, fill: "#4e3f3f" })));
};
export default ApeSwap;
