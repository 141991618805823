var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import MaterialTable from "material-table";
import Box from '@material-ui/core/Box';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import { getProtocolIcon } from '../icons/protocols/utils';
var useStyles = makeStyles(function (theme) {
    return createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            '& div.MuiPaper-root div.MuiToolbar-root div.MuiFormControl-root': {
                flex: '1 1 0',
            },
            '& .MuiTableCell-root:first-child': {
                paddingLeft: theme.spacing(2),
            },
        },
        icon: {
            verticalAlign: 'middle',
            marginRight: theme.spacing(1)
        }
    });
});
;
;
var Protocols = function (props) {
    var _a = React.useState([]), protocols = _a[0], setProtocols = _a[1];
    var classes = useStyles();
    var theme = useTheme();
    var locale = props.settings.locale;
    var currency = props.settings.preferredCurrency || 'USD';
    var rate = (props.accountData.conversionRates || {})[currency] || 1.0;
    var t = useTranslation().t;
    var currencySetting = {
        locale: locale || 'en-US',
        currencyCode: currency
    };
    var handler = {
        get: function (_target, value) {
            if (isNaN(value) || isNaN(parseFloat(value))) {
                return 0;
            }
            return value / rate;
        }
    };
    var lookup = new Proxy({}, handler);
    var searchFieldStyle = {
        minWidth: theme.spacing(6)
    };
    useEffect(function () {
        // consolidate by protocol
        var mergedProtocols = {};
        (props.wallets || []).map(function (wallet) {
            return (wallet.investments || []).map(function (investment) {
                var protocol = investment.protocol, totalAmountUSD = investment.totalAmountUSD, totalYieldUSD = investment.totalYieldUSD, totalDebtUSD = investment.totalDebtUSD, pendingYieldUSD = investment.pendingYieldUSD;
                if (protocol !== undefined) {
                    if (!Object.keys(mergedProtocols).includes(protocol)) {
                        mergedProtocols[protocol] = {
                            protocol: protocol,
                            totalAmountUSD: 0.,
                            totalYieldUSD: 0.,
                            pendingYieldUSD: 0.,
                            totalDebtUSD: 0.,
                        };
                    }
                    mergedProtocols[protocol].totalAmountUSD += (totalAmountUSD || 0.);
                    mergedProtocols[protocol].totalYieldUSD += (totalYieldUSD || 0.);
                    mergedProtocols[protocol].totalDebtUSD += (totalDebtUSD || 0.);
                    mergedProtocols[protocol].pendingYieldUSD += (pendingYieldUSD || 0.);
                }
            });
        });
        var sortedProtocols = [];
        Object.keys(mergedProtocols).sort().map(function (protocol) {
            sortedProtocols.push(mergedProtocols[protocol]);
        });
        setProtocols(sortedProtocols);
    }, [props.wallets]);
    var renderProtocol = function (protocol) {
        var Icon = getProtocolIcon(protocol);
        return (React.createElement(Box, { alignItems: "center", display: "flex" },
            React.createElement(Icon, { className: classes.icon }),
            protocol));
    };
    return (React.createElement(Box, { px: 1, pt: 0, pb: 2 },
        React.createElement("div", { className: classes.root },
            React.createElement(MaterialTable, { title: "", columns: [
                    { title: t('Protocol'), field: 'protocol', render: function (rowData) { return renderProtocol(rowData.protocol); }, defaultSort: 'asc' },
                    { title: t('Amount'), field: 'totalAmountUSD', type: 'currency', currencySetting: currencySetting, lookup: lookup },
                    { title: t('Yield'), field: 'totalYieldUSD', type: 'currency', currencySetting: currencySetting, lookup: lookup },
                    { title: t('Pending'), field: 'pendingYieldUSD', type: 'currency', currencySetting: currencySetting, lookup: lookup },
                    { title: t('Debt'), field: 'totalDebtUSD', type: 'currency', currencySetting: currencySetting, lookup: lookup, hidden: true },
                ], options: {
                    searchFieldStyle: searchFieldStyle,
                    padding: 'dense',
                    paging: false,
                    tableLayout: 'auto',
                    columnsButton: true,
                    draggable: false,
                }, icons: {
                    ViewColumn: React.forwardRef(function (props, ref) { return (React.createElement(SettingsOutlinedIcon, __assign({}, props, { ref: ref }), "view_column")); })
                }, data: protocols }))));
};
var mapStateToProps = function (state) { return (__assign({}, state)); };
export default connect(mapStateToProps)(Protocols);
