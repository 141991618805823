var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { memoizeOne } from 'memoize-one';
import { v4 as uuidv4 } from 'uuid';
export var ADDRESS_REGEX = /^0x[a-fA-F0-9]{40}$/;
export var shortenAddress = function (address) {
    if (!address) {
        return '';
    }
    return address.slice(0, 6) + "..." + address.slice(address.length - 4);
};
export var isValidAddress = function (address) {
    if (!address) {
        return false;
    }
    var trimmed = (address || '').trim();
    return ADDRESS_REGEX.test(trimmed);
};
export var isSameAddress = function (address1, address2) {
    var valid1 = isValidAddress(address1);
    var valid2 = isValidAddress(address2);
    if (!valid1 || !valid2) {
        return false;
    }
    return (address1 === null || address1 === void 0 ? void 0 : address1.trim().toLowerCase()) == (address2 === null || address2 === void 0 ? void 0 : address2.trim().toLowerCase());
};
export var getHeaders = function (connectedAccounts, account) {
    var requestId = uuidv4();
    var headers = {
        'x-request-id': requestId
    };
    var signature = (connectedAccounts || {})[account];
    if (signature) {
        headers['x-api-signature'] = signature;
    }
    return headers;
};
var getCurrencyFormat = function (locale, currency, setSignificantDigitsMinMax) {
    if (locale === void 0) { locale = 'en-US'; }
    if (currency === void 0) { currency = 'USD'; }
    if (setSignificantDigitsMinMax === void 0) { setSignificantDigitsMinMax = false; }
    var significantDigits = setSignificantDigitsMinMax ?
        {
            minimumSignificantDigits: 2,
            maximumSignificantDigits: 2
        } : {};
    return new Intl.NumberFormat(locale, __assign({ style: 'currency', currency: currency }, significantDigits));
};
var memoizedIntlCurrencyFormat = memoizeOne(getCurrencyFormat);
var getIntlNumberFormat = function (locale, fractionDigits) {
    if (locale === void 0) { locale = 'en-US'; }
    if (fractionDigits === void 0) { fractionDigits = 2; }
    return new Intl.NumberFormat(locale, { maximumFractionDigits: fractionDigits, minimumFractionDigits: fractionDigits });
};
export var memoizedIntlNumberFormat = memoizeOne(getIntlNumberFormat);
export var currencyFormat = function (locale, currency, value, showSignificantDigitsBelowOne) {
    if (locale === void 0) { locale = 'en-US'; }
    if (currency === void 0) { currency = 'USD'; }
    if (showSignificantDigitsBelowOne === void 0) { showSignificantDigitsBelowOne = false; }
    var setSignificantDigitsMinMax = (showSignificantDigitsBelowOne && value < 1);
    var intlNumberFormat = memoizedIntlCurrencyFormat(locale, currency, setSignificantDigitsMinMax);
    return intlNumberFormat.format(value);
};
export var formatBalance = function (locale, balance) {
    if (locale === void 0) { locale = 'en-US'; }
    if (balance === void 0) { balance = 0; }
    var fractionDigits = 2;
    var suffix = '';
    var divider = 1;
    if (balance >= 1000000) {
        suffix = 'M';
        divider = 1000000;
    }
    else if (balance >= 1000) {
        suffix = 'k';
        divider = 1000;
    }
    else if (balance > 0 && balance < 1) {
        var i = 0;
        var value = balance;
        while (i < 20 && value < 1) {
            i++;
            value *= 10;
        }
        fractionDigits = i + 1;
    }
    var numberFormat = memoizedIntlNumberFormat(locale, fractionDigits);
    return numberFormat.format(balance / divider) + suffix;
};
export var localeStringToNumber = function (locale, value) {
    if (locale === void 0) { locale = 'en-US'; }
    var numberFormat = memoizedIntlNumberFormat(locale);
    var sample = numberFormat.format(1.1);
    var cleanPattern = new RegExp("[^-+0-9" + sample.charAt(1) + "]", 'g');
    var cleaned = value.replace(cleanPattern, '');
    var normalized = cleaned.replace(sample.charAt(1), '.');
    return parseFloat(normalized);
};
export var isFreePlan = function (accountData) {
    return ((accountData.membershipInfo || {}).activeMembershipPlan || 0) == 0;
};
export var calculateNetProfit = function (accountData) {
    accountData.wallets.forEach(function (wallet) {
        wallet.investments.forEach(function (investment) {
            investment.totalNetProfitUSD = (investment.totalYieldUSD || 0) - (investment.totalFeeSpentUSD || 0);
        });
    });
    return accountData;
};
export var fixExternalUrl = function (url) {
    var lowerUrl = (url || '').toLowerCase();
    if (lowerUrl.startsWith('http://') || lowerUrl.startsWith('https://')) {
        return url;
    }
    return "https://" + url;
};
