import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import CancelIcon from '@material-ui/icons/Cancel';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import { Carousel } from 'react-responsive-carousel';
import { isValidAddress } from './utils';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './varela-round-font.css';
import './material-icons.css';
var useStyles = makeStyles(function (theme) {
    var _a, _b;
    return createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            paddingTop: theme.spacing(2),
            width: '100%',
            '& .MuiFormGroup-options': {
                alignItems: 'center',
                paddingBottom: theme.spacing(1),
                '& > div': {
                    minWidth: 100,
                    margin: theme.spacing(2, 2, 2, 0),
                },
            },
        },
        bannerContainer: {
            position: 'relative',
            textAlign: 'center',
            color: 'white',
            backgroundImage: 'url(/banner.v1.png)',
            backgroundSize: '100% 100%',
            width: '100%',
            minHeight: '200px',
            height: 'auto',
        },
        bannerBrand: (_a = {
                position: 'absolute',
                top: '35%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '100%',
                fontFamily: "'Varela Round', sans-serif",
                fontWeight: "bold"
            },
            _a[theme.breakpoints.down('xs')] = {
                top: '30%',
            },
            _a),
        bannerText: (_b = {
                color: 'white',
                position: 'absolute',
                top: '73%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                fontFamily: "'Varela Round', sans-serif",
                width: '90%'
            },
            _b[theme.breakpoints.down('xs')] = {
                top: '70%',
            },
            _b),
        beta: {
            fontWeight: 'lighter',
        },
        description: {
            marginTop: theme.spacing(4),
            fontWeight: 'lighter',
        },
        input: {
            '& input:-webkit-autofill': {
                '-webkit-box-shadow': "0 0 0 30px " + theme.palette.background.default + " inset",
                '-webkit-text-fill-color': theme.palette.text.primary,
                '&:hover, &:focus, &:active': {
                    '-webkit-box-shadow': "0 0 0 30px " + theme.palette.background.default + " inset",
                    '-webkit-text-fill-color': theme.palette.text.primary,
                },
            },
        }
    });
});
var Landing = function (props) {
    var classes = useStyles();
    var _a = React.useState(undefined), wallet = _a[0], setWallet = _a[1];
    var t = useTranslation().t;
    useEffect(function () {
        var lastWallet = localStorage.getItem('lastWallet');
        if (lastWallet) {
            setWallet(lastWallet);
        }
    }, []);
    var handleChange = function (event) {
        setWallet(event.target.value);
    };
    var handleClickClear = function () {
        localStorage.setItem('lastWallet', '');
        setWallet('');
    };
    var handleClickCarousel = function (index) {
        var url;
        switch (index) {
            case 0:
                url = 'https://docs.walletnow.net/features/telegram-bot';
                break;
            case 2:
                url = 'https://docs.walletnow.net/features/time-machine';
                break;
            default:
                url = 'https://docs.walletnow.net/features/detailed-token-data';
        }
        window.open(url, '_blank');
    };
    var handleView = function () {
        var sanitized = (wallet || '').trim().toLowerCase();
        localStorage.setItem('lastWallet', sanitized);
        props.history.push("/account/" + sanitized);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { className: classes.bannerContainer },
            React.createElement(Typography, { className: classes.bannerBrand, variant: "h1", align: "center" }, "WalletNow"),
            React.createElement(Typography, { className: classes.bannerText, variant: "h4", align: "center" }, t('Easy Crypto & DeFi Monitoring'))),
        React.createElement(Container, { maxWidth: "sm" },
            React.createElement(Box, { py: 2 },
                React.createElement(Carousel, { onClickItem: handleClickCarousel, showThumbs: false, showStatus: false, interval: 10000, autoPlay: true, infiniteLoop: true },
                    React.createElement("div", null,
                        React.createElement("img", { src: "/1.v1.jpeg" })),
                    React.createElement("div", null,
                        React.createElement("img", { src: "/2.v1.jpeg" })),
                    React.createElement("div", null,
                        React.createElement("img", { src: "/3.v1.jpeg" })))),
            React.createElement(Grid, { container: true, spacing: 2, justify: "center" },
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(TextField, { className: classes.input, id: "wallet", label: t('Wallet'), value: wallet, onChange: handleChange, variant: "outlined", fullWidth: true, InputProps: {
                            endAdornment: (React.createElement(InputAdornment, { position: "end" },
                                React.createElement(IconButton, { edge: "end", "aria-label": t('Clear'), onClick: handleClickClear },
                                    React.createElement(CancelIcon, null)))),
                        } })),
                React.createElement(Grid, { item: true },
                    React.createElement(Button, { variant: "contained", color: "primary", onClick: handleView, disabled: !isValidAddress(wallet) }, t('View Wallet'))),
                React.createElement(Grid, { item: true },
                    React.createElement(Button, { variant: "outlined", color: "default", href: "https://docs.walletnow.net/", rel: "noopener", target: "_blank" }, t('Check all our features')))),
            React.createElement(Typography, { className: classes.description, variant: "h6", align: "center", color: "textSecondary", paragraph: true },
                "View all your DeFi investments on BSC and Polygon, including all tokens and an ever-growing list of farms, pools and stakes! Filters, detailed views, conversions, ",
                React.createElement("b", null, "Telegram bot"),
                ", multi-wallet support, active monitoring and many more features!"))));
};
export default withRouter(Landing);
