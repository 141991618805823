var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
var Medal = function (props) {
    return (React.createElement(SvgIcon, __assign({ x: 0, y: 0, viewBox: "0 0 100 100" }, props, { key: "medal" }),
        React.createElement("path", { fill: "#324D5B", d: "M43.624 49.921l14.389 9.051-14.47 25.022-5.273-8.014-10.282.047 15.636-26.106z" }),
        React.createElement("path", { fill: "#445D6A", d: "M56.376 50.015l-14.389 9.025 14.47 24.954 5.273-7.991 10.282.046-15.636-26.034z" }),
        React.createElement("path", { fill: props.primaryColor || '#44aa00', d: "M74 43.999c0 2.027-2.938 3.641-3.438 5.511-.518 1.935 1.204 4.798.225 6.491-.994 1.719-4.344 1.652-5.738 3.047s-1.328 4.744-3.047 5.738c-1.693.98-4.557-.741-6.491-.225-1.87.5-3.483 3.437-5.511 3.437s-3.642-2.937-5.511-3.437c-1.935-.517-4.799 1.204-6.492.225-1.718-.994-1.652-4.344-3.047-5.738-1.394-1.395-4.743-1.329-5.737-3.047-.98-1.693.741-4.557.225-6.491-.5-1.87-3.438-3.484-3.438-5.511s2.938-3.642 3.438-5.511c.517-1.935-1.205-4.799-.225-6.492.994-1.718 4.344-1.652 5.738-3.047 1.394-1.394 1.328-4.744 3.046-5.737 1.694-.98 4.558.741 6.492.224 1.869-.499 3.483-3.437 5.511-3.437s3.642 2.938 5.511 3.437c1.935.518 4.798-1.204 6.492-.224 1.718.994 1.652 4.344 3.046 5.738 1.395 1.394 4.744 1.328 5.738 3.046.979 1.694-.741 4.558-.225 6.492.5 1.869 3.438 3.484 3.438 5.511z" }),
        React.createElement("path", { fill: props.secondaryColor || '#8dd35f', d: "M50 26.999c-9.389 0-17 7.611-17 17s7.611 17 17 17 17-7.611 17-17-7.611-17-17-17zm0 32c-8.284 0-15-6.716-15-15s6.716-15 15-15 15 6.716 15 15-6.716 15-15 15zM57.94 41.591c-.139-.386-.494-.669-.922-.73l-4.163-.6-1.81-3.635c-.19-.382-.597-.626-1.045-.626s-.855.244-1.046.626l-1.81 3.635-4.164.6c-.428.062-.783.345-.921.73-.138.388-.033.814.269 1.106l3.051 2.947-.707 4.09c-.072.411.112.825.474 1.066.199.132.433.198.666.198.191 0 .384-.045.559-.135l3.629-1.89 3.63 1.892c.175.09.367.135.559.135.233 0 .467-.066.666-.198.361-.241.545-.655.474-1.066l-.708-4.09 3.052-2.947c.301-.294.404-.72.267-1.108z" })));
};
export default Medal;
