var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
var AaveIcon = function (props) {
    return (React.createElement(SvgIcon, __assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24", width: 64, height: 64 }, props, { key: "aave" }),
        React.createElement("defs", null,
            React.createElement("linearGradient", { id: "aave_prefix__a", x1: 0.907, x2: 0.163, y1: 0.227, y2: 0.853, gradientUnits: "objectBoundingBox" },
                React.createElement("stop", { offset: 0, stopColor: "#b6509e" }),
                React.createElement("stop", { offset: 1, stopColor: "#2ebac6" }))),
        React.createElement("circle", { cx: 12, cy: 12, r: 12, fill: "url(#aave_prefix__a)", "data-name": "Ellipse 991" }),
        React.createElement("path", { fill: "#fff", d: "M17.251 16.78l-4.059-9.809a1.091 1.091 0 00-1.017-.755h-.359a1.091 1.091 0 00-1.017.755l-1.766 4.274H7.697a.731.731 0 00-.728.725v.01a.731.731 0 00.728.725h.718L6.73 16.78a.86.86 0 00-.05.278.767.767 0 00.2.547.7.7 0 00.539.209.729.729 0 00.419-.139.885.885 0 00.289-.368l1.853-4.601h1.287a.731.731 0 00.728-.725v-.02a.731.731 0 00-.728-.725h-.687l1.415-3.529 3.859 9.6a.885.885 0 00.289.368.729.729 0 00.419.139.7.7 0 00.539-.209.767.767 0 00.2-.547.657.657 0 00-.05-.278z", "data-name": "Path 2434" })));
};
export default AaveIcon;
