var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popper from '@material-ui/core/Popper';
import Link from '@material-ui/core/Link';
import MaterialTable from "material-table";
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { formatBalance } from './utils';
import TokenPopoverContent from './TokenPopoverContent';
var useStyles = makeStyles(function (theme) {
    var _a, _b;
    return createStyles({
        subTableBox: (_a = {
                overflow: 'hidden',
                maxHeight: 0,
                transition: 'max-height 300ms ease-out',
                paddingRight: theme.spacing(1),
                paddingLeft: theme.spacing(1),
                backgroundColor: theme.palette.background.default,
                '& .MuiTableCell-root:first-child': {
                    paddingLeft: theme.spacing(2),
                }
            },
            _a[theme.breakpoints.down('xs')] = {
                '& .MuiTable-root': {
                    fontSize: '0.75rem'
                },
            },
            _a),
        infoLink: (_b = {
                color: theme.palette.text.primary,
                textDecoration: 'underline',
                textDecorationStyle: 'dotted',
                display: 'inline-block',
                cursor: 'pointer'
            },
            _b[theme.breakpoints.down('xs')] = {
                fontSize: '0.75rem'
            },
            _b),
        popper: {
            zIndex: 1000,
            maxWidth: 'calc(100% - 8px)',
        },
        paper: {
            overflowY: 'auto',
            outline: 0,
        }
    });
});
;
var DefaultToken = {
    symbol: '',
    token: '',
    amount: '',
    amountUSD: 0,
    priceUSD: 0,
    type: 'hold',
    subTokens: [],
    isLP: false
};
var Tokens = function (props) {
    var classes = useStyles();
    var _a = useState(false), opened = _a[0], setOpened = _a[1];
    var _b = useState(false), openPopover = _b[0], setOpenPopover = _b[1];
    var _c = useState(undefined), anchorEl = _c[0], setAnchorEl = _c[1];
    var _d = useState(DefaultToken), currentInvestmentToken = _d[0], setCurrentInvestmentToken = _d[1];
    var _e = useState(), currentImpermanentLoss = _e[0], setCurrentImpermanentLoss = _e[1];
    var _f = useState([]), investmentTokens = _f[0], setInvestmentTokens = _f[1];
    var investment = props.investment;
    var locale = props.settings.locale;
    var currency = props.settings.preferredCurrency || 'USD';
    var rate = (props.conversionRates || props.accountData.conversionRates || {})[currency] || 1.0;
    var t = useTranslation().t;
    useEffect(function () {
        // used to set the height after mount to trigger transition effect
        setOpened(true);
        setInvestmentTokens(props.investmentTokens);
    }, []);
    var currencySetting = {
        locale: locale || 'en-US',
        currencyCode: currency
    };
    var convertRateHandler = {
        get: function (_target, value) {
            if (isNaN(value) || isNaN(parseFloat(value))) {
                return 0;
            }
            return value / rate;
        }
    };
    var lookupAmount = new Proxy({}, convertRateHandler);
    var formatQuantityHandler = {
        get: function (_target, value) {
            if (isNaN(value) || isNaN(parseFloat(value))) {
                return 0;
            }
            return formatBalance(locale, value);
        }
    };
    var lookupQuantity = new Proxy({}, formatQuantityHandler);
    var onInfoClick = function (row) { return function (event) {
        setAnchorEl(event.currentTarget);
        setCurrentInvestmentToken(row);
        setCurrentImpermanentLoss((props.ilData || {})[row.token]);
        setAnchorEl(event.currentTarget);
        setOpenPopover(true);
    }; };
    var handlePopoverClose = function () {
        setOpenPopover(false);
    };
    var renderSymbol = function (row) { return (React.createElement(Link, { color: "inherit", component: "a", variant: "body2", onClick: onInfoClick(row), className: classes.infoLink, key: row.symbol + "_" + row.type + "_" + row.token }, row.symbol)); };
    var tableStyle = { boxShadow: 'none' };
    var columns = useCallback(function () {
        return [
            { width: '29%', title: t('Symbol'), render: renderSymbol, field: 'symbol' },
            { width: '15%', title: t('Type'), field: 'type', defaultSort: 'asc' },
            { width: '28%', title: t('Quantity'), field: 'amount', align: 'right', lookup: lookupQuantity },
            { width: '28%', title: t('Amount'), field: 'amountUSD', type: 'currency', currencySetting: currencySetting, lookup: lookupAmount },
        ];
    }, [locale, currency]);
    var options = useCallback(function () {
        return {
            tableLayout: 'fixed',
            toolbar: false,
            paging: false,
            draggable: false,
            pageSize: investmentTokens.length,
            padding: 'dense'
        };
    }, []);
    return (React.createElement(Box, { className: classes.subTableBox, style: { maxHeight: (opened) ? ((investmentTokens.length + 1) * 47) : 0 } },
        React.createElement(ClickAwayListener, { onClickAway: handlePopoverClose },
            React.createElement(Popper, { open: openPopover, anchorEl: anchorEl, placement: "right", className: classes.popper },
                React.createElement(Paper, { elevation: 8, className: classes.paper },
                    React.createElement(TokenPopoverContent, { investment: investment, investmentToken: currentInvestmentToken, conversionRates: props.conversionRates, impermanentLoss: currentImpermanentLoss, onClose: handlePopoverClose })))),
        React.createElement(MaterialTable, { style: tableStyle, options: options(), columns: columns(), data: investmentTokens })));
};
var mapStateToProps = function (state) { return (__assign({}, state)); };
export default connect(mapStateToProps)(withRouter(Tokens));
