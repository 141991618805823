/*
 * These types are shared between the different systems
 * (front-end, back-end and telegram bot)
 */
/**
 * JSON schema for the user settings
 */
export var UserSettingsJsonSchema = {
    type: 'object',
    properties: {
        binanceIntegration: {
            type: 'object',
            properties: {
                apiKey: { type: 'string' },
                apiSecret: { type: 'string' },
            },
            additionalProperties: false,
        },
        accountRequiresLogin: { type: 'boolean' },
        customInvestments: {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    name: { type: 'string' },
                    tokenAddress: { type: 'string' },
                    amount: { type: 'string' },
                    link: { type: 'string' },
                },
                required: ['name', 'tokenAddress', 'amount'],
                additionalProperties: false,
            }
        },
        customVaults: {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    name: { type: 'string' },
                    vaultAddress: { type: 'string' },
                    link: { type: 'string' },
                    rewardToken: { type: 'string' },
                    pendingApi: { type: 'string' },
                },
                required: ['name', 'vaultAddress'],
                additionalProperties: false,
            }
        },
        ignoredTokens: {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    token: { type: 'string' },
                },
                required: ['token'],
                additionalProperties: false,
            }
        },
        walletAlias: { type: 'string' },
        wallets: {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    address: { type: 'string' },
                    alias: { type: 'string' },
                },
                required: ['address'],
                additionalProperties: false,
            }
        },
        telegramUserId: { type: 'integer' },
        preferredCurrency: { type: 'string' },
        locale: { type: 'string' },
        activeMonitoring: {
            type: 'object',
            properties: {
                enabled: { type: 'boolean' },
                timeWindow: { type: 'integer' },
                priceIncreaseThreshold: { type: 'integer' },
                priceDecreaseThreshold: { type: 'integer' },
            },
            required: ['enabled', 'timeWindow'],
            additionalProperties: false,
        }
    },
    additionalProperties: false,
};
/**
 * Flags for an investment
 */
export var InvestmentFlags;
(function (InvestmentFlags) {
    InvestmentFlags[InvestmentFlags["None"] = 0] = "None";
    InvestmentFlags[InvestmentFlags["IsWalletHold"] = 1] = "IsWalletHold";
    InvestmentFlags[InvestmentFlags["IsCeFiHold"] = 2] = "IsCeFiHold";
    InvestmentFlags[InvestmentFlags["RequiresSilver"] = 4] = "RequiresSilver";
    InvestmentFlags[InvestmentFlags["RequiresGold"] = 8] = "RequiresGold";
})(InvestmentFlags || (InvestmentFlags = {}));
/**
 * Account plans
 */
export var AccountPlan;
(function (AccountPlan) {
    AccountPlan[AccountPlan["Free"] = 0] = "Free";
    AccountPlan[AccountPlan["Bronze"] = 1] = "Bronze";
    AccountPlan[AccountPlan["Silver"] = 2] = "Silver";
    AccountPlan[AccountPlan["Gold"] = 3] = "Gold";
})(AccountPlan || (AccountPlan = {}));
