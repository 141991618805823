import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
var useStyles = makeStyles(function (theme) {
    return createStyles({
        signupBox: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        signupTitle: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(1),
        },
        signupButton: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(2),
        },
    });
});
var UpgradeBanner = function (props) {
    var classes = useStyles();
    var t = useTranslation().t;
    var handlePlans = function () {
        props.history.push('/plans');
    };
    return (React.createElement(Box, { className: classes.signupBox },
        React.createElement(Typography, { className: classes.signupTitle, variant: "h6" }, t('Upgrade now to see all your token details!')),
        React.createElement(Button, { className: classes.signupButton, variant: "outlined", color: "primary", onClick: handlePlans }, t('See Plans'))));
};
export default withRouter(UpgradeBanner);
