var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import { isValidAddress } from '../utils';
var useStyles = makeStyles(function (theme) {
    return createStyles({
        reducedMargin: {
            margin: theme.spacing(3)
        }
    });
});
;
var DefaultWalletSettings = { address: '', alias: '' };
var EditWalletDialog = function (props) {
    var _a, _b;
    var classes = useStyles();
    var t = useTranslation().t;
    var _c = React.useState(DefaultWalletSettings), walletSettings = _c[0], setWalletSettings = _c[1];
    var isEditing = (Boolean((_a = props.walletSettings) === null || _a === void 0 ? void 0 : _a.address) || Boolean((_b = props.walletSettings) === null || _b === void 0 ? void 0 : _b.alias));
    useEffect(function () {
        setWalletSettings(props.walletSettings || DefaultWalletSettings);
    }, []);
    var handleChange = function (field) { return function (event) {
        var _a;
        setWalletSettings(__assign(__assign({}, (walletSettings || {})), (_a = {}, _a[field] = event.target.value, _a)));
    }; };
    var handleConfirm = function () {
        props.onClickConfirm(walletSettings, props.userData);
    };
    var hasErrors = !isValidAddress(walletSettings.address);
    return (React.createElement(Dialog, { open: true, fullWidth: true, classes: { paper: classes.reducedMargin }, onClose: props.onCloseDialog },
        React.createElement(DialogTitle, null, (isEditing) ? t('Edit Wallet') : t('Add Wallet')),
        React.createElement(DialogContent, null,
            React.createElement(Grid, { container: true, spacing: 1 },
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(TextField, { required: true, error: hasErrors, className: classes.input, id: "address", label: t('Address'), onChange: handleChange('address'), variant: "outlined", value: walletSettings.address, fullWidth: true })),
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(TextField, { className: classes.input, id: "alias", label: t('Alias'), onChange: handleChange('alias'), variant: "outlined", value: walletSettings.alias, fullWidth: true })))),
        React.createElement(DialogActions, null,
            React.createElement(Button, { onClick: props.onCloseDialog, color: "secondary" }, t('Cancel')),
            React.createElement(Button, { onClick: handleConfirm, color: "primary", disabled: hasErrors }, t('Ok')))));
};
export default EditWalletDialog;
