var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
var JetFuel = function (props) {
    return (React.createElement(SvgIcon, __assign({ xmlns: "http://www.w3.org/2000/svg", width: 50, height: 50, viewBox: "0 0 50 50", style: { fill: 'black' } }, props, { key: "jet-fuel" }),
        React.createElement("circle", { cx: 25, cy: 25, r: 25, fill: "#fff" }),
        React.createElement("g", { fill: "#000" },
            React.createElement("path", { d: "M9.29 24.317c-.74 3.317-1.347 6.2-1.347 6.408 0 .343.283.371 3.196.317l3.196-.06 1.138-5.534c.626-3.044 1.203-5.9 1.281-6.349l.143-.813h-6.261l-1.347 6.03z" }),
            React.createElement("path", { d: "M28.04 17.147c-.861 4.133-3.071 14.519-3.279 15.406l-.114.485-8.617.057-8.618.057-.482 2.93c-.264 1.611-.487 3.052-.494 3.201-.01.214 2.447.271 11.595.271h11.607l.11-.488c.06-.269.36-1.66.663-3.093.304-1.432.69-3.19.857-3.906.34-1.456 1.331-6.038 2.16-9.983.3-1.433.841-3.906 1.203-5.496.362-1.59.658-3.03.658-3.2 0-.256-.57-.311-3.202-.31h-3.2l-.848 4.069z" }),
            React.createElement("path", { d: "M19.88 13.267c0 .105-.782 4.005-1.737 8.668-.955 4.662-1.736 8.632-1.736 8.822 0 .302.384.338 3.084.285l3.084-.06.247-1.194c.993-4.802 3.352-16.47 3.352-16.578 0-.073-1.417-.133-3.147-.133-1.731 0-3.147.086-3.147.19zM32.901 23.928h6.511l-1.302 6.076H31.6zM34.884 13.077h8.68l-1.301 6.077h-8.681zM30.297 7h6.51l-.867 4.775h-6.511L30.296 7z" }))));
};
export default JetFuel;
