import { matchRoutes } from "react-router-config";
import Account from './Account';
import Landing from './Landing';
import Settings from './settings/Settings';
import Plans from './Plans';
import PrivateSale from './PrivateSale';
var baseRoutes = [
    {
        path: '/account/:account/settings',
        component: Settings
    },
    {
        path: '/account/:account',
        component: Account
    },
    {
        path: '/plans',
        component: Plans
    },
    {
        path: '/private_sale',
        component: PrivateSale
    },
    {
        path: '/',
        component: Landing
    },
    {
        component: Landing
    }
];
export var getRoutes = function () { return baseRoutes; };
export var accountFromCurrentPath = function () {
    var branch = matchRoutes(getRoutes(), location.pathname);
    if (branch.length > 0) {
        return (((branch[0].match || {}).params || {}).account || '').toLowerCase();
    }
    return '';
};
