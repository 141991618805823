var _a;
import { AccountPlan } from "./sharedTypes";
export var ACTION_TYPES = {
    SETTINGS_CHANGE: 'SETTINGS_CHANGE',
    INCREMENT_BUSY_COUNTER: 'INCREMENT_BUSY_COUNTER',
    DECREMENT_BUSY_COUNTER: 'DECREMENT_BUSY_COUNTER',
    CLEAR_ACCOUNT_DATA: 'CLEAR_ACCOUNT_DATA',
    SET_ACCOUNT_DATA: 'SET_ACCOUNT_DATA',
    CONNECT_ACCOUNT: 'CONNECT_ACCOUNT',
    DISCONNECT_ACCOUNT: 'DISCONNECT_ACCOUNT',
    SET_SUMMARY_DATA: 'SET_SUMMARY_DATA',
};
export var LOCALES = ['en-US', 'pt-BR', 'es'];
export var LOCALES_LABEL = {
    'en-US': 'English',
    'pt-BR': 'Português (Brasil)',
    'es': 'Español',
};
export var IS_BETA = (window.location.hostname == '127.0.0.1' || window.location.hostname.startsWith('beta.'));
export var API_BASE_URL = (window.location.hostname == '127.0.0.1') ?
    'https://api.beta.walletnow.net' :
    "https://api." + window.location.hostname;
export var PROVIDER = (IS_BETA) ?
    'https://data-seed-prebsc-1-s1.binance.org:8545/' :
    'https://bsc-dataseed.binance.org/';
export var CHAIN_ID = (IS_BETA) ? 97 : 56;
export var PRIVATE_SALE_CONTRACT_ADDRESS = IS_BETA
    ? '0xca53a7f0c95cdf048b1b5f3450c507b4c4e25230'
    : '0xe2dbc7bd3360dae52f8c12ccb5493772f217e1b9';
export var WNOW_CONTRACT_ADDRESS = IS_BETA
    ? '0x6bf0bb35cb0fe9197e518ebdbd639732935423c2'
    : '0x56aa0237244c67b9a854b4efe8479cca0b105289';
export var ZERO_ADDR = '0x0000000000000000000000000000000000000000';
// this constant is used while the user is on the landing page, so they have a glance of the currencies we support
export var AVAILABLE_CURRENCIES = [
    'AED', 'ARS', 'AUD', 'BDT', 'BHD', 'BMD', 'BRL', 'CAD', 'CHF', 'CLP', 'CNY', 'CZK', 'DKK', 'EUR', 'GBP', 'HKD', 'HUF', 'IDR',
    'ILS', 'INR', 'JPY', 'KRW', 'KWD', 'LKR', 'MMK', 'MXN', 'MYR', 'NGN', 'NOK', 'NZD', 'PHP', 'PKR', 'PLN', 'RUB', 'SAR', 'SEK',
    'SGD', 'THB', 'TRY', 'TWD', 'UAH', 'VEF', 'VND', 'ZAR', 'XDR'
];
export var PLAN_COLORS = (_a = {},
    _a[AccountPlan.Free] = {
        primaryColor: "#ff9955",
        secondaryColor: "#ffccaa"
    },
    _a[AccountPlan.Silver] = {
        primaryColor: "#cccccc",
        secondaryColor: "#f2f2f2"
    },
    _a[AccountPlan.Gold] = {
        primaryColor: "#efc75e",
        secondaryColor: "#fce29c"
    },
    _a);
