var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
var Custom = function (props) {
    return (React.createElement(SvgIcon, __assign({ viewBox: "0 0 40 40", width: 40, height: 40 }, props, { key: "logo" }),
        React.createElement("defs", null,
            React.createElement("linearGradient", { id: "wallet_now_alt_gradient" },
                React.createElement("stop", { offset: 0, stopColor: "#5a005a" }),
                React.createElement("stop", { offset: 1, stopColor: "#de00de" })),
            React.createElement("linearGradient", { xlinkHref: "#wallet_now_alt_gradient", id: "wallet_now_alt_id", x1: 32.86, y1: 35.317, x2: 8.056, y2: 3.958, gradientUnits: "userSpaceOnUse" })),
        React.createElement("circle", { style: {
                mixBlendMode: "normal",
            }, cx: 20, cy: 20, r: 20, fill: "url(#wallet_now_alt_id)" }),
        React.createElement("g", { transform: "translate(2.435 2.436)", fill: "#fff", stroke: "#fff" },
            React.createElement("path", { d: "M30.143 13.19h-.574v-2.275c0-1.415-1.17-2.566-2.61-2.566H6.368c-1.44 0-2.61 1.15-2.61 2.566V24.21c0 1.417 1.17 2.568 2.61 2.568h20.59c1.438 0 2.61-1.15 2.61-2.568v-2.273h.574a1.22 1.22 0 001.229-1.207v-6.333a1.22 1.22 0 00-1.229-1.207zm-1.41 11.021c0 .963-.796 1.745-1.775 1.745H6.368c-.977 0-1.774-.782-1.774-1.745V10.916c0-.962.796-1.745 1.775-1.745h20.59c.978 0 1.773.783 1.773 1.745v2.275h-6.026c-1.57 0-2.847 1.256-2.847 2.799v3.149c0 1.543 1.277 2.8 2.847 2.8h6.026zm1.802-3.48a.389.389 0 01-.393.384h-7.436c-1.108 0-2.011-.888-2.011-1.977V15.99c0-1.09.902-1.977 2.011-1.977h7.436a.39.39 0 01.393.385z", strokeWidth: 1.1 }),
            React.createElement("path", { d: "M24.918 15.663h-1.415c-.582 0-1.055.389-1.055.866v1.94c0 .48.473.868 1.055.868h1.415c.582 0 1.055-.389 1.055-.867v-1.941c0-.477-.473-.866-1.055-.866zm.207 2.807c0 .094-.092.17-.207.17h-1.415c-.116 0-.207-.075-.207-.17v-1.941c0-.095.091-.17.207-.17h1.415c.114 0 .207.076.207.17z", strokeWidth: 0.7 }),
            React.createElement("rect", { width: 1.287, height: 8, x: 11.787, y: 17.729, ry: 0.222, strokeWidth: 1.113, strokeLinecap: "round" }),
            React.createElement("rect", { width: 1.243, height: 12, x: 16.03, y: 13.707, ry: 0.222, strokeWidth: 1.157, strokeLinecap: "round" }),
            React.createElement("rect", { width: 1.287, height: 5, x: 7.707, y: 20.729, ry: 0.222, strokeWidth: 1.113, strokeLinecap: "round" }))));
};
export default Custom;
