var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { useLocation, withRouter } from 'react-router-dom';
import { clearAccountData, setAccountData, setSummaryData } from '../store/actions';
import { bindActionCreators } from 'redux';
import Summary from './Summary';
import { accountFromCurrentPath } from './routes';
import { calculateNetProfit, getHeaders, isValidAddress } from './utils';
import { API_BASE_URL } from './constants';
import Wallets from './Wallets';
import Protocols from './Protocols';
import TimeMachine from './TimeMachine';
var Account = function (props) {
    var location = useLocation();
    var accountData = props.accountData;
    var enqueueSnackbar = useSnackbar().enqueueSnackbar;
    var _a = React.useState('wallets'), tab = _a[0], setTab = _a[1];
    var _b = React.useState(true), loading = _b[0], setLoading = _b[1];
    var t = useTranslation().t;
    useEffect(function () {
        var account = accountFromCurrentPath();
        if (isValidAddress(account)) {
            props.clearAccountData();
            setLoading(true);
            var headers = getHeaders(props.connectedAccounts, account);
            var retryHeaders_1 = __assign(__assign({}, headers), { 'x-is-retry': 'true' });
            var url_1 = API_BASE_URL + "/accounts/" + account;
            var fetchJson_1 = function (url, init) {
                return fetch(url, init)
                    .then(function (response) { return response.json(); })
                    .then(function (data) { return new Promise(function (resolve, reject) {
                    if (data.success)
                        resolve(data);
                    else
                        reject(data.message || 'Error retrieving data, please try again');
                }); });
            };
            var retryLoop = function (promise) {
                var p = promise.catch(function (reason) { return new Promise(function (_resolve, reject) {
                    console.log('Error retrieving account data. Retrying in 10 seconds...', reason);
                    enqueueSnackbar(t('Still processing. Please wait...'), { variant: 'warning' });
                    setTimeout(reject.bind(null, reason), 10000);
                }); })
                    .catch(function () { return fetchJson_1(url_1, { headers: retryHeaders_1 }); });
                for (var i = 0; i < 5; i++) {
                    p = p.catch(function (reason) { return new Promise(function (_resolve, reject) {
                        console.log('Error retrieving account data. Retrying in 5 seconds...', reason);
                        setTimeout(reject.bind(null, reason), 5000);
                    }); })
                        .catch(function () { return fetchJson_1(url_1, { headers: retryHeaders_1 }); });
                }
                return p;
            };
            retryLoop(fetchJson_1(url_1, { headers: headers }))
                .then(function (data) {
                if (data.success) {
                    var accountData_1 = calculateNetProfit(data.result);
                    props.setAccountData(accountData_1);
                    props.setSummaryData(accountData_1.wallets);
                    displayServerMessages(accountData_1);
                }
                else {
                    enqueueSnackbar(t(data.message || 'Error retrieving data, please try again'), { variant: 'error' });
                }
                setLoading(false);
            })
                .catch(function () {
                setLoading(false);
                enqueueSnackbar(t('Error retrieving data, please try again'), { variant: 'error' });
            });
        }
    }, [(location.state || {}).refreshTimestamp]);
    useEffect(function () {
        if (tab == 'wallets') {
            props.setSummaryData(accountData.wallets);
        }
    }, [tab]);
    var handleChange = function (event, newValue) {
        setTab(newValue);
    };
    var displayServerMessages = function (accountData) {
        (accountData.wallets || []).map(function (wallet) {
            (wallet.warnings || []).map(function (warning) {
                // TODO: handle i18n with interpolation
                var key = enqueueSnackbar(warning.en, {
                    variant: 'warning',
                    autoHideDuration: null,
                });
            });
        });
    };
    return (React.createElement(React.Fragment, null,
        loading && React.createElement(Box, { p: 3, width: "100%", textAlign: "center" },
            React.createElement(CircularProgress, null)),
        !loading && (React.createElement(Box, { px: 1, py: 1 },
            React.createElement(Summary, null),
            React.createElement(Tabs, { value: tab, onChange: handleChange },
                React.createElement(Tab, { value: "wallets", label: t('Wallets') }),
                React.createElement(Tab, { value: "protocols", label: t('Protocols') }),
                React.createElement(Tab, { value: "time-machine", label: t('Time Machine') })))),
        !loading && (tab === 'wallets') && (React.createElement(React.Fragment, null,
            React.createElement(Wallets, { wallets: accountData.wallets }),
            React.createElement(Box, { px: 1 },
                React.createElement(Typography, { variant: "body2" },
                    "Is there anything missing? ",
                    React.createElement(Link, { href: "https://docs.walletnow.net/features/custom-vaults", target: "_blank", rel: "noopener" }, "Register a custom vault"),
                    " ",
                    React.createElement(OpenInNewIcon, { fontSize: "small" }),
                    " now and track everything")))),
        !loading && (tab === 'protocols') && React.createElement(Protocols, { wallets: accountData.wallets }),
        !loading && (tab === 'time-machine') && React.createElement(TimeMachine, null)));
};
var mapStateToProps = function (state) { return (__assign({}, state)); };
var mapDispatchToProps = function (dispatch) {
    return bindActionCreators({ clearAccountData: clearAccountData, setAccountData: setAccountData, setSummaryData: setSummaryData }, dispatch);
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Account));
