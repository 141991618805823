import i18n from 'i18next';
import enUSTranslation from './en-US/translation.json';
import esTranslation from './es/translation.json';
import ptBRTranslation from './pt-BR/translation.json';
import { initReactI18next } from 'react-i18next';
export var resources = {
    'en-US': {
        translation: enUSTranslation,
    },
    'es': {
        translation: esTranslation,
    },
    'pt-BR': {
        translation: ptBRTranslation,
    },
};
i18n.use(initReactI18next).init({
    lng: 'en-US',
    resources: resources,
    interpolation: {
        escapeValue: false
    }
});
