var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
var PolycatIcon = function (props) {
    return (React.createElement(SvgIcon, __assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 202.737 202.736", width: 202.737, height: 202.736 }, props, { key: "polycat" }),
        React.createElement("circle", { cx: 101.189, cy: 101.255, r: 100.627, fill: "#fff" }),
        React.createElement("path", { d: "M140.042 151.528l17-24-30.5 16-6.25 13zM66.042 151.528l-17-24 30.5 16 6.25 13zM103.042 181.528l11-11h-22z", fill: "#000", stroke: "#000" }),
        React.createElement("path", { d: "M103.042 129.028l-23.5 14.5m23.5-14.5l-11 41.5m11-41.5l23.5 14.5m-23.5-14.5l11 41.5m-11-41.5l22.5-50.5m-22.5 50.5l-22.5-50.5m0 0h45m-45 0l-13 11.5m13-11.5l-51-32.5m50 97.5l6.25 13m-6.25-13l-30.5-16m30.5 16l-12-53.5m24.5 80.5l-6.25-14m6.25 14h-19m19 0l-10 14.5m10-14.5l11 11m-11-11h22m-46.5-80.5h-32m32 0l-38-44m19.5 81.5l17 24m-17-24l-12.5-6.5m12.5 6.5l-13.5-37.5m30.5 61.5l19.75 5m-19.75-5l7 19m-7-19l-14 1m21 18l9 14.5m-9-14.5l-21-18m30 32.5l15 5m-15-5l-30-32.5m51 29l11-11m-11 11l-6 8.5m6-8.5l6 8.5m-12 0h12m-57-37.5l-15.5-31.5m0 0l-1-31m0 0l-6-44m96 32.5l13 11.5m-13-11.5l51-32.5m-50 97.5l-6.25 13m6.25-13l30.5-16m-30.5 16l12-53.5m-24.5 80.5l6.25-14m-6.25 14h19m-19 0l10 14.5m14.5-95h32m-32 0l38-44m-19.5 81.5l-17 24m17-24l12.5-6.5m-12.5 6.5l13.5-37.5m-30.5 61.5l-19.75 5m19.75-5l-7 19m7-19l14 1m-21 18l-9 14.5m9-14.5l21-18m-30 32.5l-15 5m15-5l30-32.5m0 0l15.5-31.5m0 0l1-31m0 0l6-44", fill: "#000", stroke: "#000" })));
};
export default PolycatIcon;
