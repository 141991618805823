var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import CloseIcon from '@material-ui/icons/Close';
import Divider from '@material-ui/core/Divider';
import { currencyFormat, formatBalance, memoizedIntlNumberFormat, shortenAddress } from './utils';
import { ZERO_ADDR } from './constants';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
var useStyles = makeStyles(function (theme) {
    var _a;
    return createStyles({
        symbol: {
            display: 'flex',
            alignItems: 'center'
        },
        button: {
            display: 'flex'
        },
        infoLink: {
            display: 'inline-block',
            marginRight: theme.spacing(3)
        },
        alignRight: {
            textAlign: 'right'
        },
        openIcon: {
            verticalAlign: 'middle'
        },
        popoverGrid: {
            padding: theme.spacing(1),
        },
        reducedFontInXS: (_a = {},
            _a[theme.breakpoints.down('xs')] = {
                fontSize: '0.75rem'
            },
            _a),
        closeGrid: {
            display: 'flex',
            justifyContent: 'flex-end'
        },
        even: {
            backgroundColor: theme.palette.background.default
        },
        ilNonZeroValue: {
            color: theme.palette.error.main
        },
        ratio: {
            color: theme.palette.text.disabled
        },
        reasons: {
            paddingLeft: theme.spacing(2)
        },
        disclaimer: {
            whiteSpace: 'pre-wrap'
        }
    });
});
;
function isTokenAddress(address) {
    return address.startsWith('0x') && address != ZERO_ADDR;
}
function getBlockchainExplorer(chain) {
    if (chain == 'poly')
        return 'polygonscan.com';
    return 'bscscan.com';
}
var TokenPopoverContent = function (props) {
    var classes = useStyles();
    var _a = useState('il'), tab = _a[0], setTab = _a[1];
    var investmentToken = props.investmentToken, impermanentLoss = props.impermanentLoss, investment = props.investment;
    var locale = props.settings.locale;
    var currency = props.settings.preferredCurrency || 'USD';
    var rate = (props.conversionRates || props.accountData.conversionRates || {})[currency] || 1.0;
    var t = useTranslation().t;
    var blockchainExplorer = getBlockchainExplorer(investment.chain);
    var formatQuantity = function (value) {
        if (isNaN(value) || isNaN(parseFloat(value))) {
            return 0;
        }
        return formatBalance(locale, value);
    };
    var formatPercent = function (value) {
        var intlNumberFormat = memoizedIntlNumberFormat(locale, 2);
        return intlNumberFormat.format(value) + '%';
    };
    var handleChange = function (event, newValue) {
        setTab(newValue);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Grid, { className: classes.popoverGrid, container: true, alignItems: "center" },
            React.createElement(Grid, { item: true, xs: 11 },
                React.createElement(Typography, { variant: "subtitle1" }, investmentToken.symbol),
                isTokenAddress(investmentToken.token) &&
                    React.createElement(Link, { className: classes.infoLink, target: "_blank", rel: "noopener", href: "https://" + blockchainExplorer + "/token/" + investmentToken.token },
                        shortenAddress(investmentToken.token),
                        " ",
                        React.createElement(OpenInNewIcon, { className: classes.openIcon, fontSize: "small" }))),
            React.createElement(Grid, { className: classes.closeGrid, item: true, xs: 1 },
                React.createElement(IconButton, { className: classes.button, "aria-label": t('Close'), size: "small", onClick: props.onClose },
                    React.createElement(CloseIcon, null))),
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Divider, null)),
            !investmentToken.isLP && [
                React.createElement(Grid, { item: true, xs: 5, sm: 4, key: "token_price_title" }, t('Price')),
                React.createElement(Grid, { item: true, xs: 7, sm: 8, key: "token_price_value" }, currencyFormat(locale, currency, investmentToken.priceUSD / rate, true))
            ],
            ((investmentToken.subTokens || []).length > 0) &&
                [
                    React.createElement(Grid, { key: "header_symbol", item: true, xs: 3 },
                        React.createElement(Typography, { variant: "overline" }, t('Symbol'))),
                    React.createElement(Grid, { key: "header_quantity", className: classes.alignRight, item: true, xs: 3 },
                        React.createElement(Typography, { variant: "overline" }, t('Quantity'))),
                    React.createElement(Grid, { key: "header_price", className: classes.alignRight, item: true, xs: 3 },
                        React.createElement(Typography, { variant: "overline" }, t('Price'))),
                    React.createElement(Grid, { key: "header_amount", className: classes.alignRight, item: true, xs: 3 },
                        React.createElement(Typography, { variant: "overline" }, t('Amount')))
                ],
            (investmentToken.subTokens || []).map(function (subToken, index) {
                var key = investmentToken.token + "_" + subToken.token;
                var baseClass = ((index % 2) == 0) ? classes.even : '';
                return [
                    React.createElement(Grid, { key: key + "_symbol", className: baseClass, item: true, xs: 3 },
                        React.createElement(Typography, { variant: "caption" }, subToken.symbol)),
                    React.createElement(Grid, { key: key + "_quantity", className: classes.alignRight + " " + baseClass, item: true, xs: 3 },
                        React.createElement(Typography, { variant: "caption" }, formatQuantity(subToken.amount))),
                    React.createElement(Grid, { key: key + "_price", className: classes.alignRight + " " + baseClass, item: true, xs: 3 },
                        React.createElement(Typography, { variant: "caption" }, currencyFormat(locale, currency, subToken.priceUSD / rate))),
                    React.createElement(Grid, { key: key + "_amount", className: classes.alignRight + " " + baseClass, item: true, xs: 3 },
                        React.createElement(Typography, { variant: "caption" }, currencyFormat(locale, currency, subToken.amountUSD / rate)))
                ];
            })),
        investmentToken.isLP && (React.createElement(Box, { px: 1, pb: 1, className: classes.reducedFontInXS },
            React.createElement(Tabs, { value: tab, onChange: handleChange },
                React.createElement(Tab, { value: "il", label: t('LP Summary') }),
                React.createElement(Tab, { value: "tx", label: t('Last Session TXs') })),
            (tab === 'il') && !impermanentLoss && (React.createElement(Typography, { className: classes.disclaimer, variant: "caption" },
                React.createElement("br", null),
                t('LP_INFO_UNABLE_TO_LOAD'),
                React.createElement("ul", { className: classes.reasons },
                    React.createElement("li", null, t('LP_INFO_REASON1')),
                    React.createElement("li", null, t('LP_INFO_REASON2'))),
                t('LP_INFO_UNSUPPORTED_SCENARIO'))),
            (tab === 'il') && impermanentLoss && (React.createElement(Grid, { container: true },
                React.createElement(Grid, { item: true, xs: 5, sm: 4 }, t('Impermanent Loss')),
                React.createElement(Grid, { item: true, xs: 7, sm: 8 },
                    React.createElement(Box, { className: (impermanentLoss.impermanentLoss >= 0.01) ? classes.ilNonZeroValue : '' }, formatPercent(impermanentLoss.impermanentLoss * 100))),
                React.createElement(Grid, { item: true, xs: 5, sm: 4 }, t('Deposited')),
                React.createElement(Grid, { item: true, xs: 7, sm: 8 },
                    currencyFormat(locale, currency, impermanentLoss.depositedUSD / rate),
                    " (",
                    formatBalance(locale, Number(impermanentLoss.token0.depositedAmount)),
                    " ",
                    impermanentLoss.token0.symbol,
                    " ",
                    t('and'),
                    " ",
                    formatBalance(locale, Number(impermanentLoss.token1.depositedAmount)),
                    " ",
                    impermanentLoss.token1.symbol,
                    ")"),
                React.createElement(Grid, { item: true, xs: 5, sm: 4 }, t('Current')),
                React.createElement(Grid, { item: true, xs: 7, sm: 8 },
                    currencyFormat(locale, currency, impermanentLoss.currentUSD / rate),
                    " (",
                    formatBalance(locale, Number(impermanentLoss.token0.currentAmount)),
                    " ",
                    impermanentLoss.token0.symbol,
                    " ",
                    t('and'),
                    " ",
                    formatBalance(locale, Number(impermanentLoss.token1.currentAmount)),
                    " ",
                    impermanentLoss.token1.symbol,
                    ")"))),
            (tab === 'tx') && impermanentLoss && (React.createElement(Grid, { container: true },
                React.createElement(Grid, { item: true, xs: 4, sm: 2 },
                    React.createElement(Typography, { variant: "overline" }, t('TX'))),
                React.createElement(Hidden, { xsDown: true },
                    React.createElement(Grid, { item: true, sm: 2 },
                        React.createElement(Typography, { variant: "overline" }, t('Type')))),
                React.createElement(Grid, { className: classes.alignRight, item: true, xs: 2 },
                    React.createElement(Typography, { variant: "overline" }, impermanentLoss.token0.symbol)),
                React.createElement(Grid, { className: classes.alignRight, item: true, xs: 2 },
                    React.createElement(Typography, { variant: "overline" }, impermanentLoss.token1.symbol)),
                React.createElement(Grid, { className: classes.alignRight, item: true, xs: 4 },
                    React.createElement(Typography, { variant: "overline" }, investmentToken.symbol)),
                impermanentLoss.lpTransactions.map(function (tx, index) {
                    var key = tx.tx + "_" + index;
                    var baseClass = ((index % 2) == 0) ? classes.even : '';
                    return [
                        React.createElement(Grid, { key: key + "_tx", className: baseClass, item: true, xs: 4, sm: 2 },
                            React.createElement(Hidden, { smUp: true },
                                tx.type,
                                React.createElement("br", null)),
                            React.createElement(Link, { className: classes.infoLink, target: "_blank", rel: "noopener", href: "https://" + blockchainExplorer + ".com/tx/" + tx.tx },
                                shortenAddress(tx.tx),
                                " ",
                                React.createElement(OpenInNewIcon, { className: classes.openIcon, fontSize: "small" }))),
                        React.createElement(Hidden, { xsDown: true },
                            React.createElement(Grid, { key: key + "_type", className: baseClass, item: true, sm: 2 }, tx.type)),
                        React.createElement(Grid, { key: key + "_token0Amount", className: classes.alignRight + " " + baseClass, item: true, xs: 2 },
                            formatBalance(locale, Number(tx.token0Amount)),
                            React.createElement("br", null),
                            React.createElement("span", { className: classes.ratio },
                                t('[ratio]'),
                                " ",
                                formatBalance(locale, Number(tx.ratio)))),
                        React.createElement(Grid, { key: key + "_token1Amount", className: classes.alignRight + " " + baseClass, item: true, xs: 2 },
                            formatBalance(locale, Number(tx.token1Amount)),
                            React.createElement("br", null),
                            React.createElement("span", { className: classes.ratio },
                                t('[ratio]'),
                                " ",
                                formatBalance(locale, 1 / Number(tx.ratio)))),
                        React.createElement(Grid, { key: key + "_lpTokenAmount", className: classes.alignRight + " " + baseClass, item: true, xs: 4 }, formatBalance(locale, Number(tx.lpTokenAmount)))
                    ];
                })))))));
};
var mapStateToProps = function (state) { return (__assign({}, state)); };
export default connect(mapStateToProps)(withRouter(TokenPopoverContent));
