var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDialog } from 'muibox';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DescriptionBox from './DescriptionBox';
import { shortenAddress } from '../utils';
import WalletsTable from './WalletsTable';
import EditWalletDialog from './EditWalletDialog';
var useStyles = makeStyles(function (theme) {
    return createStyles({
        flexColumn: {
            display: 'flex',
            flexDirection: 'column',
        },
        flexRow: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
        },
        bottomMargin: {
            marginBottom: theme.spacing(2),
        },
    });
});
var Wallets = function (props) {
    var classes = useStyles();
    var dialog = useDialog();
    var t = useTranslation().t;
    var account = props.account, walletAlias = props.walletAlias, wallets = props.wallets;
    var _a = useState(), editingWallet = _a[0], setEditingWallet = _a[1];
    var handleClickAddWallet = function () {
        setEditingWallet({
            settings: { address: '', alias: '' },
            index: -1
        });
    };
    var handleClickEditWallet = function (walletSettings, index) {
        setEditingWallet({ settings: walletSettings, index: index });
    };
    var handleEditWallet = function (walletSettings, userData) {
        var newWallets = __spreadArray([], wallets);
        if (userData >= 0) {
            newWallets[userData] = walletSettings;
        }
        else {
            newWallets.push(walletSettings);
        }
        props.onChange(newWallets);
        setEditingWallet(undefined);
    };
    var handleDeleteWallet = function (walletSettings, userData) {
        dialog.confirm({
            title: t('Remove Custom Investment'),
            message: t('CONFIRM_REMOVE', { name: shortenAddress(walletSettings.address) })
        })
            .then(function () {
            var newWallets = __spreadArray([], wallets);
            newWallets.splice(userData, 1);
            props.onChange(newWallets);
        });
    };
    var handleCloseEditWalletDialog = function () {
        setEditingWallet(undefined);
    };
    return (React.createElement(React.Fragment, null,
        Boolean(editingWallet) &&
            React.createElement(EditWalletDialog, { walletSettings: editingWallet === null || editingWallet === void 0 ? void 0 : editingWallet.settings, userData: editingWallet === null || editingWallet === void 0 ? void 0 : editingWallet.index, onCloseDialog: handleCloseEditWalletDialog, onClickConfirm: handleEditWallet }),
        React.createElement(Box, { className: classes.flexColumn },
            React.createElement(Box, { className: classes.flexRow },
                React.createElement(DescriptionBox, { text: t('Wallets'), description: t('Configure up to 5 wallets'), className: classes.bottomMargin }),
                React.createElement(IconButton, { onClick: handleClickAddWallet, disabled: wallets.length >= 4 },
                    React.createElement(AddCircleOutlineIcon, null))),
            React.createElement(WalletsTable, { account: account, walletAlias: walletAlias, wallets: wallets, onClickEdit: handleClickEditWallet, onClickDelete: handleDeleteWallet }))));
};
export default Wallets;
