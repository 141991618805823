var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
var BeltIcon = function (props) {
    return (React.createElement(SvgIcon, __assign({ xmlns: "http://www.w3.org/2000/svg", width: 37.039, height: 37.039, viewBox: "0 0 9.8 9.8" }, props, { key: "belt" }),
        React.createElement("defs", null,
            React.createElement("filter", { id: "belt_prefix__a", width: 1.457, height: 1.457, x: -0.229, y: -0.229, filterUnits: "objectBoundingBox" },
                React.createElement("feOffset", { in: "SourceAlpha", result: "shadowOffsetOuter1" }),
                React.createElement("feGaussianBlur", { in: "shadowOffsetOuter1", result: "shadowBlurOuter1", stdDeviation: 2 }),
                React.createElement("feColorMatrix", { in: "shadowBlurOuter1", result: "shadowMatrixOuter1", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" }),
                React.createElement("feMerge", null,
                    React.createElement("feMergeNode", { in: "shadowMatrixOuter1" }),
                    React.createElement("feMergeNode", { in: "SourceGraphic" })))),
        React.createElement("g", { filter: "url(#belt_prefix__a)", transform: "matrix(.26458 0 0 .26458 2.12 2.12)" },
            React.createElement("circle", { cx: 10.554, cy: 10.521, r: 17.499, fill: "#fcb251" }),
            React.createElement("path", { fill: "#fff", d: "M13.367 1.02c3.693 0 6.686 2.68 6.686 5.985 0 1.534-.645 2.932-1.704 3.99 1.06 1.06 1.704 2.458 1.704 3.991 0 3.306-2.993 5.985-6.686 5.985h-8.71c-.52 0-.941-.377-.941-.843V13.59h4.457v3.017c0 .207.187.374.418.374h4.776c1.231 0 2.229-.893 2.229-1.995s-.998-1.995-2.229-1.995H8.843v-3.99h4.525c1.231 0 2.229-.894 2.229-1.996 0-1.101-.998-1.995-2.229-1.995H8.592c-.231 0-.418.168-.418.374v3.017H3.717V1.863c0-.466.421-.843.941-.843zM4.058 9H7.83c.165 0 .304.105.336.245l.007.062v3.376c0 .149-.118.272-.274.301l-.07.006h-3.77c-.166 0-.305-.105-.337-.245l-.006-.062V9.307c0-.148.117-.272.273-.3L4.059 9h3.77zM3.046 9v3.99h-.883c-1.628-.098-2.11-1.995-2.11-1.995s.444-1.748 1.92-1.974l.19-.02h.883zm4.015.842H4.827a.171.171 0 00-.162.105l-.009.048v2c0 .068.05.125.117.146l.054.007h2.234c.076 0 .14-.044.163-.105l.008-.048v-.579h-.947c-.26 0-.47-.188-.47-.42 0-.208.167-.38.386-.415l.084-.007h.947v-.579c0-.084-.077-.153-.171-.153zm5.493.663c-.303 0-.548.22-.548.49 0 .271.245.49.548.49.302 0 .548-.219.548-.49 0-.27-.246-.49-.548-.49zm-2.056 0c-.303 0-.548.22-.548.49 0 .271.245.49.548.49.302 0 .547-.219.547-.49 0-.27-.245-.49-.547-.49z" }))));
};
export default BeltIcon;
