var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState, useContext } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as CryptoJS from 'crypto-js';
import { useSnackbar } from 'notistack';
import { useDialog } from 'muibox';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import OfflineBoltOutlinedIcon from '@material-ui/icons/OfflineBoltOutlined';
import SaveIcon from '@material-ui/icons/Save';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import CloseIcon from '@material-ui/icons/Close';
import Link from '@material-ui/core/Link';
import { accountFromCurrentPath } from '../routes';
import { connectAccount, decrementBusyCounter, disconnectAccount, incrementBusyCounter, setAccountData } from '../../store/actions';
import { ConnectContext } from '../ConnectionProvider';
import { API_BASE_URL } from '../constants';
import DescriptionBox from './DescriptionBox';
import { getHeaders, isSameAddress, localeStringToNumber, shortenAddress } from '../utils';
import Wallets from './Wallets';
import CustomInvestments from './CustomInvestments';
import CustomVaults from './CustomVaults';
import IgnoredTokens from './IgnoredTokens';
var useStyles = makeStyles(function (theme) {
    var _a;
    return createStyles({
        container: {
            paddingTop: theme.spacing(2),
        },
        paper: {
            padding: theme.spacing(2),
        },
        flexColumn: {
            display: 'flex',
            flexDirection: 'column',
        },
        flexRow: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
        },
        flexResponsive: (_a = {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
            },
            _a[theme.breakpoints.down('xs')] = {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'initial',
            },
            _a),
        bottomMargin: {
            marginBottom: theme.spacing(2),
        },
        divider: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        button: {
            margin: theme.spacing(1),
        },
        leftIcon: {
            marginRight: theme.spacing(1),
        },
        input: {
            '& input:-webkit-autofill': {
                '-webkit-box-shadow': "0 0 0 30px " + theme.palette.background.default + " inset",
                '-webkit-text-fill-color': theme.palette.text.primary,
                '&:hover, &:focus, &:active': {
                    '-webkit-box-shadow': "0 0 0 30px " + theme.palette.background.default + " inset",
                    '-webkit-text-fill-color': theme.palette.text.primary,
                },
            },
        },
        readMoreTelegram: {
            verticalAlign: 'bottom'
        },
    });
});
var Settings = function (props) {
    var classes = useStyles();
    var enqueueSnackbar = useSnackbar().enqueueSnackbar;
    var t = useTranslation().t;
    var dialog = useDialog();
    var connection = useContext(ConnectContext);
    var _a = useState(undefined), secret = _a[0], setSecret = _a[1];
    var _b = useState(false), needToSign = _b[0], setNeedToSign = _b[1];
    var _c = useState(false), accountRequiresLogin = _c[0], setAccountRequiresLogin = _c[1];
    var _d = useState(''), walletAlias = _d[0], setWalletAlias = _d[1];
    var _e = useState([]), wallets = _e[0], setWallets = _e[1];
    var _f = useState(false), enableBinanceIntegration = _f[0], setEnableBinanceIntegration = _f[1];
    var _g = useState([]), customInvestments = _g[0], setCustomInvestments = _g[1];
    var _h = useState([]), customVaults = _h[0], setCustomVaults = _h[1];
    var _j = useState(), binanceIntegration = _j[0], setBinanceIntegration = _j[1];
    var _k = useState(false), activeMonitoringEnabled = _k[0], setActiveMonitoringEnabled = _k[1];
    var _l = useState([]), ignoredTokens = _l[0], setIgnoredTokens = _l[1];
    var _m = useState(''), timeWindow = _m[0], setTimeWindow = _m[1];
    var _o = useState(''), priceIncreaseThreshold = _o[0], setPriceIncreaseThreshold = _o[1];
    var _p = useState(''), priceDecreaseThreshold = _p[0], setPriceDecreaseThreshold = _p[1];
    var _q = useState(), telegramKey = _q[0], setTelegramKey = _q[1];
    var account = accountFromCurrentPath();
    var locale = props.settings.locale;
    var hasApiSignature = Boolean((props.connectedAccounts || {})[account]);
    var userSettings = props.accountData.userSettings;
    var busy = props.busy > 0;
    useEffect(function () {
        if (userSettings) {
            setAccountRequiresLogin(userSettings.accountRequiresLogin || false);
            setWalletAlias(userSettings.walletAlias || '');
            // clone objects so we can modify while user is editing
            setWallets(JSON.parse(JSON.stringify(userSettings.wallets || [])));
            setCustomInvestments(JSON.parse(JSON.stringify(userSettings.customInvestments || [])));
            setCustomVaults(JSON.parse(JSON.stringify(userSettings.customVaults || [])));
            setIgnoredTokens(JSON.parse(JSON.stringify(userSettings.ignoredTokens || [])));
            var binance = userSettings.binanceIntegration || {};
            setBinanceIntegration(JSON.parse(JSON.stringify(binance)));
            setEnableBinanceIntegration(Boolean(binance.apiKey) && Boolean(binance.apiSecret));
            var monitoring = (userSettings.activeMonitoring || { enabled: false, timeWindow: 1, priceIncreaseThreshold: 2, priceDecreaseThreshold: 2 });
            setActiveMonitoringEnabled(monitoring.enabled);
            setTimeWindow("" + monitoring.timeWindow);
            setPriceIncreaseThreshold(monitoring.priceIncreaseThreshold ? monitoring.priceIncreaseThreshold.toString() : '');
            setPriceDecreaseThreshold(monitoring.priceDecreaseThreshold ? monitoring.priceDecreaseThreshold.toString() : '');
        }
        else {
            fetchSettings(function (accountDataResponse) {
                props.setAccountData(accountDataResponse.result || {});
                setTelegramKey(accountDataResponse.telegramKey);
            });
        }
    }, [userSettings]);
    useEffect(function () {
        if (connection.isConnected && needToSign) {
            setNeedToSign(false);
            signHash();
        }
    }, [connection.isConnected, needToSign]);
    useEffect(function () {
        if (connection.address && !isSameAddress(connection.address, account)) {
            var connectedAddress = shortenAddress(connection.address);
            var currentAddress = shortenAddress(account);
            dialog.alert(t("DIFFERENT_ADDRESS", { connectedAddress: connectedAddress, currentAddress: currentAddress }));
        }
    }, [connection.address]);
    var fetchSettings = function (onSuccess) {
        props.incrementBusyCounter();
        fetch(API_BASE_URL + "/accounts/" + account + "/settings", { headers: getHeaders(props.connectedAccounts, account) })
            .then(function (response) { return response.json(); })
            .then(function (data) {
            if (data.success) {
                onSuccess(data);
            }
            else {
                enqueueSnackbar(t(data.message), { variant: 'error' });
            }
            props.decrementBusyCounter();
        })
            .catch(function () {
            props.decrementBusyCounter();
            enqueueSnackbar(t('Error retrieving settings, please try again'), { variant: 'warning' });
        });
    };
    var tryToDisconnect = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!connection.disconnect) return [3 /*break*/, 2];
                    return [4 /*yield*/, connection.disconnect()];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var signHash = function () { return __awaiter(void 0, void 0, void 0, function () {
        var loginMessage, signature;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    loginMessage = CryptoJS.SHA256(account.toLowerCase() + "_" + secret).toString(CryptoJS.enc.Base64);
                    if (!!isSameAddress(connection.address, account)) return [3 /*break*/, 2];
                    // editing address doesn't match address, try to disconnect wallet
                    return [4 /*yield*/, tryToDisconnect()];
                case 1:
                    // editing address doesn't match address, try to disconnect wallet
                    _b.sent();
                    return [2 /*return*/];
                case 2: return [4 /*yield*/, ((_a = connection
                        .signer) === null || _a === void 0 ? void 0 : _a.signMessage(loginMessage).catch(function (ex) { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    console.log("Exception waiting for signature: " + JSON.stringify(ex));
                                    return [4 /*yield*/, tryToDisconnect()];
                                case 1:
                                    _a.sent();
                                    enqueueSnackbar(t('Signature cannot be verified, please try again'), { variant: 'warning' });
                                    return [2 /*return*/];
                            }
                        });
                    }); }))];
                case 3:
                    signature = _b.sent();
                    if (!signature) return [3 /*break*/, 5];
                    // before caching this account, let's check that the account belongs to the address in the signature
                    return [4 /*yield*/, fetch(API_BASE_URL + "/accounts/" + account + "/settings", {
                            method: 'POST',
                            mode: 'cors',
                            headers: {
                                'Content-Type': 'application/json',
                                'x-api-signature': signature,
                            },
                            body: '{}'
                        })
                            .then(function (response) { return response.json(); })
                            .then(function (data) {
                            if (data.success) {
                                props.connectAccount(account, signature);
                            }
                            else {
                                enqueueSnackbar(t(data.message), { variant: 'error' });
                            }
                        })
                            .catch(function () {
                            enqueueSnackbar(t('Signature cannot be verified, please try again'), { variant: 'warning' });
                        })];
                case 4:
                    // before caching this account, let's check that the account belongs to the address in the signature
                    _b.sent();
                    return [3 /*break*/, 7];
                case 5: 
                // user cancelled, disconnect wallet
                return [4 /*yield*/, tryToDisconnect()];
                case 6:
                    // user cancelled, disconnect wallet
                    _b.sent();
                    _b.label = 7;
                case 7: return [2 /*return*/];
            }
        });
    }); };
    var handleLogout = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    props.disconnectAccount(account);
                    return [4 /*yield*/, tryToDisconnect()];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var handleLogin = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, accountData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!!secret) return [3 /*break*/, 3];
                    props.incrementBusyCounter();
                    return [4 /*yield*/, fetch(API_BASE_URL + "/accounts/" + account + "/settings")];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, response.json()];
                case 2:
                    accountData = _a.sent();
                    setSecret(accountData.secret);
                    props.decrementBusyCounter();
                    _a.label = 3;
                case 3:
                    if (!connection.connect) return [3 /*break*/, 5];
                    return [4 /*yield*/, connection.connect()];
                case 4:
                    _a.sent();
                    _a.label = 5;
                case 5:
                    setNeedToSign(true);
                    return [2 /*return*/];
            }
        });
    }); };
    var handleRequireLogin = function (event) {
        setAccountRequiresLogin(event.target.checked);
    };
    var handleChangeWalletAlias = function (event) {
        setWalletAlias(event.target.value);
    };
    var handleEnableBinanceIntegration = function () {
        setEnableBinanceIntegration(!enableBinanceIntegration);
    };
    var handleChangeBinanceIntegration = function (key) { return function (event) {
        var _a;
        setBinanceIntegration(__assign(__assign({}, binanceIntegration), (_a = {}, _a[key] = event.target.value, _a)));
    }; };
    var handleChangeInvestments = function (customInvestments) {
        setCustomInvestments(customInvestments);
    };
    var handleChangeVaults = function (customVaults) {
        setCustomVaults(customVaults);
    };
    var handleChangeIgnoredTokens = function (ignoredTokens) {
        setIgnoredTokens(ignoredTokens);
    };
    var handleChangeWallets = function (wallets) {
        setWallets(wallets);
    };
    var showTelegramKey = function (key) {
        dialog.confirm({
            title: t('Telegram Key'),
            message: t('TELEGRAM_KEY', { key: key }),
            ok: {
                text: t('Ok')
            },
            cancel: {
                text: t('Copy to Clipboard')
            }
        })
            .catch(function () {
            // note: cancel action was converted to copy-to-clipboard
            navigator.clipboard.writeText(key);
        });
    };
    var handleGetTelegramKey = function () {
        if (!hasApiSignature) {
            dialog.alert(t('LOGIN_TO_GET_TELEGRAM_KEY'));
            return;
        }
        if (telegramKey) {
            showTelegramKey(telegramKey);
            return;
        }
        fetchSettings(function (accountDataResponse) {
            var key = accountDataResponse.telegramKey;
            if (key) {
                setTelegramKey(key);
                showTelegramKey(key);
            }
            else {
                enqueueSnackbar(t('Error getting Telegram key'), { variant: 'error' });
            }
        });
    };
    var handleEnableActiveMonitoring = function () {
        setActiveMonitoringEnabled(!activeMonitoringEnabled);
    };
    var handleChangeTimeWindow = function (event) {
        setTimeWindow(event.target.value);
    };
    var handleChangePriceIncreaseThreshold = function (event) {
        setPriceIncreaseThreshold(event.target.value);
    };
    var handleChangePriceDecreaseThreshold = function (event) {
        setPriceDecreaseThreshold(event.target.value);
    };
    var isValidNumber = function (value) {
        var num = localeStringToNumber(locale, value);
        return !isNaN(num);
    };
    var isValidTimeWindow = timeWindow != '' && !isNaN(parseInt(timeWindow));
    var isValidPriceIncreaseThreshold = priceIncreaseThreshold == '' || isValidNumber(priceIncreaseThreshold);
    var isValidPriceDecreaseThreshold = priceDecreaseThreshold == '' || isValidNumber(priceDecreaseThreshold);
    var handleBackToAccountClick = function () {
        if (account) {
            props.history.push("/account/" + account);
        }
    };
    var canSave = (!enableBinanceIntegration || (enableBinanceIntegration && (binanceIntegration === null || binanceIntegration === void 0 ? void 0 : binanceIntegration.apiKey) && (binanceIntegration === null || binanceIntegration === void 0 ? void 0 : binanceIntegration.apiSecret))) &&
        (!activeMonitoringEnabled || (activeMonitoringEnabled && isValidTimeWindow && isValidPriceIncreaseThreshold && isValidPriceDecreaseThreshold));
    var handleSave = function () { return __awaiter(void 0, void 0, void 0, function () {
        var disabledBinanceIntegration, activeMonitoring;
        return __generator(this, function (_a) {
            props.incrementBusyCounter();
            disabledBinanceIntegration = { apiKey: '', apiSecret: '' };
            activeMonitoring = {
                enabled: activeMonitoringEnabled,
                timeWindow: parseInt(timeWindow)
            };
            if (priceIncreaseThreshold != '') {
                activeMonitoring.priceIncreaseThreshold = localeStringToNumber(locale, priceIncreaseThreshold);
            }
            if (priceDecreaseThreshold != '') {
                activeMonitoring.priceDecreaseThreshold = localeStringToNumber(locale, priceDecreaseThreshold);
            }
            fetch(API_BASE_URL + "/accounts/" + account + "/settings", {
                method: 'POST',
                mode: 'cors',
                headers: __assign({ 'Content-Type': 'application/json' }, getHeaders(props.connectedAccounts, account)),
                body: JSON.stringify({
                    walletAlias: walletAlias,
                    accountRequiresLogin: accountRequiresLogin,
                    wallets: wallets,
                    binanceIntegration: (enableBinanceIntegration) ? binanceIntegration : disabledBinanceIntegration,
                    customInvestments: customInvestments,
                    customVaults: customVaults,
                    activeMonitoring: activeMonitoring,
                    ignoredTokens: ignoredTokens
                })
            })
                .then(function (response) { return response.json(); })
                .then(function (data) {
                if (data.success) {
                    props.setAccountData({
                        userSettings: data.result
                    });
                    props.history.push("/account/" + account, {
                        refreshTimestamp: (new Date()).getTime()
                    });
                }
                else {
                    enqueueSnackbar(t(data.message), { variant: 'error' });
                }
                props.decrementBusyCounter();
            })
                .catch(function () {
                props.decrementBusyCounter();
                enqueueSnackbar(t('Error saving settings, please try again'), { variant: 'error' });
            });
            return [2 /*return*/];
        });
    }); };
    return (React.createElement(React.Fragment, null,
        React.createElement(Container, { maxWidth: "md", className: classes.container },
            React.createElement(Grid, { container: true, spacing: 2, justify: "center" },
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Paper, { className: classes.paper },
                        React.createElement(Box, { className: classes.flexResponsive },
                            React.createElement(DescriptionBox, { text: (hasApiSignature) ?
                                    t('You are currently logged-in') :
                                    t('Your account is not logged-in'), description: (React.createElement(Box, null,
                                    (hasApiSignature) ?
                                        t('If you logout, a new signature will be required to confirm the account ownership') :
                                        t('Login to modify your settings'),
                                    "\u00A0",
                                    React.createElement(Link, { target: "_blank", rel: "noopener", href: "https://docs.walletnow.net/features/login#security-considerations" },
                                        t('About security'),
                                        " ",
                                        React.createElement(OpenInNewIcon, { className: classes.readMoreTelegram })))) }),
                            React.createElement(Button, { variant: "contained", color: "primary", size: "large", className: classes.button, disabled: !hasApiSignature && busy, onClick: (hasApiSignature) ? handleLogout : handleLogin },
                                (hasApiSignature) ?
                                    React.createElement(ExitToAppIcon, { className: classes.leftIcon }) :
                                    React.createElement(OfflineBoltOutlinedIcon, { className: classes.leftIcon }),
                                (hasApiSignature) ?
                                    t('Logout') :
                                    t('Login'))))),
                React.createElement(Grid, { item: true, xs: 12 },
                    busy && React.createElement(Box, { p: 2, width: "100%", textAlign: "center" },
                        React.createElement(CircularProgress, null)),
                    !busy && (React.createElement(Paper, { className: classes.paper },
                        React.createElement(Box, { className: classes.flexColumn },
                            React.createElement(DescriptionBox, { text: t('Alias'), description: t('Optionally set a name for your account'), className: classes.bottomMargin }),
                            React.createElement(TextField, { className: classes.input, id: "wallet-alias", value: walletAlias, onChange: handleChangeWalletAlias, variant: "outlined", fullWidth: true })),
                        React.createElement(Divider, { className: classes.divider }),
                        React.createElement(Box, { className: classes.flexRow },
                            React.createElement(DescriptionBox, { text: t('Require login'), description: t('This account would be accessible only by logged-in users (private account)') }),
                            React.createElement(Switch, { checked: accountRequiresLogin, onChange: handleRequireLogin, value: "true", color: "primary", inputProps: { 'aria-label': t('Require login') } })),
                        React.createElement(Divider, { className: classes.divider }),
                        React.createElement(Box, { className: classes.flexColumn },
                            React.createElement(Box, { className: classes.flexRow },
                                React.createElement(DescriptionBox, { text: t('Binance Integration'), description: t('Enable Binance integration'), className: classes.bottomMargin }),
                                React.createElement(Switch, { checked: enableBinanceIntegration, onChange: handleEnableBinanceIntegration, value: "true", color: "primary", inputProps: { 'aria-label': t('Binance Integration') } })),
                            React.createElement(TextField, { required: enableBinanceIntegration, disabled: !enableBinanceIntegration, error: enableBinanceIntegration && !(binanceIntegration === null || binanceIntegration === void 0 ? void 0 : binanceIntegration.apiKey), className: classes.input + " " + classes.bottomMargin, id: "binance-api-key", label: t('Binance API Key'), value: binanceIntegration === null || binanceIntegration === void 0 ? void 0 : binanceIntegration.apiKey, onChange: handleChangeBinanceIntegration('apiKey'), variant: "outlined", fullWidth: true }),
                            React.createElement(TextField, { required: enableBinanceIntegration, disabled: !enableBinanceIntegration, error: enableBinanceIntegration && !(binanceIntegration === null || binanceIntegration === void 0 ? void 0 : binanceIntegration.apiSecret), className: classes.input, id: "binance-api-secret", label: t('Binance API Secret'), value: binanceIntegration === null || binanceIntegration === void 0 ? void 0 : binanceIntegration.apiSecret, onChange: handleChangeBinanceIntegration('apiSecret'), variant: "outlined", fullWidth: true, type: "password" })),
                        React.createElement(Divider, { className: classes.divider }),
                        React.createElement(Box, { className: classes.flexColumn },
                            React.createElement(Box, { className: classes.flexRow },
                                React.createElement(DescriptionBox, { text: t('Active Monitoring'), description: t('Enable active monitoring on Telegram'), className: classes.bottomMargin }),
                                "\u00A0",
                                React.createElement(Switch, { checked: activeMonitoringEnabled, onChange: handleEnableActiveMonitoring, value: "true", color: "primary", inputProps: { 'aria-label': t('Active Monitoring') } })),
                            React.createElement(Grid, { container: true, spacing: 2 },
                                React.createElement(Grid, { item: true, xs: 12, sm: 4 },
                                    React.createElement(TextField, { required: activeMonitoringEnabled, disabled: !activeMonitoringEnabled, error: activeMonitoringEnabled && !isValidTimeWindow, className: classes.input, id: "time-window-key", label: t('Time Window'), value: timeWindow, onChange: handleChangeTimeWindow, variant: "outlined", fullWidth: true, type: "number" })),
                                React.createElement(Grid, { item: true, xs: 12, sm: 4 },
                                    React.createElement(TextField, { disabled: !activeMonitoringEnabled, error: activeMonitoringEnabled && !isValidPriceIncreaseThreshold, className: classes.input, id: "price-increase-key", label: t('Price Increase %'), value: priceIncreaseThreshold, onChange: handleChangePriceIncreaseThreshold, variant: "outlined", fullWidth: true, type: "number" })),
                                React.createElement(Grid, { item: true, xs: 12, sm: 4 },
                                    React.createElement(TextField, { disabled: !activeMonitoringEnabled, error: activeMonitoringEnabled && !isValidPriceDecreaseThreshold, className: classes.input, id: "price-decrease-key", label: t('Price Drop %'), value: priceDecreaseThreshold, onChange: handleChangePriceDecreaseThreshold, variant: "outlined", fullWidth: true, type: "number" })),
                                React.createElement(Grid, { item: true, xs: 12 },
                                    React.createElement(Link, { target: "_blank", rel: "noopener", href: "https://docs.walletnow.net/features/active-monitoring" },
                                        t('Read more about this feature'),
                                        " ",
                                        React.createElement(OpenInNewIcon, { className: classes.readMoreTelegram }))))),
                        React.createElement(Divider, { className: classes.divider }),
                        React.createElement(Wallets, { account: account, walletAlias: walletAlias, wallets: wallets, onChange: handleChangeWallets }),
                        React.createElement(Divider, { className: classes.divider }),
                        React.createElement(CustomInvestments, { customInvestments: customInvestments, onChange: handleChangeInvestments }),
                        React.createElement(Divider, { className: classes.divider }),
                        React.createElement(CustomVaults, { customVaults: customVaults, onChange: handleChangeVaults }),
                        React.createElement(Divider, { className: classes.divider }),
                        React.createElement(IgnoredTokens, { ignoredTokens: ignoredTokens, onChange: handleChangeIgnoredTokens }),
                        React.createElement(Divider, { className: classes.divider }),
                        React.createElement(Box, { className: classes.flexResponsive },
                            React.createElement(DescriptionBox, { text: t('Telegram Key'), description: (React.createElement(Box, null,
                                    t('Connect your Telegram account'),
                                    "\u00A0",
                                    React.createElement(Link, { target: "_blank", rel: "noopener", href: "https://docs.walletnow.net/features/telegram-bot" },
                                        t('Read more'),
                                        " ",
                                        React.createElement(OpenInNewIcon, { className: classes.readMoreTelegram })))) }),
                            React.createElement(Button, { variant: "contained", color: "primary", size: "large", className: classes.button, onClick: handleGetTelegramKey },
                                React.createElement(VpnKeyIcon, { className: classes.leftIcon }),
                                t('Get Key'))),
                        React.createElement(Divider, { className: classes.divider }),
                        React.createElement(Box, { className: classes.flexRow },
                            React.createElement(Box, { flex: '1 0 0' }),
                            React.createElement(Button, { variant: "outlined", color: "default", size: "large", className: classes.button, onClick: handleBackToAccountClick },
                                React.createElement(CloseIcon, { className: classes.leftIcon }),
                                t('Close')),
                            React.createElement(Button, { variant: "contained", color: "primary", size: "large", className: classes.button, onClick: handleSave, disabled: !canSave },
                                React.createElement(SaveIcon, { className: classes.leftIcon }),
                                t('Save'))))))))));
};
var mapStateToProps = function (state) { return (__assign({}, state)); };
var mapDispatchToProps = function (dispatch) {
    return bindActionCreators({ setAccountData: setAccountData, incrementBusyCounter: incrementBusyCounter, decrementBusyCounter: decrementBusyCounter, connectAccount: connectAccount, disconnectAccount: disconnectAccount }, dispatch);
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Settings));
