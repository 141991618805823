var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDialog } from 'muibox';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DescriptionBox from './DescriptionBox';
import IgnoredTokensTable from './IgnoredTokensTable';
import EditIgnoredTokenDialog from './EditIgnoredTokenDialog';
var useStyles = makeStyles(function (theme) {
    return createStyles({
        flexColumn: {
            display: 'flex',
            flexDirection: 'column',
        },
        flexRow: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
        },
        bottomMargin: {
            marginBottom: theme.spacing(2),
        },
    });
});
var IgnoredTokens = function (props) {
    var classes = useStyles();
    var dialog = useDialog();
    var t = useTranslation().t;
    var ignoredTokens = props.ignoredTokens;
    var _a = useState(), editingIgnoredToken = _a[0], setEditingIgnoredToken = _a[1];
    var handleClickAddIgnoredToken = function () {
        setEditingIgnoredToken({
            ignoredToken: { token: '' },
            index: -1
        });
    };
    var handleClickEditIgnoredToken = function (ignoredToken, index) {
        setEditingIgnoredToken({ ignoredToken: ignoredToken, index: index });
    };
    var handleEditIgnoredToken = function (ignoredToken, userData) {
        var newIgnoredTokens = __spreadArray([], ignoredTokens);
        if (userData >= 0) {
            newIgnoredTokens[userData] = ignoredToken;
        }
        else {
            newIgnoredTokens.push(ignoredToken);
        }
        props.onChange(newIgnoredTokens);
        setEditingIgnoredToken(undefined);
    };
    var handleDeleteIgnoredToken = function (ignoredToken, userData) {
        dialog.confirm({
            title: t('Remove Ignored Token'),
            message: t('CONFIRM_REMOVE', { name: ignoredToken.token })
        })
            .then(function () {
            var newIgnoredTokens = __spreadArray([], ignoredTokens);
            newIgnoredTokens.splice(userData, 1);
            props.onChange(newIgnoredTokens);
        });
    };
    var handleCloseEditIgnoredTokenDialog = function () {
        setEditingIgnoredToken(undefined);
    };
    return (React.createElement(React.Fragment, null,
        Boolean(editingIgnoredToken) &&
            React.createElement(EditIgnoredTokenDialog, { ignoredToken: editingIgnoredToken === null || editingIgnoredToken === void 0 ? void 0 : editingIgnoredToken.ignoredToken, userData: editingIgnoredToken === null || editingIgnoredToken === void 0 ? void 0 : editingIgnoredToken.index, onCloseDialog: handleCloseEditIgnoredTokenDialog, onClickConfirm: handleEditIgnoredToken }),
        React.createElement(Box, { className: classes.flexColumn },
            React.createElement(Box, { className: classes.flexRow },
                React.createElement(DescriptionBox, { text: t('Ignored Tokens'), description: t('List of tokens to be ignored'), className: classes.bottomMargin }),
                React.createElement(IconButton, { onClick: handleClickAddIgnoredToken },
                    React.createElement(AddCircleOutlineIcon, null))),
            React.createElement(IgnoredTokensTable, { ignoredTokens: ignoredTokens, onClickEdit: handleClickEditIgnoredToken, onClickDelete: handleDeleteIgnoredToken }))));
};
export default IgnoredTokens;
