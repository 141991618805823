var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
var BNB = function (props) {
    return (React.createElement(SvgIcon, __assign({}, props, { viewBox: "0 0 2500 2500", key: "bnb" }),
        React.createElement("defs", null,
            React.createElement("style", null, ".bnb_prefix_cls-1{fill:#f3ba2f}")),
        React.createElement("title", null, "bi"),
        React.createElement("g", { id: "bnb_prefix_2", "data-name": "Layer 2" },
            React.createElement("g", { id: "bnb_prefix_1-2", "data-name": "Layer 1" },
                React.createElement("path", { className: "bnb_prefix_cls-1", d: "M764.48 1050.52L1250 565l485.75 485.73 282.5-282.5L1250 0 482 768l282.49 282.5M0 1250l282.51-282.55L565 1249.94l-282.51 282.51zm764.48 199.51L1250 1935l485.74-485.72 282.65 282.35-.14.15L1250 2500l-768-768-.4-.4 282.91-282.12M1935 1250.12l282.51-282.51L2500 1250.1l-282.5 282.51z" }),
                React.createElement("path", { className: "bnb_prefix_cls-1", d: "M1536.52 1249.85h.12L1250 963.19 1038.13 1175l-24.34 24.35-50.2 50.21-.4.39.4.41L1250 1536.81l286.66-286.66.14-.16-.26-.14" })))));
};
export default BNB;
