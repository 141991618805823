var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
var Cream = function (props) {
    return (React.createElement(SvgIcon, __assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 75 75" }, props, { key: "cream" }),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "prefix__a" },
                React.createElement("path", { fill: "none", d: "M0 0h75v75H0z" }))),
        React.createElement("title", null, "Asset 2"),
        React.createElement("g", { "data-name": "Layer 2" },
            React.createElement("g", { clipPath: "url(#prefix__a)", "data-name": "Layer 1" },
                React.createElement("path", { d: "M40.52 39.27l13.15 13.15a2.51 2.51 0 01-.25 3.76 23.76 23.76 0 11.09-37.3 2.48 2.48 0 01.16 3.69L40.51 35.73a2.5 2.5 0 00-.54.81 2.65 2.65 0 00-.19 1 2.46 2.46 0 00.2 1 2.4 2.4 0 00.54.81zM37.5 0A37.5 37.5 0 1075 37.5 37.5 37.5 0 0037.5 0z", fill: "#69e2dc" })))));
};
export default Cream;
