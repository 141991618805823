var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDialog } from 'muibox';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DescriptionBox from './DescriptionBox';
import CustomVaultsTable from './CustomVaultsTable';
import EditCustomVaultDialog from './EditCustomVaultDialog';
var useStyles = makeStyles(function (theme) {
    return createStyles({
        flexColumn: {
            display: 'flex',
            flexDirection: 'column',
        },
        flexRow: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
        },
        bottomMargin: {
            marginBottom: theme.spacing(2),
        },
    });
});
var CustomVaults = function (props) {
    var classes = useStyles();
    var dialog = useDialog();
    var t = useTranslation().t;
    var customVaults = props.customVaults;
    var _a = useState(), editingCustomVault = _a[0], setEditingCustomVault = _a[1];
    var handleClickAddCustomVault = function () {
        setEditingCustomVault({
            customVault: { vaultAddress: '', name: '', link: '' },
            index: -1
        });
    };
    var handleClickEditCustomVault = function (customVault, index) {
        setEditingCustomVault({ customVault: customVault, index: index });
    };
    var handleEditCustomVault = function (customVault, userData) {
        var newCustomVaults = __spreadArray([], customVaults);
        if (userData >= 0) {
            newCustomVaults[userData] = customVault;
        }
        else {
            newCustomVaults.push(customVault);
        }
        props.onChange(newCustomVaults);
        setEditingCustomVault(undefined);
    };
    var handleDeleteCustomVault = function (customVault, userData) {
        dialog.confirm({
            title: t('Remove Custom Vault'),
            message: t('CONFIRM_REMOVE', { name: customVault.name })
        })
            .then(function () {
            var newCustomVaults = __spreadArray([], customVaults);
            newCustomVaults.splice(userData, 1);
            props.onChange(newCustomVaults);
        });
    };
    var handleCloseEditCustomVaultDialog = function () {
        setEditingCustomVault(undefined);
    };
    return (React.createElement(React.Fragment, null,
        Boolean(editingCustomVault) &&
            React.createElement(EditCustomVaultDialog, { customVault: editingCustomVault === null || editingCustomVault === void 0 ? void 0 : editingCustomVault.customVault, userData: editingCustomVault === null || editingCustomVault === void 0 ? void 0 : editingCustomVault.index, onCloseDialog: handleCloseEditCustomVaultDialog, onClickConfirm: handleEditCustomVault }),
        React.createElement(Box, { className: classes.flexColumn },
            React.createElement(Box, { className: classes.flexRow },
                React.createElement(DescriptionBox, { text: t('Custom Vaults'), description: t('Configure custom vaults'), className: classes.bottomMargin }),
                React.createElement(IconButton, { onClick: handleClickAddCustomVault },
                    React.createElement(AddCircleOutlineIcon, null))),
            React.createElement(CustomVaultsTable, { customVaults: customVaults, onClickEdit: handleClickEditCustomVault, onClickDelete: handleDeleteCustomVault }))));
};
export default CustomVaults;
