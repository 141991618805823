import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { shortenAddress } from '../utils';
var useStyles = makeStyles(function (theme) {
    return createStyles({
        table: {
            width: '100%',
            overflowX: 'auto',
        },
        marginLeft: {
            marginLeft: theme.spacing(2)
        },
        container: {
            overflow: 'auto'
        },
        box: {
            display: 'flex'
        }
    });
});
;
var IgnoredTokensTable = function (props) {
    var classes = useStyles();
    var t = useTranslation().t;
    var handleClickDelete = function (ignoredToken, index) { return function () {
        props.onClickDelete(ignoredToken, index);
    }; };
    var handleClickEdit = function (ignoredToken, index) { return function () {
        props.onClickEdit(ignoredToken, index);
    }; };
    return (React.createElement(Box, { className: classes.container },
        React.createElement(Table, { className: classes.table, size: "small" },
            React.createElement(TableHead, null,
                React.createElement(TableRow, null,
                    React.createElement(TableCell, { width: "99%" }, t('Token Address')),
                    React.createElement(TableCell, { width: "1%" }))),
            React.createElement(TableBody, null, props.ignoredTokens.map(function (ignoredToken, index) { return (React.createElement(TableRow, { key: ignoredToken.token + "_" + index },
                React.createElement(TableCell, null, shortenAddress(ignoredToken.token)),
                React.createElement(TableCell, null,
                    React.createElement(Box, { className: classes.box },
                        React.createElement(IconButton, { size: "small", onClick: handleClickDelete(ignoredToken, index) },
                            React.createElement(DeleteForeverOutlinedIcon, null)),
                        React.createElement(IconButton, { className: classes.marginLeft, size: "small", onClick: handleClickEdit(ignoredToken, index) },
                            React.createElement(EditOutlinedIcon, null)))))); })))));
};
export default IgnoredTokensTable;
