var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
var MDex = function (props) {
    return (React.createElement(SvgIcon, __assign({ xmlns: "http://www.w3.org/2000/svg", width: "200pt", height: "200pt", viewBox: "0 0 200 200" }, props, { key: "mdex" }),
        React.createElement("circle", { cx: 100, cy: 100, r: 100, fill: "#131735" }),
        React.createElement("path", { d: "M133.65 45.862c-4.75-.112-9.601.688-13.751 2.438-3.2 1.3-13.9 9.9-14.7 11.7-.2.4 2.7 3.4 6.3 6.5l6.601 5.8 3.2-3c4.4-4.2 7.3-5.499 11.9-5.499 4.5.1 9.199 3.099 11.399 7.399 1.4 2.7 1.6 8 1.3 43.5-.4 39.8-.4 40.3 1.6 40.3 3.3-.1 10-3.6 12.3-6.5 4.1-5.1 4.3-8.4 4-45.5l-.3-35.5-2.9-5.3c-3.4-6-7.7-10.2-13.8-13.3-3.75-1.9-8.4-2.925-13.15-3.038z", fill: "#4ba8db" }),
        React.createElement("path", { d: "M80.8 48.4c2.8 1.4 11.6 8.7 19.8 16.2 8.2 7.5 17.7 16.2 21.1 19.2 8.3 7.3 10.3 11.6 10.3 21.8 0 10.1-3.4 17.9-10.5 24.2-23.5 20.7-59.9-.6-53.5-31.5 1.1-5 2-6.6 7.4-12l6.1-6.3 7 6.5 7 6.4-3.8 3.7c-4.9 4.7-6 7.5-4.7 12.3 3.2 11.9 19.6 13.7 25 2.7 2.3-4.8 2.5-7.2.7-10.2-1.4-2.4-37.2-34.5-40.4-36.2-3.3-1.8-8.4-1.4-12.1.8-6.1 3.7-6.2 4.7-6.2 49.1v40.2l-4.2-.6c-7.6-1-13.2-7.3-14.3-16-.4-2.9-.5-19.6-.3-37.2l.3-32 2.6-5.5c6-12.6 17.5-19.4 31.1-18.6 4.4.3 8.3 1.3 11.6 3z", fill: "#fff" })));
};
export default MDex;
