var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
var Wallet = function (props) {
    return (React.createElement(SvgIcon, __assign({ xmlns: "http://www.w3.org/2000/svg", width: 64, height: 64, viewBox: "0 0 64 64" }, props, { key: "wallet" }),
        React.createElement("circle", { r: 32, cy: 32.06, cx: 32.034, className: "prefix__st0", fill: "#7762d4" }),
        React.createElement("path", { d: "M11.908 33.97c0 10.838 8.865 19.634 19.787 19.634 10.923 0 19.787-8.796 19.787-19.633 0-10.838-8.864-19.633-19.787-19.633-10.922 0-19.787 8.795-19.787 19.633z", opacity: 0.2, fill: "none", stroke: "#000", strokeWidth: 3.867 }),
        React.createElement("path", { d: "M11.795 31.032c0 10.837 8.864 19.633 19.787 19.633 10.922 0 19.787-8.796 19.787-19.633 0-10.838-8.865-19.634-19.787-19.634-10.923 0-19.787 8.796-19.787 19.634z", fill: "none", stroke: "#e1c33b", strokeWidth: 3.867 }),
        React.createElement("path", { d: "M13.09 31.087c0 10.294 8.338 18.649 18.611 18.649 10.274 0 18.612-8.355 18.612-18.649S41.975 12.44 31.7 12.44c-10.273 0-18.61 8.354-18.61 18.648z", fill: "url(#prefix__K)" }),
        React.createElement("path", { d: "M14.172 31.215c0 9.572 7.83 17.341 17.478 17.341 9.647 0 17.477-7.769 17.477-17.341 0-9.573-7.83-17.342-17.477-17.342-9.648 0-17.478 7.77-17.478 17.342z", fill: "#e1c33b" }),
        React.createElement("path", { d: "M38.614 34.19q0 2.22-1.733 3.696-1.733 1.46-4.54 1.733v5.185h-1.69V39.69q-1.892-.015-3.553-.359-1.661-.358-2.879-.916V35.58h.23q.271.2.973.587.702.373 1.36.616.746.272 1.734.516 1.003.229 2.134.272v-6.202q-.573-.115-1.06-.215-.487-.114-.902-.23-2.335-.586-3.352-1.76-1.017-1.19-1.017-2.923 0-2.12 1.662-3.58 1.676-1.461 4.67-1.705V17.06h1.69v3.868q1.446.028 2.964.343t2.55.73v2.808h-.201q-1.074-.659-2.249-1.16-1.16-.516-3.065-.645v6.174q.43.071.931.2.502.115.874.186 2.134.459 3.294 1.576 1.175 1.117 1.175 3.05zm-7.964-5.256V23.02q-1.532.114-2.578.845-1.046.716-1.046 2.005 0 1.303.774 1.962.773.659 2.85 1.103zm5.257 5.629q0-1.346-.845-1.962-.831-.63-2.722-.989v5.93q1.719-.172 2.636-.874.93-.701.93-2.105z", fill: "#ad8613", "aria-label": "$", fontWeight: 400, fontSize: 29.333, fontFamily: "sans-serif" })));
};
export default Wallet;
