import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
import red from '@material-ui/core/colors/red';
import indigo from '@material-ui/core/colors/indigo';
import deepPurple from '@material-ui/core/colors/deepPurple';
var labelColor = '#3167a2';
var textHighlightColor = '#583592';
var lightBackground = '#f0e7ff';
var themes = {
    light: responsiveFontSizes(createMuiTheme({
        overrides: {
            MuiAppBar: {
                colorPrimary: {
                    backgroundColor: '#583592'
                }
            },
            MuiLink: {
                root: {
                    textDecoration: 'underline',
                    textDecorationStyle: 'dotted',
                    display: 'inline-block',
                    cursor: 'pointer',
                },
            },
            MuiToolbar: {
                root: {
                    backgroundColor: lightBackground
                }
            },
            MuiTypography: {
                h4: {
                    color: textHighlightColor
                },
                h6: {
                    color: labelColor
                }
            },
            MuiTableSortLabel: {
                root: {
                    color: labelColor
                },
                icon: {
                    width: 20,
                    height: 20,
                    fontSize: '1.2rem',
                }
            },
            MuiTabs: {
                indicator: {
                    backgroundColor: textHighlightColor
                }
            },
            MuiTab: {
                textColorInherit: {
                    '&$selected': {
                        color: textHighlightColor,
                        fontWeight: 'bold'
                    }
                }
            },
            MuiInputLabel: {
                root: {
                    color: labelColor
                }
            },
            MuiTableCell: {
                root: {
                    borderBottomColor: lightBackground
                },
                sizeSmall: {
                    paddingRight: 2,
                    paddingLeft: 2,
                }
            }
        },
        palette: {
            type: 'light',
            primary: indigo,
            secondary: deepPurple,
            error: {
                main: red.A400,
            },
        },
    })),
    dark: responsiveFontSizes(createMuiTheme({
        overrides: {
            MuiAppBar: {
                colorPrimary: {
                    backgroundColor: grey[900]
                }
            },
            MuiLink: {
                root: {
                    color: deepPurple[300],
                    textDecoration: 'underline',
                    textDecorationStyle: 'dotted',
                    display: 'inline-block',
                    cursor: 'pointer',
                },
            },
            MuiTableSortLabel: {
                icon: {
                    width: 20,
                    height: 20,
                    fontSize: '1.2rem',
                }
            },
            MuiTableCell: {
                sizeSmall: {
                    paddingRight: 2,
                    paddingLeft: 2,
                }
            }
        },
        palette: {
            type: 'dark',
            primary: indigo,
            secondary: deepPurple,
            error: {
                main: red.A400,
            },
        },
    }))
};
export default themes;
