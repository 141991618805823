var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
var Bunny = function (props) {
    return (React.createElement(SvgIcon, __assign({ xmlns: "http://www.w3.org/2000/svg", width: 640, height: 640, viewBox: "0 0 640 640" }, props, { key: "bunny" }),
        React.createElement("g", { fill: "none", fillRule: "evenodd" },
            React.createElement("circle", { fill: "#facfd8", cx: 320, cy: 320, r: 320 }),
            React.createElement("path", { d: "M244.982 414.923c11.1 0 20.404 3.67 27.912 11.009 7.509 7.339 11.263 16.717 11.263 28.134 0 11.417-3.754 20.713-11.263 27.89-7.508 7.176-16.812 10.764-27.912 10.764-10.773 0-19.996-3.588-27.668-10.764-7.671-7.177-11.507-16.473-11.507-27.89 0-11.417 3.836-20.795 11.507-28.134 7.672-7.34 16.895-11.01 27.668-11.01zm149.846 0c11.1 0 20.404 3.67 27.912 11.009 7.509 7.339 11.263 16.717 11.263 28.134 0 11.417-3.754 20.713-11.263 27.89-7.508 7.176-16.813 10.764-27.912 10.764-10.774 0-19.996-3.588-27.668-10.764-7.672-7.177-11.508-16.473-11.508-27.89 0-11.417 3.836-20.795 11.508-28.134 7.672-7.34 16.894-11.01 27.668-11.01zM276.812 147.28v91.008l-.002 2.027c-.015 8.316-.123 16.416-.325 24.298 14.087-1.73 28.48-2.594 43.175-2.594 15.04 0 29.761.905 44.165 2.716-.226-8.581-.338-17.397-.338-26.447V147.28h63.17v91.008l-.001 2.027c-.025 13.386-.29 26.21-.798 38.47a313.056 313.056 0 0112.083 4.314c36.958 14.054 69.95 33.19 98.979 57.41l-25.138 38.124-1.27-.936c-26.3-19.286-55.353-34.373-87.16-45.26-.476-.164-.952-.325-1.428-.486-1.6 16.157-3.45 32.397-5.55 48.721H374.26l-.569-4.028a1231.605 1231.605 0 01-6.673-57.579c-15.349-2.15-31.135-3.224-47.358-3.224-15.88 0-31.33 1.03-46.347 3.089a1661.683 1661.683 0 01-6.785 61.742h-42.113l-.57-4.028a1236.275 1236.275 0 01-5.445-44.957l-.787.265-1.421.484c-32.17 11.064-61.721 26.463-88.654 46.197L102.4 340.51l1.212-1.006c28.733-23.744 61.322-42.545 97.767-56.404 4.32-1.643 8.674-3.19 13.062-4.64-.533-12.835-.8-26.226-.8-40.17V147.28z", fill: "#ec6998", fillRule: "nonzero" }))));
};
export default Bunny;
