var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
var Venus = function (props) {
    return (React.createElement(SvgIcon, __assign({ xmlns: "http://www.w3.org/2000/svg", width: 88, height: 88, viewBox: "0 0 88 88" }, props, { key: "venus" }),
        React.createElement("path", { d: "M44 88c24.3 0 44-19.7 44-44S68.3 0 44 0 0 19.7 0 44s19.7 44 44 44z", fill: "url(#venus_gradient)" }),
        React.createElement("path", { d: "M66.453 46.45H21.548a2.724 2.724 0 00-2.716 2.715 2.724 2.724 0 002.716 2.717H32.83l7.645 20.189a2.725 2.725 0 002.679 1.749 2.725 2.725 0 002.679-1.749l7.645-20.19h12.976a2.724 2.724 0 002.716-2.716 2.724 2.724 0 00-2.716-2.716zm-23.3 17.356L38.637 51.88h9.032l-4.516 11.925zM21.548 41.38h44.906a2.724 2.724 0 002.716-2.717 2.724 2.724 0 00-2.716-2.716h-6.942l5.598-14.785a2.724 2.724 0 00-1.578-3.502 2.724 2.724 0 00-3.502 1.579l-6.328 16.708H32.603l-6.327-16.708a2.725 2.725 0 00-3.502-1.579 2.724 2.724 0 00-1.578 3.502l5.599 14.785h-5.247a2.724 2.724 0 00-2.716 2.716 2.724 2.724 0 002.716 2.716z", fill: "#fff" }),
        React.createElement("defs", null,
            React.createElement("linearGradient", { id: "venus_gradient", x1: 9.258, y1: 71.67, x2: 77.778, y2: 17.097, gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { stopColor: "#F8B342" }),
                React.createElement("stop", { offset: 1, stopColor: "#F2C35E" })))));
};
export default Venus;
