import HelpIcon from '@material-ui/icons/Help';
import ACryptoSIcon from './ACryptoS';
import ApeSwapIcon from './ApeSwap';
import AutoFarmIcon from './AutoFarm';
import BeefyIcon from './Beefy';
import BinanceIcon from './Binance';
import BlizzardIcon from './Blizzard';
import BunnyIcon from './Bunny';
import CreamIcon from './Cream';
import CustomIcon from './Custom';
import HyperJumpIcon from './HyperJump';
import JetFuelIcon from './JetFuel';
import MDexIcon from './MDex';
import PCSIcon from './PCS';
import PantherSwapIcon from './PantherSwap';
import VenusIcon from './Venus';
import WalletIcon from './Wallet';
import BDollarIcon from './BDollar';
import AaveIcon from './Aave';
import BeltIcon from './Belt';
import PolycatIcon from './Polycat';
import WaultIcon from './Wault';
import JaguarSwapIcon from './JaguarSwap';
import GarudaIcon from './Garuda';
import SwampIcon from './Swamp';
import TreeDefiIcon from './TreeDefi';
var protocolIcons = {
    'Aave': AaveIcon,
    'ACryptoS': ACryptoSIcon,
    'ApeSwap': ApeSwapIcon,
    'AutoFarm': AutoFarmIcon,
    'bDollar': BDollarIcon,
    'Beefy': BeefyIcon,
    'Belt': BeltIcon,
    'Binance': BinanceIcon,
    'Blizzard': BlizzardIcon,
    'Bunny': BunnyIcon,
    'Cream': CreamIcon,
    'Custom': CustomIcon,
    'Garuda': GarudaIcon,
    'Hyper Jump': HyperJumpIcon,
    'JaguarSwap': JaguarSwapIcon,
    'JetFuel': JetFuelIcon,
    'MDex': MDexIcon,
    'PantherSwap': PantherSwapIcon,
    'PCS': PCSIcon,
    'Polycat': PolycatIcon,
    'Swamp': SwampIcon,
    'TreeDefi': TreeDefiIcon,
    'Venus': VenusIcon,
    'Wallet': WalletIcon,
    'Wault': WaultIcon,
};
export var getProtocolIcon = function (protocol) {
    return protocolIcons[protocol] || HelpIcon;
};
