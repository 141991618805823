import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TelegramIcon from '../icons/Telegram';
import TwitterIcon from '../icons/Twitter';
import GitHubIcon from '../icons/GitHub';
var useStyles = makeStyles(function (theme) {
    return createStyles({
        grid: {
            textAlign: 'center',
            marginTop: theme.spacing(3)
        }
    });
});
var Footer = function () {
    var classes = useStyles();
    return (React.createElement(Container, { maxWidth: "sm" },
        React.createElement(Grid, { container: true, className: classes.grid, spacing: 4 },
            React.createElement(Grid, { item: true, xs: 4 },
                React.createElement(IconButton, { target: "_blank", rel: "noopener", href: "https://t.me/WalletNow" },
                    React.createElement(TelegramIcon, null))),
            React.createElement(Grid, { item: true, xs: 4 },
                React.createElement(IconButton, { target: "_blank", rel: "noopener", href: "https://twitter.com/WalletNow" },
                    React.createElement(TwitterIcon, null))),
            React.createElement(Grid, { item: true, xs: 4 },
                React.createElement(IconButton, { target: "_blank", rel: "noopener", href: "https://github.com/wallet-now/" },
                    React.createElement(GitHubIcon, null))))));
};
export default Footer;
