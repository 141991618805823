var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, useLocation } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popper from '@material-ui/core/Popper';
import LanguageIcon from '@material-ui/icons/Translate';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RefreshIcon from '@material-ui/icons/Refresh';
import NoSsr from '@material-ui/core/NoSsr';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import CurrencyFlag from 'react-currency-flags';
import Logo from '../icons/Logo';
import { changeSettings } from '../store/actions';
import { getHeaders, isFreePlan, shortenAddress } from './utils';
import { API_BASE_URL, AVAILABLE_CURRENCIES, LOCALES, LOCALES_LABEL, PLAN_COLORS } from './constants';
import { accountFromCurrentPath } from './routes';
import PlanPopperContent from './PlanPopperContent';
import Medal from '../icons/Medal';
var useStyles = makeStyles(function (theme) {
    var _a, _b;
    return createStyles({
        '@global': {
            '#main-content': {
                outline: 0,
            },
        },
        appBar: (_a = {
                boxShadow: 'none'
            },
            _a[theme.breakpoints.down('xs')] = {
                '& .MuiToolbar-gutters': {
                    paddingLeft: 0,
                    paddingRight: 0,
                }
            },
            _a),
        toolbar: {
            backgroundColor: 'inherit'
        },
        logo: {
            transform: 'scale(1.6)',
            verticalAlign: 'middle',
        },
        grow: {
            flex: '1 1 auto',
        },
        title: {
            margin: theme.spacing(0, 1),
        },
        menuWithIcon: (_b = {
                margin: theme.spacing(0, 0.5, 0, 1),
                display: 'none'
            },
            _b[theme.breakpoints.up('md')] = {
                display: 'block',
            },
            _b),
        flag: {
            height: '11px',
            border: (theme.palette.type == 'light') ? '1px solid #ddd' : 'none',
        },
        currency: {
            paddingLeft: theme.spacing(1),
            fontSize: '75%'
        },
        planIconButton: {
            padding: theme.spacing(1),
        },
        planIcon: {
            width: theme.spacing(4),
            height: theme.spacing(4),
        },
        popper: {
            zIndex: 1000,
            maxWidth: 'calc(100% - 8px)',
        },
    });
});
var Header = function (props) {
    var classes = useStyles();
    var t = useTranslation().t;
    var location = useLocation();
    var _a = props.settings, mode = _a.mode, maskedSummary = _a.maskedSummary;
    var _b = useState(undefined), anchorEl = _b[0], setAnchorEl = _b[1];
    var _c = useState(undefined), mainMenu = _c[0], setMainMenu = _c[1];
    var _d = useState(undefined), languageMenu = _d[0], setLanguageMenu = _d[1];
    var _e = useState(undefined), currencyMenu = _e[0], setCurrencyMenu = _e[1];
    var _f = useState(false), openPopover = _f[0], setOpenPopover = _f[1];
    var underAccount = location.pathname.startsWith('/account/');
    var underSettings = location.pathname.endsWith('/settings/') || location.pathname.endsWith('/settings');
    var underLanding = !underAccount;
    var title = (underAccount) ?
        ((props.accountData.userSettings || {}).walletAlias || shortenAddress(props.accountData.address)) : '';
    var currentCurrency = props.settings.preferredCurrency || 'USD';
    var currentLocale = props.settings.locale || 'en-US';
    var currencies = ['USD'].concat((underLanding) ? AVAILABLE_CURRENCIES : Object.keys(props.accountData.conversionRates || {}).sort());
    var freePlan = isFreePlan(props.accountData);
    var membershipInfo = props.accountData.membershipInfo;
    var activeMembershipPlan = (membershipInfo || {}).activeMembershipPlan || 0;
    var theme = useTheme();
    var isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    var isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));
    var handleMenuIconClick = function (event) {
        setMainMenu(event.currentTarget);
    };
    var handleMainMenuClose = function () {
        setMainMenu(undefined);
    };
    var handleSettingsClick = function () {
        var account = accountFromCurrentPath();
        if (account) {
            props.history.push("/account/" + account + "/settings");
        }
        setMainMenu(undefined);
    };
    var handleLanguageIconClick = function (event) {
        setLanguageMenu(event.currentTarget);
    };
    var handleLanguageMenuClose = function () {
        setLanguageMenu(undefined);
    };
    var handleLanguageMenuClick = function (event) {
        saveSetting('locale', event.currentTarget.lang);
        setLanguageMenu(undefined);
    };
    var handleCurrencyIconClick = function (event) {
        setCurrencyMenu(event.currentTarget);
    };
    var handleCurrencyMenuClose = function () {
        setCurrencyMenu(undefined);
    };
    var handleCurrencyMenuClick = function (event) {
        saveSetting('preferredCurrency', event.currentTarget.dataset.currency);
        setCurrencyMenu(undefined);
    };
    var handleTogglePaletteType = function () {
        props.changeSettings({ mode: (mode == 'light') ? 'dark' : 'light' });
        setMainMenu(undefined);
    };
    var handleToggleMaskedSummary = function () {
        props.changeSettings({ maskedSummary: !maskedSummary });
        setMainMenu(undefined);
    };
    var handleHomeClick = function () {
        props.history.push('/');
    };
    var handlePlansClick = function () {
        props.history.push('/plans');
        setCurrencyMenu(undefined);
    };
    var handleBackToAccountClick = function () {
        var account = accountFromCurrentPath();
        if (account) {
            props.history.push("/account/" + account);
        }
        setMainMenu(undefined);
    };
    var handleRefreshPage = function () {
        var account = accountFromCurrentPath();
        props.history.replace("/account/" + account, {
            refreshTimestamp: (new Date()).getTime()
        });
    };
    var handlePopoverOpen = function (event) {
        setAnchorEl(event.currentTarget);
        setOpenPopover(true);
    };
    var handlePopoverClose = function () {
        setOpenPopover(false);
    };
    var saveSetting = function (settingName, settingValue) {
        var _a, _b;
        var account = accountFromCurrentPath();
        // sanity check
        if (!['preferredCurrency', 'locale'].includes(settingName)) {
            console.log('Invalid setting name');
            return;
        }
        // always save locally
        localStorage.setItem(settingName, settingValue);
        // update state
        props.changeSettings((_a = {}, _a[settingName] = settingValue, _a));
        // not logged - don't even try to save on the backend
        if (!props.connectedAccounts[account]) {
            return;
        }
        // best effort to save the setting on the backend
        fetch(API_BASE_URL + "/accounts/" + account + "/settings", {
            method: 'POST',
            mode: 'cors',
            headers: __assign({ 'Content-Type': 'application/json' }, getHeaders(props.connectedAccounts, account)),
            body: JSON.stringify((_b = {},
                _b[settingName] = settingValue,
                _b))
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ClickAwayListener, { onClickAway: handlePopoverClose },
            React.createElement(Popper, { open: openPopover, anchorEl: anchorEl, className: classes.popper, placement: "bottom" },
                React.createElement(PlanPopperContent, { membershipInfo: membershipInfo }))),
        React.createElement(AppBar, { position: "static", className: classes.appBar },
            React.createElement(Toolbar, { className: classes.toolbar },
                React.createElement(IconButton, { color: "primary", "aria-label": t('Home'), onClick: handleHomeClick },
                    React.createElement(Logo, { className: classes.logo })),
                React.createElement("div", { className: classes.grow }, !isExtraSmall && (React.createElement(Typography, { className: classes.title, variant: "h5" }, title))),
                membershipInfo && (React.createElement(Tooltip, { title: t('Plan') + ": " + t('PLAN_' + activeMembershipPlan), enterDelay: 300 },
                    React.createElement(IconButton, { className: classes.planIconButton, color: "inherit", onClick: handlePopoverOpen, "aria-label": t('Plan') },
                        React.createElement(Medal, __assign({ className: classes.planIcon }, PLAN_COLORS[activeMembershipPlan]))))),
                underAccount && !underSettings && (React.createElement(Tooltip, { title: t('Refresh'), enterDelay: 300 },
                    React.createElement(IconButton, { color: "inherit", onClick: handleRefreshPage, "aria-label": t('Refresh'), "data-ga-event-category": "header", "data-ga-event-action": "refresh" },
                        React.createElement(RefreshIcon, null)))),
                React.createElement(Tooltip, { title: t('Change Language'), enterDelay: 300 },
                    React.createElement(Button, { color: "inherit", "aria-owns": languageMenu ? 'language-menu' : undefined, "aria-haspopup": "true", "aria-label": t('Change Language'), onClick: handleLanguageIconClick, "data-ga-event-category": "header", "data-ga-event-action": "language" },
                        React.createElement(LanguageIcon, null),
                        React.createElement("span", { className: classes.menuWithIcon }, LOCALES_LABEL[currentLocale]),
                        React.createElement(ExpandMoreIcon, { fontSize: "small" }))),
                React.createElement(NoSsr, { defer: true },
                    React.createElement(Menu, { id: "language-menu", anchorEl: languageMenu, open: Boolean(languageMenu), onClose: handleLanguageMenuClose }, LOCALES.map(function (localeCode) { return (React.createElement(MenuItem, { component: "a", "data-no-link": "true", key: localeCode, selected: currentLocale === localeCode, onClick: handleLanguageMenuClick, lang: localeCode, hrefLang: localeCode }, LOCALES_LABEL[localeCode])); }))),
                React.createElement(Tooltip, { title: t('Currency'), enterDelay: 300 },
                    React.createElement(Button, { color: "inherit", "aria-owns": currencyMenu ? 'currency-menu' : undefined, "aria-haspopup": "true", "aria-label": t('Change Currency'), onClick: handleCurrencyIconClick, "data-ga-event-category": "header", "data-ga-event-action": "currency" },
                        React.createElement(MonetizationOnOutlinedIcon, null),
                        React.createElement("span", { className: classes.menuWithIcon }, currentCurrency),
                        React.createElement(ExpandMoreIcon, { fontSize: "small" }))),
                React.createElement(NoSsr, { defer: true },
                    React.createElement(Menu, { id: "currency-menu", anchorEl: currencyMenu, open: Boolean(currencyMenu), onClose: handleCurrencyMenuClose },
                        currencies.map(function (currency) { return (React.createElement(MenuItem, { component: "a", "data-no-link": "true", key: currency, selected: currentCurrency === currency, onClick: handleCurrencyMenuClick, "data-currency": currency },
                            React.createElement(CurrencyFlag, { className: classes.flag, currency: currency, size: "sm" }),
                            React.createElement("span", { className: classes.currency }, currency))); }),
                        !underLanding && freePlan && (React.createElement(MenuItem, { component: "a", "data-no-link": "true", onClick: handlePlansClick }, t('More currencies available, Upgrade now!'))))),
                !isSmall && (React.createElement(Tooltip, { title: t('Toggle Theme'), enterDelay: 300 },
                    React.createElement(IconButton, { color: "inherit", onClick: handleTogglePaletteType, "aria-label": t('Toggle Theme'), "data-ga-event-category": "header", "data-ga-event-action": "dark" }, mode === 'light' ? React.createElement(Brightness4Icon, null) : React.createElement(Brightness7Icon, null)))),
                !isSmall && (React.createElement(Tooltip, { title: maskedSummary ? t('Show Totals') : t('Hide Totals'), enterDelay: 300 },
                    React.createElement(IconButton, { color: "inherit", onClick: handleToggleMaskedSummary, "aria-label": maskedSummary ? t('Show Totals') : t('Hide Totals'), "data-ga-event-category": "header", "data-ga-event-action": "masked_summary" }, maskedSummary ? React.createElement(VisibilityIcon, null) : React.createElement(VisibilityOffIcon, null)))),
                underAccount &&
                    React.createElement(Tooltip, { title: t('Menu'), enterDelay: 300 },
                        React.createElement(IconButton, { color: "inherit", onClick: handleMenuIconClick, "aria-label": t('Menu'), "data-ga-event-category": "header", "data-ga-event-action": "dark" },
                            React.createElement(MoreVertIcon, null)))),
            React.createElement(NoSsr, { defer: true },
                React.createElement(Menu, { id: "main-menu", anchorEl: mainMenu, open: Boolean(mainMenu), onClose: handleMainMenuClose },
                    isSmall &&
                        React.createElement(MenuItem, { component: "a", "data-no-link": "true", onClick: handleTogglePaletteType }, t('Toggle palette')),
                    isSmall &&
                        React.createElement(MenuItem, { component: "a", "data-no-link": "true", onClick: handleToggleMaskedSummary }, maskedSummary ? t('Show Totals') : t('Hide Totals')),
                    !underSettings &&
                        React.createElement(MenuItem, { component: "a", "data-no-link": "true", onClick: handleSettingsClick }, t('Settings')),
                    underSettings &&
                        React.createElement(MenuItem, { component: "a", "data-no-link": "true", onClick: handleBackToAccountClick }, t('Back to account')))))));
};
var mapStateToProps = function (state) { return (__assign({}, state)); };
var mapDispatchToProps = function (dispatch) {
    return bindActionCreators({ changeSettings: changeSettings }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
