var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import HistoryIcon from '@material-ui/icons/History';
import { currencyFormat } from './utils';
var useStyles = makeStyles(function (theme) {
    var _a, _b, _c;
    return createStyles({
        paper: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        panel: (_a = {
                display: 'flex',
                alignItems: 'center'
            },
            _a[theme.breakpoints.down('xs')] = {
                flexDirection: 'row',
            },
            _a[theme.breakpoints.up('sm')] = {
                flexDirection: 'column',
            },
            _a),
        title: (_b = {
                fontWeight: 'lighter'
            },
            _b[theme.breakpoints.down('xs')] = {
                paddingLeft: theme.spacing(2),
            },
            _b),
        amount: (_c = {
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'flex-end',
                fontWeight: 'lighter'
            },
            _c[theme.breakpoints.down('xs')] = {
                flexGrow: 1,
                textAlign: 'right',
                paddingRight: theme.spacing(2),
            },
            _c),
        snapshotIcon: {
            marginLeft: theme.spacing(0.5),
        }
    });
});
var Summary = function (props) {
    var classes = useStyles();
    var _a = props.settings, preferredCurrency = _a.preferredCurrency, locale = _a.locale, maskedSummary = _a.maskedSummary;
    var rate = (props.summaryData.conversionRates || props.accountData.conversionRates || {})[preferredCurrency] || 1.0;
    var t = useTranslation().t;
    var theme = useTheme();
    var isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    var showDebt = props.summaryData.totalDebtUSD || !isSmall;
    var showPendingYield = props.summaryData.totalPendingYieldUSD || !isSmall;
    var gridCols = (12 / (4 - (showDebt ? 0 : 1) - (showPendingYield ? 0 : 1)));
    var snapshotIndicator = props.summaryData.isSnapshot && React.createElement(HistoryIcon, { className: classes.snapshotIcon });
    var getFormattedAmount = function (amount) {
        if (maskedSummary) {
            return '****';
        }
        return currencyFormat(locale, preferredCurrency, amount / rate);
    };
    return (React.createElement(Paper, { className: classes.paper },
        React.createElement(Grid, { container: true, spacing: 2, justify: "center" },
            React.createElement(Grid, { item: true, xs: 12, sm: gridCols, className: classes.panel },
                React.createElement(Typography, { variant: "h5", className: classes.title }, t('Net Worth')),
                React.createElement(Typography, { variant: "h4", className: classes.amount },
                    getFormattedAmount(props.summaryData.totalAmountUSD),
                    snapshotIndicator)),
            React.createElement(Grid, { item: true, xs: 12, sm: gridCols, className: classes.panel },
                React.createElement(Typography, { variant: "h5", className: classes.title }, t('Total Yield')),
                React.createElement(Typography, { variant: "h4", className: classes.amount },
                    getFormattedAmount(props.summaryData.totalYieldUSD),
                    snapshotIndicator)),
            showPendingYield && (React.createElement(Grid, { item: true, xs: 12, sm: gridCols, className: classes.panel },
                React.createElement(Typography, { variant: "h5", className: classes.title }, t('Pending Yield')),
                React.createElement(Typography, { variant: "h4", className: classes.amount },
                    getFormattedAmount(props.summaryData.totalPendingYieldUSD),
                    snapshotIndicator))),
            showDebt && (React.createElement(Grid, { item: true, xs: 12, sm: gridCols, className: classes.panel },
                React.createElement(Typography, { variant: "h5", className: classes.title }, t('Debt')),
                React.createElement(Typography, { variant: "h4", className: classes.amount },
                    getFormattedAmount(props.summaryData.totalDebtUSD),
                    snapshotIndicator))))));
};
var mapStateToProps = function (state) { return (__assign({}, state)); };
export default connect(mapStateToProps)(Summary);
