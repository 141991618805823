var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { AccountPlan } from './sharedTypes';
import { PLAN_COLORS } from './constants';
import Medal from '../icons/Medal';
var useStyles = makeStyles(function (theme) {
    return createStyles({
        container: {
            marginTop: theme.spacing(2)
        },
        header: {
            backgroundColor: theme.palette.type == 'light'
                ? theme.palette.grey[200]
                : theme.palette.grey[700],
        },
        list: {
            margin: 0,
            paddingLeft: theme.spacing(2),
            listStyle: 'circle',
        },
        listItem: {},
        planContainer: {
            position: 'relative',
            width: '100%'
        },
        planIcon: {
            position: 'absolute',
            right: 0,
            width: theme.spacing(4),
            height: theme.spacing(4),
        },
        highlight: {
            fontWeight: 'bold'
        }
    });
});
var tiers = [
    {
        plan: AccountPlan.Free,
        description: [
            { text: 'View all your DeFi investments on BSC and Polygon' },
            { text: 'All BEP20 tokens supported out-of-the-box' },
            { text: 'Ever-growing list of farms, pools and vaults' },
        ],
        buttonText: null,
        buttonVariant: 'outlined',
    },
    {
        plan: AccountPlan.Gold,
        subheader: 'Most popular',
        description: [
            { text: 'All Silver & Free tier features', clazz: 'highlight' },
            { text: 'Add up to five wallets per account' },
            { text: 'Active monitoring', clazz: 'highlight' },
            { text: 'Integration with Binance Exchange' },
            { text: 'Register custom crypto' },
            { text: 'Register custom vaults' },
            { text: 'Time Machine', clazz: 'highlight' },
        ],
        buttonText: 'Upgrade now!',
        buttonVariant: 'contained',
    },
    {
        plan: AccountPlan.Silver,
        description: [
            { text: 'All Free-tier features', clazz: 'highlight' },
            { text: 'Detailed views, including per-token' },
            { text: '46 fiat currency conversions' },
            { text: 'LP Token breakdown' },
            { text: 'Impermanent Loss' },
            { text: 'Telegram bot', clazz: 'highlight' },
            { text: 'Privacy lock' },
        ],
        buttonText: 'Upgrade now!',
        buttonVariant: 'outlined',
    },
];
var Plans = function () {
    var classes = useStyles();
    var t = useTranslation().t;
    var handleClick = function () {
        window.open('https://docs.walletnow.net/pricing', '_blank');
    };
    return (React.createElement(Container, { maxWidth: "md", className: classes.container },
        React.createElement(Grid, { container: true, spacing: 5, alignItems: "flex-end" }, tiers.map(function (tier) { return (
        // Enterprise card is full width at sm breakpoint
        React.createElement(Grid, { item: true, key: tier.plan, xs: 12, sm: tier.plan === AccountPlan.Silver ? 12 : 6, md: 4 },
            React.createElement(Card, null,
                React.createElement(CardHeader, { className: classes.header, title: (React.createElement("div", { className: classes.planContainer },
                        t('PLAN_' + tier.plan),
                        React.createElement(Medal, __assign({ className: classes.planIcon }, PLAN_COLORS[tier.plan])))), subheader: tier.subheader, titleTypographyProps: { align: 'center' }, subheaderTypographyProps: {
                        align: 'center',
                    } }),
                React.createElement(CardContent, null,
                    React.createElement("ul", { className: classes.list }, tier.description.map(function (line) { return (React.createElement("li", { className: classes.listItem + " " + (('clazz' in line) ? classes[line.clazz] : ''), key: line.text }, line.text)); }))),
                React.createElement(CardActions, null, tier.buttonText && (React.createElement(Button, { fullWidth: true, variant: tier.buttonVariant, onClick: handleClick }, tier.buttonText)))))); }))));
};
export default Plans;
