var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { createRef, useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import MaterialTable from "material-table";
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import { changeSettings } from '../store/actions';
import Tokens from './Tokens';
import UpgradeBanner from './UpgradeBanner';
import { currencyFormat, fixExternalUrl, shortenAddress } from './utils';
var useStyles = makeStyles(function (theme) {
    var _a, _b;
    return createStyles({
        root: (_a = {
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                '& div.MuiPaper-root div.MuiToolbar-root div.MuiFormControl-root': {
                    flex: '1 1 0',
                },
                '& div.MuiPaper-root div.MuiToolbar-root div:nth-child(2)': {
                    flex: '1 1 0',
                }
            },
            _a[theme.breakpoints.down('xs')] = {
                '& .MuiTable-root': {
                    fontSize: '0.75rem'
                },
            },
            _a),
        walletName: {
            marginTop: theme.spacing(1),
            textOverflow: 'initial',
            flex: '1 0 0',
        },
        walletTotal: {
            color: theme.palette.text.secondary
        },
        link: (_b = {
                color: theme.palette.text.primary,
                textDecoration: 'underline',
                textDecorationStyle: 'dotted',
                display: 'inline-block',
                cursor: 'pointer'
            },
            _b[theme.breakpoints.down('xs')] = {
                fontSize: '0.75rem'
            },
            _b),
    });
});
;
;
var Wallet = function (props) {
    var classes = useStyles();
    var theme = useTheme();
    var _a = useState(''), localSearch = _a[0], setLocalSearch = _a[1];
    var _b = props.settings, locale = _b.locale, walletHiddenColumns = _b.walletHiddenColumns;
    var currency = props.settings.preferredCurrency || 'USD';
    var rate = (props.conversionRates || props.accountData.conversionRates || {})[currency] || 1.0;
    var t = useTranslation().t;
    var tableRef = createRef();
    useEffect(function () {
        var table = tableRef.current;
        if (table) {
            table.dataManager.changeSearchText(props.searchText);
            // localSearch state is only used to force a state change on the table, otherwise
            // the table won't re-render
            setLocalSearch(props.searchText);
        }
    }, [props.searchText]);
    var currencySetting = {
        locale: locale || 'en-US',
        currencyCode: currency
    };
    var handler = {
        get: function (_target, value) {
            if (isNaN(value) || isNaN(parseFloat(value))) {
                return 0;
            }
            return value / rate;
        }
    };
    var lookup = new Proxy({}, handler);
    var customFilterAndSearch = function (filter, rowData) {
        var trimmedSearchText = (props.searchText || '').trim().toLowerCase();
        var value = rowData.protocol || '';
        if (value.toString().toLowerCase().includes(trimmedSearchText)) {
            return true;
        }
        // search the string in the tokens
        return (rowData.tokens || []).some(function (token) {
            return Object.keys(token).some(function (prop) {
                return token[prop].toString().toLowerCase().includes(trimmedSearchText);
            });
        });
    };
    var handleChangeColumnHidden = function (column, hidden) {
        var _a;
        props.changeSettings({
            walletHiddenColumns: __assign(__assign({}, walletHiddenColumns), (_a = {}, _a[column.field] = hidden, _a))
        });
    };
    var searchFieldStyle = {
        minWidth: theme.spacing(6)
    };
    var renderProtocol = function (row) {
        if (row.link) {
            return (React.createElement(Link, { color: "inherit", component: "a", target: "_blank", rel: "noopener", href: fixExternalUrl(row.link), className: classes.link }, row.protocol));
        }
        return React.createElement("div", null, row.protocol);
    };
    var totalAmount = 0;
    (props.data.investments || []).map(function (investment) {
        totalAmount += investment.totalAmountUSD;
    });
    return (React.createElement("div", { className: classes.root },
        React.createElement(MaterialTable, { tableRef: tableRef, title: (React.createElement(Box, null,
                React.createElement(Typography, { variant: "h6", className: classes.walletName }, props.data.alias || shortenAddress(props.data.address)),
                React.createElement(Typography, { variant: "subtitle1", className: classes.walletTotal }, currencyFormat(locale, currency, totalAmount / rate)))), columns: [
                { title: t('Protocol'), field: 'protocol', customFilterAndSearch: customFilterAndSearch, render: renderProtocol, hidden: walletHiddenColumns.protocol, defaultSort: 'asc' },
                { title: t('Name'), field: 'name', hidden: walletHiddenColumns.name },
                { title: t('Amount'), field: 'totalAmountUSD', type: 'currency', currencySetting: currencySetting, lookup: lookup, hidden: walletHiddenColumns.totalAmountUSD },
                { title: t('Yield'), field: 'totalYieldUSD', type: 'currency', currencySetting: currencySetting, lookup: lookup, hidden: walletHiddenColumns.totalYieldUSD },
                { title: t('Pending'), field: 'pendingYieldUSD', type: 'currency', currencySetting: currencySetting, lookup: lookup, hidden: walletHiddenColumns.pendingYieldUSD },
                { title: t('Debt'), field: 'totalDebtUSD', type: 'currency', currencySetting: currencySetting, lookup: lookup, hidden: walletHiddenColumns.totalDebtUSD },
                { title: t('Fee Spent'), field: 'totalFeeSpentUSD', type: 'currency', currencySetting: currencySetting, lookup: lookup, hidden: walletHiddenColumns.totalFeeSpentUSD },
                { title: t('Net Profit'), field: 'totalNetProfitUSD', type: 'currency', currencySetting: currencySetting, lookup: lookup, hidden: walletHiddenColumns.totalNetProfitUSD },
            ], options: {
                searchFieldStyle: searchFieldStyle,
                padding: 'dense',
                paging: false,
                tableLayout: 'auto',
                columnsButton: true,
                draggable: false,
                searchText: localSearch,
                search: false,
            }, icons: {
                ViewColumn: React.forwardRef(function (props, ref) { return (React.createElement(SettingsOutlinedIcon, __assign({}, props, { ref: ref }), "view_column")); })
            }, data: props.data.investments, detailPanel: function (rowData) {
                if (!rowData.tokens.length && !(props.accountData.membershipInfo || {}).activeMembershipPlan) {
                    return (React.createElement(UpgradeBanner, null));
                }
                return (React.createElement(Tokens, { investment: rowData, investmentTokens: rowData.tokens, conversionRates: props.conversionRates, ilData: rowData.ilData }));
            }, onRowClick: function (event, rowData, togglePanel) { return togglePanel(); }, onChangeColumnHidden: handleChangeColumnHidden })));
};
var mapStateToProps = function (state) { return (__assign({}, state)); };
var mapDispatchToProps = function (dispatch) {
    return bindActionCreators({ changeSettings: changeSettings }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Wallet));
