import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './i18n/config';
import App from './app/App';
import { ConnectionProvider } from './app/ConnectionProvider';
import initRedux from './store/initRedux';
var store = initRedux({});
ReactDOM.render(React.createElement(Provider, { store: store },
    React.createElement(ConnectionProvider, null,
        React.createElement(App, null))), document.querySelector('#root'));
