var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { createContext, useCallback, useEffect, useState } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { providers } from 'ethers';
import Web3Modal, { connectors } from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';
import MathWalletIcon from '../icons/MathWallet';
import SafePalWalletIcon from '../icons/SafePalWallet';
import TrustWalletIcon from '../icons/TrustWallet';
import { CHAIN_ID } from './constants';
var svgData = function (icon) {
    var svgString = encodeURIComponent(renderToStaticMarkup(icon));
    return "data:image/svg+xml," + svgString;
};
var providerOptions = {
    walletconnect: {
        package: WalletConnectProvider,
        options: {
            chainId: CHAIN_ID,
            rpc: {
                1: 'https://bsc-dataseed.binance.org/',
                56: 'https://bsc-dataseed.binance.org/',
                97: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
            },
        },
    },
    /*
    // **** needs more test
    'custom-binance': {
      display: {
        name: 'Binance',
        description: 'Binance Chain Wallet',
        logo: svgData(<BinanceWalletIcon />),
      },
      package: 'binance',
      connector: async (ProviderPackage, options) => {
        const provider = (window as any).BinanceChain;
        await provider.enable();
        return provider;
      },
    },
    */
    'custom-math': {
        display: {
            name: 'Math',
            description: 'Math Wallet',
            logo: svgData(React.createElement(MathWalletIcon, null)),
        },
        package: 'math',
        connector: connectors.injected,
    },
    'custom-twt': {
        display: {
            name: 'Trust',
            description: 'Trust Wallet',
            logo: svgData(React.createElement(TrustWalletIcon, null)),
        },
        package: 'twt',
        connector: connectors.injected,
    },
    'custom-safepal': {
        display: {
            name: 'SafePal',
            description: 'SafePal App',
            logo: svgData(React.createElement(SafePalWalletIcon, null)),
        },
        package: 'safepal',
        connector: connectors.injected,
    },
};
export var ConnectContext = createContext({
    isConnected: false,
    isInitialized: false,
    provider: null,
    signer: null,
    chainId: null,
    address: null,
});
export var ConnectionProvider = function (_a) {
    var children = _a.children;
    var _b = useState(), web3Modal = _b[0], setWeb3Modal = _b[1];
    var _c = useState(null), provider = _c[0], setProvider = _c[1];
    var _d = useState(null), signer = _d[0], setSigner = _d[1];
    var _e = useState(null), address = _e[0], setAddress = _e[1];
    var _f = useState(null), modalProvider = _f[0], setModalProvider = _f[1];
    var _g = useState(false), isConnected = _g[0], setIsConnected = _g[1];
    var _h = useState(null), chainId = _h[0], setChainId = _h[1];
    var _j = useState(false), isInitialized = _j[0], setIsInitialized = _j[1];
    useEffect(function () {
        setWeb3Modal(new Web3Modal({
            network: "binance",
            cacheProvider: false,
            providerOptions: providerOptions,
        }));
    }, []);
    var connect = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var myModalProvider, ethersProvider, ethersSigner, ethAddress, ex_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!web3Modal) return [3 /*break*/, 6];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, , 6]);
                    return [4 /*yield*/, web3Modal.connect()];
                case 2:
                    myModalProvider = _a.sent();
                    ethersProvider = new providers.Web3Provider(myModalProvider, CHAIN_ID);
                    ethersSigner = ethersProvider.getSigner();
                    return [4 /*yield*/, ethersSigner.getAddress()];
                case 3:
                    ethAddress = _a.sent();
                    setModalProvider(myModalProvider);
                    setSigner(ethersSigner);
                    setAddress(ethAddress);
                    setProvider(ethersProvider);
                    setIsConnected(true);
                    return [3 /*break*/, 6];
                case 4:
                    ex_1 = _a.sent();
                    console.log('web3Modal connect: ', ex_1);
                    return [4 /*yield*/, disconnect()];
                case 5:
                    _a.sent();
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }); }, [web3Modal]);
    var disconnect = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            web3Modal === null || web3Modal === void 0 ? void 0 : web3Modal.clearCachedProvider();
            setAddress(null);
            setProvider(null);
            setIsConnected(false);
            return [2 /*return*/];
        });
    }); }, [web3Modal]);
    useEffect(function () {
        console.log('initialize web3Modal');
        if (web3Modal === null || web3Modal === void 0 ? void 0 : web3Modal.cachedProvider) {
            console.log('got cachedProvider');
            connect()
                .then(function () { return setIsInitialized(true); })
                .catch(function (ex) {
                console.log('connect error: ', ex);
            });
        }
        else {
            setIsInitialized(true);
        }
    }, [web3Modal, connect]);
    // event tracking
    useEffect(function () {
        if (!(modalProvider === null || modalProvider === void 0 ? void 0 : modalProvider.on)) {
            return;
        }
        modalProvider.on('accountsChanged', function (accounts) {
            console.log('accountsChanged %O', accounts);
            setAddress(accounts[0]);
        });
        modalProvider.on('chainChanged', function (updatedChainId) {
            console.log('chainChanged %s', updatedChainId);
            setChainId(chainId);
        });
        modalProvider.on('networkChanged', function (networkId) { return __awaiter(void 0, void 0, void 0, function () {
            var network;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log('networkChanged %s', networkId);
                        return [4 /*yield*/, provider.getNetwork()];
                    case 1:
                        network = _a.sent();
                        setChainId(network.chainId);
                        return [2 /*return*/];
                }
            });
        }); });
        modalProvider.on('disconnect', function () {
            console.log('disconnect');
        });
        modalProvider.on('close', function () {
            console.log('close');
        });
        return function () {
            if (!(modalProvider === null || modalProvider === void 0 ? void 0 : modalProvider.removeAllListeners)) {
                return;
            }
            modalProvider.removeAllListeners();
        };
    }, [provider, modalProvider]);
    return (React.createElement(ConnectContext.Provider, { value: {
            connect: connect,
            disconnect: disconnect,
            address: address,
            signer: signer,
            chainId: chainId,
            isConnected: isConnected,
            isInitialized: isInitialized,
            provider: provider,
        } }, children));
};
