var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { AccountPlan } from './sharedTypes';
import { currencyFormat, formatBalance } from './utils';
import Medal from '../icons/Medal';
import { PLAN_COLORS } from './constants';
var useStyles = makeStyles(function (theme) {
    return createStyles({
        infoLink: {
            display: 'flex',
            marginRight: theme.spacing(3)
        },
        paper: {
            overflowY: 'auto',
            outline: 0,
            padding: theme.spacing(2),
        },
        popoverGrid: {
            padding: theme.spacing(1),
        },
        openIcon: {
            marginLeft: theme.spacing(1),
        },
        table: {
            marginBottom: theme.spacing(1)
        },
        bottomInfo: {
            paddingBottom: theme.spacing(1)
        },
        currentPlan: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            fontWeight: 'bold'
        },
        suggestedPlan: {
            display: 'flex',
            alignItems: 'center',
        },
    });
});
;
var PlanPopperContent = function (props) {
    var classes = useStyles();
    var t = useTranslation().t;
    var locale = props.settings.locale;
    var WNOW = (props.membershipInfo || {}).WNOW;
    var currency = props.settings.preferredCurrency || 'USD';
    var rate = (props.accountData.conversionRates || {})[currency] || 1.0;
    var activeMembershipPlan = (props.membershipInfo || {}).activeMembershipPlan || 0;
    if (!WNOW) {
        return null;
    }
    var calcSuggested = function (value) {
        return value + (value / 10.0);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Paper, { elevation: 8, className: classes.paper },
            React.createElement(Table, { className: classes.table, size: "small" },
                React.createElement(TableBody, null,
                    React.createElement(TableRow, null,
                        React.createElement(TableCell, null,
                            React.createElement(Typography, { variant: "overline" }, t('Current Plan'))),
                        React.createElement(TableCell, { align: "right" },
                            React.createElement(Typography, { className: classes.currentPlan, variant: "overline" },
                                React.createElement("span", null, t('PLAN_' + activeMembershipPlan)),
                                " ",
                                React.createElement(Medal, __assign({ className: classes.planIcon }, PLAN_COLORS[activeMembershipPlan]))))),
                    React.createElement(TableRow, null,
                        React.createElement(TableCell, null,
                            React.createElement(Typography, { variant: "overline" }, t('Total $WNOW tokens'))),
                        React.createElement(TableCell, { align: "right" },
                            React.createElement(Typography, { variant: "overline" }, formatBalance(locale, parseFloat(WNOW.totalAmount))))),
                    React.createElement(TableRow, null,
                        React.createElement(TableCell, null,
                            React.createElement(Typography, { variant: "overline" }, t('Liquidity Bonus'))),
                        React.createElement(TableCell, { align: "right" },
                            React.createElement(Typography, { variant: "overline" }, formatBalance(locale, parseFloat(WNOW.liquidityBonusAmount))))),
                    React.createElement(TableRow, null,
                        React.createElement(TableCell, null,
                            React.createElement(Typography, { variant: "overline" }, t('Your Portfolio'))),
                        React.createElement(TableCell, { align: "right" },
                            React.createElement(Typography, { variant: "overline" }, currencyFormat(locale, currency, props.membershipInfo.portfolioAmountUSD / rate)))),
                    React.createElement(TableRow, null,
                        React.createElement(TableCell, null,
                            React.createElement(Typography, { className: classes.suggestedPlan, variant: "overline" },
                                React.createElement("span", null, t('Max. monitorable in Silver')),
                                React.createElement(Medal, __assign({ className: classes.planIcon }, PLAN_COLORS[AccountPlan.Silver])))),
                        React.createElement(TableCell, { align: "right" },
                            React.createElement(Typography, { variant: "overline" }, currencyFormat(locale, currency, props.membershipInfo.maxSilverAmountUSD / rate)))),
                    (WNOW.missingTokensToSilver > 0) &&
                        React.createElement(TableRow, null,
                            React.createElement(TableCell, null,
                                React.createElement(Typography, { className: classes.suggestedPlan, variant: "overline" },
                                    React.createElement("span", null, t('Tokens needed for Silver')),
                                    React.createElement(Medal, __assign({ className: classes.planIcon }, PLAN_COLORS[AccountPlan.Silver])))),
                            React.createElement(TableCell, { align: "right" },
                                React.createElement(Typography, { variant: "overline" },
                                    formatBalance(locale, calcSuggested(WNOW.missingTokensToSilver)),
                                    "*"))),
                    React.createElement(TableRow, null,
                        React.createElement(TableCell, null,
                            React.createElement(Typography, { className: classes.suggestedPlan, variant: "overline" },
                                React.createElement("span", null, t('Max. monitorable in Gold ')),
                                React.createElement(Medal, __assign({ className: classes.planIcon }, PLAN_COLORS[AccountPlan.Gold])))),
                        React.createElement(TableCell, { align: "right" },
                            React.createElement(Typography, { variant: "overline" }, currencyFormat(locale, currency, props.membershipInfo.maxGoldAmountUSD / rate)))),
                    (WNOW.missingTokensToGold > 0) &&
                        React.createElement(TableRow, null,
                            React.createElement(TableCell, null,
                                React.createElement(Typography, { className: classes.suggestedPlan, variant: "overline" },
                                    React.createElement("span", null, t('Tokens needed for Gold')),
                                    React.createElement(Medal, __assign({ className: classes.planIcon }, PLAN_COLORS[AccountPlan.Gold])))),
                            React.createElement(TableCell, { align: "right" },
                                React.createElement(Typography, { variant: "overline" },
                                    formatBalance(locale, calcSuggested(WNOW.missingTokensToGold)),
                                    "*"))))),
            (WNOW.missingTokensToSilver > 0 || WNOW.missingTokensToGold > 0) &&
                React.createElement("div", { className: classes.bottomInfo },
                    React.createElement(Typography, { variant: "caption" }, t('* Includes a 10% margin for price fluctuation'))),
            React.createElement("div", { className: classes.bottomInfo },
                React.createElement(Link, { className: classes.infoLink, target: "_blank", rel: "noopener", href: 'https://docs.walletnow.net/pricing' },
                    t('Learn more about our membership plans'),
                    React.createElement(OpenInNewIcon, { className: classes.openIcon, fontSize: "small" }))),
            React.createElement("div", null,
                React.createElement(Link, { className: classes.infoLink, target: "_blank", rel: "noopener", href: 'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x56aa0237244c67b9a854b4efe8479cca0b105289' },
                    t('BUY $WNOW TOKENS on PancakeSwap!'),
                    " ",
                    React.createElement(OpenInNewIcon, { className: classes.openIcon, fontSize: "small" }))))));
};
var mapStateToProps = function (state) { return (__assign({}, state)); };
export default connect(mapStateToProps)(PlanPopperContent);
