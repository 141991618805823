var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useContext, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Web3 from 'web3';
import BN from 'bn.js';
import { useSnackbar } from 'notistack';
import { fromWei, toWei } from 'web3-utils';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import OfflineBoltOutlinedIcon from '@material-ui/icons/OfflineBoltOutlined';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import BNBIcon from '../icons/BNB';
import { ConnectContext } from './ConnectionProvider';
import privateSaleAbi from './WalletNowPrivateSale.abi.json';
import { PROVIDER, PRIVATE_SALE_CONTRACT_ADDRESS } from './constants';
import { formatBalance } from './utils';
var useStyles = makeStyles(function (theme) {
    return createStyles({
        description: {
            marginTop: theme.spacing(2),
            fontWeight: 'lighter',
        },
        available: {
            fontWeight: 'lighter',
        },
        leftIcon: {
            marginRight: theme.spacing(1),
        },
        divider: {
            width: '100%'
        },
        icon: {
            marginRight: theme.spacing(2),
        },
        input: {
            '& input:-webkit-autofill': {
                '-webkit-box-shadow': "0 0 0 30px " + theme.palette.background.default + " inset",
                '-webkit-text-fill-color': theme.palette.text.primary,
                '&:hover, &:focus, &:active': {
                    '-webkit-box-shadow': "0 0 0 30px " + theme.palette.background.default + " inset",
                    '-webkit-text-fill-color': theme.palette.text.primary,
                },
            },
        }
    });
});
var ZERO = new BN(0);
var PrivateSale = function (props) {
    var classes = useStyles();
    var enqueueSnackbar = useSnackbar().enqueueSnackbar;
    var connection = useContext(ConnectContext);
    var locale = props.settings.locale;
    var _a = useState(''), value = _a[0], setValue = _a[1];
    var _b = useState(ZERO), availableTokens = _b[0], setAvailableTokens = _b[1];
    var _c = useState(ZERO), price = _c[0], setPrice = _c[1];
    var t = useTranslation().t;
    useEffect(function () {
        var web3 = new Web3(PROVIDER);
        var contract = new web3.eth.Contract(privateSaleAbi, PRIVATE_SALE_CONTRACT_ADDRESS);
        updateBalanceAndPrice(contract);
    }, []);
    var updateBalanceAndPrice = function (contract) {
        contract.methods.tokenBalance().call().then(function (tokenBalance) {
            setAvailableTokens(new BN(tokenBalance));
        });
        contract.methods.price().call().then(function (price) {
            setPrice(new BN(price));
        });
    };
    var handleChangeValue = function (event) {
        setValue(event.target.value);
    };
    var handleLogout = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!connection.disconnect) return [3 /*break*/, 2];
                    return [4 /*yield*/, connection.disconnect()];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var handleLogin = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!connection.connect) return [3 /*break*/, 2];
                    return [4 /*yield*/, connection.connect()];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var getValueInWei = function (value) {
        var res;
        try {
            res = new BN(toWei(value));
        }
        catch (error) {
            res = ZERO;
        }
        return res;
    };
    var getValueInTokens = function (value, price) {
        return price.isZero() ? ZERO : value.mul(toWei(new BN(1))).div(price);
    };
    var handleBuy = function () { return __awaiter(void 0, void 0, void 0, function () {
        var valueBN, valueInTokens, network, provider, web3, contract_1;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    valueBN = getValueInWei(value);
                    valueInTokens = getValueInTokens(valueBN, price);
                    if (!((_a = connection === null || connection === void 0 ? void 0 : connection.provider) === null || _a === void 0 ? void 0 : _a.provider)) return [3 /*break*/, 3];
                    return [4 /*yield*/, connection.provider.detectNetwork()];
                case 1:
                    network = _c.sent();
                    provider = (_b = connection === null || connection === void 0 ? void 0 : connection.provider) === null || _b === void 0 ? void 0 : _b.provider;
                    web3 = new Web3(provider);
                    contract_1 = new web3.eth.Contract(privateSaleAbi, PRIVATE_SALE_CONTRACT_ADDRESS);
                    return [4 /*yield*/, contract_1
                            .methods
                            .purchaseTokens(valueInTokens.toString())
                            .send({
                            value: valueBN.toString(),
                            from: connection.address
                        })
                            .on('transactionHash', function (transactionHash) {
                            updateBalanceAndPrice(contract_1);
                            enqueueSnackbar(t('Transaction sent. Please wait...'), { variant: 'success' });
                            setValue('');
                            console.log(transactionHash);
                        })
                            .on('receipt', function (receipt) {
                            enqueueSnackbar(t('Transaction confirmed!'), { variant: 'success' });
                            console.log(receipt);
                        })
                            .on('error', function (error) {
                            enqueueSnackbar(error.message, { variant: 'error' });
                            console.log(error);
                        })];
                case 2:
                    _c.sent();
                    return [3 /*break*/, 4];
                case 3:
                    enqueueSnackbar(t('Wallet not connected'), { variant: 'error' });
                    _c.label = 4;
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var valueBN = getValueInWei(value);
    var valueInTokens = getValueInTokens(valueBN, price);
    var notEnoughTokens = valueInTokens.gt(availableTokens);
    var valueError = valueBN.isZero() || valueInTokens.lte(ZERO) || notEnoughTokens;
    var enable = connection.isConnected && !availableTokens.isZero() && !price.isZero();
    var helperText = (notEnoughTokens) ? t('Not enough tokens available') : undefined;
    return (React.createElement(Container, { maxWidth: "sm" },
        React.createElement(Typography, { className: classes.description, variant: "h6", align: "center", color: "textSecondary", gutterBottom: false }, "For more information about the private sale"),
        React.createElement(Typography, { variant: "h6", align: "center", color: "textSecondary" },
            React.createElement(Link, { href: "https://walletnow.medium.com/walletnow-private-sale-is-coming-bf447a8ba759", rel: "noopener", target: "_blank" }, "read this article")),
        React.createElement(Grid, { container: true, spacing: 2 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Box, { my: 2, textAlign: "center" },
                    React.createElement(Button, { variant: "contained", color: "primary", size: "large", className: classes.button, onClick: (connection.isConnected) ? handleLogout : handleLogin },
                        (connection.isConnected) ?
                            React.createElement(ExitToAppIcon, { className: classes.leftIcon }) :
                            React.createElement(OfflineBoltOutlinedIcon, { className: classes.leftIcon }),
                        (connection.isConnected) ?
                            t('Disconnect') :
                            t('Connect')))),
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Paper, null,
                    React.createElement(Grid, { container: true },
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(Box, { p: 2 },
                                React.createElement(Typography, { className: classes.description, variant: "h5", color: "textSecondary", gutterBottom: false },
                                    t('Buy'),
                                    "\u00A0",
                                    React.createElement("b", null, "$WNOW")),
                                React.createElement(Typography, { className: classes.available, variant: "h6", color: "textSecondary" },
                                    t('Private sale available tokens'),
                                    ":\u00A0",
                                    React.createElement("b", null, availableTokens.isZero() ? '-' : formatBalance(locale, parseFloat(fromWei(availableTokens).toString())))),
                                React.createElement(Typography, { className: classes.description, variant: "caption", color: "textSecondary", gutterBottom: false },
                                    t('You will pay'),
                                    " ",
                                    React.createElement("b", null, price.isZero() ? '-' : fromWei(price)),
                                    " ",
                                    t('BNBs for each $WNOW token')))),
                        React.createElement(Divider, { className: classes.divider }),
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(Box, { p: 2 },
                                React.createElement(TextField, { className: classes.input, id: "value", value: value, disabled: !enable, onChange: handleChangeValue, variant: "outlined", fullWidth: true, type: "number", placeholder: "0.0", error: notEnoughTokens, helperText: helperText, InputProps: {
                                        endAdornment: (React.createElement(InputAdornment, { position: "end" },
                                            React.createElement(BNBIcon, { className: classes.icon }),
                                            "BNB")),
                                    } }),
                                !valueError && (React.createElement(Typography, { className: classes.description, variant: "h6", color: "textSecondary", gutterBottom: false },
                                    t('You will be buying'),
                                    ": ",
                                    React.createElement("b", null,
                                        formatBalance(locale, parseFloat(fromWei(valueInTokens))),
                                        " ",
                                        valueInTokens.eq(new BN(1)) ? t('token') : t('tokens')))))),
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(Box, { p: 2, textAlign: "center" },
                                React.createElement(Button, { variant: "contained", color: "primary", size: "large", className: classes.button, disabled: !enable || valueError, onClick: handleBuy },
                                    React.createElement(ShoppingCartOutlinedIcon, { className: classes.leftIcon }),
                                    t('Buy Tokens'))))))))));
};
var mapStateToProps = function (state) { return (__assign({}, state)); };
export default connect(mapStateToProps)(PrivateSale);
