import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
var useStyles = makeStyles(function (theme) {
    return createStyles({
        box: {
            flexGrow: 1,
            flexDirection: 'row',
        },
        description: {
            color: theme.palette.text.secondary
        },
    });
});
;
var DescriptionBox = function (props) {
    var classes = useStyles();
    var boxClasses = (props.className) ? props.className + " " + classes.box : classes.box;
    return (React.createElement(Box, { className: boxClasses },
        React.createElement(Typography, { variant: "subtitle1" }, props.text),
        props.description && (React.createElement(Typography, { className: classes.description, variant: "body2" }, props.description))));
};
export default DescriptionBox;
