var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
var AutoFarm = function (props) {
    return (React.createElement(SvgIcon, __assign({ width: 60, height: 59, viewBox: "0 0 60 59", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props, { key: "auto-farm" }),
        React.createElement("path", { d: "M59.006 29.5c0 16.292-13.21 29.5-29.503 29.5C13.209 59 0 45.792 0 29.5S13.209 0 29.503 0s29.503 13.208 29.503 29.5z", fill: "#4958C5" }),
        React.createElement("path", { d: "M41.525 17.273h-8.062v-2.272c0-2.492 2.587-4.354 4.323-4.511 1.514-.137 2.335-.327 5.332.652V7.05c-2.05-.507-4.512-.436-6.563-.253-2.24.126-7.32 2.493-7.32 7.982v2.493h-2.966v3.723h2.966v14.04s-1.925-1.83-5.87-1.83c-5.49 0-10.35 4.228-10.35 10.286s4.513 10.405 10.477 10.405c5.963 0 10.128-5.105 10.255-10.405.126-5.3 0-11.327 0-11.327V20.996h7.778v-3.723z", fill: "#fff" }),
        React.createElement("circle", { cx: 23.531, cy: 43.33, fill: "#4958C5", r: 6.058 })));
};
export default AutoFarm;
