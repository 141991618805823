import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { debounce } from 'debounce';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import Wallet from './Wallet';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
var useStyles = makeStyles(function (theme) {
    return createStyles({
        paper: {
            marginBottom: theme.spacing(1),
            padding: theme.spacing(3),
        },
    });
});
;
var Wallets = function (props) {
    var t = useTranslation().t;
    var classes = useStyles();
    var _a = useState(''), searchText = _a[0], setSearchText = _a[1];
    var _b = useState(''), debouncedSearchText = _b[0], setDebouncedSearchText = _b[1];
    var wallets = props.wallets || [];
    var debouncedSearch = useCallback(debounce(function (text) { return setDebouncedSearchText(text); }, 200), []);
    useEffect(function () {
        debouncedSearch(searchText);
    }, [searchText]);
    var handleChangeSearchText = function (event) {
        setSearchText(event.target.value);
    };
    var handleClearSearch = function () {
        setSearchText('');
    };
    return (React.createElement(React.Fragment, null,
        (wallets.length > 0) && (React.createElement(Box, { px: 1, pb: 1 },
            React.createElement(Paper, { className: classes.paper },
                React.createElement(Grid, { container: true, spacing: 2 },
                    React.createElement(Grid, { item: true, xs: 12, sm: 6 },
                        React.createElement(TextField, { fullWidth: true, type: "text", label: t('Search'), value: searchText, onChange: handleChangeSearchText, InputProps: {
                                startAdornment: (React.createElement(InputAdornment, { position: "start" },
                                    React.createElement(SearchIcon, null))),
                                endAdornment: (React.createElement(InputAdornment, { position: "end" },
                                    React.createElement(IconButton, { edge: "end", "aria-label": t('Clear'), onClick: handleClearSearch },
                                        React.createElement(ClearIcon, null)))),
                            } })),
                    React.createElement(Grid, { item: true, xs: 12, sm: 6 }))))),
        wallets.map(function (wallet) {
            return React.createElement(Box, { key: wallet.address, px: 1, pt: 0, pb: 2 },
                React.createElement(Wallet, { data: wallet, conversionRates: props.conversionRates, searchText: debouncedSearchText }));
        })));
};
export default Wallets;
