var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
var HyperJump = function (props) {
    return (React.createElement(SvgIcon, __assign({ xmlns: "http://www.w3.org/2000/svg", width: 400, height: 400, viewBox: "0 0 400 400" }, props, { key: "hyper-jump" }),
        React.createElement("circle", { cx: 200, cy: 200, r: 200, fill: "#2a9cc1" }),
        React.createElement("path", { d: "M256.667 8.333l.666.334-.666-.334z", fill: "#24626f" }),
        React.createElement("path", { d: "M174 10.428c-25.622 3.67-49.695 11.976-72 25.175C12.6 88.506-17.755 210.114 36.204 299 60.569 339.133 98.047 367.139 142 382.62c26.728 9.415 59.151 9.719 87 6.091 23.684-3.086 46.291-12.064 67-23.569 20.481-11.378 38.986-27.73 53.424-46.143C414.232 236.35 400.034 114.968 318 49.371c-21.768-17.406-47.27-28.222-74-35.102-21.329-5.49-48.139-6.971-70-3.84z" }),
        React.createElement("path", { d: "M258.667 18.333l.666.334-.666-.334z", fill: "#174b58" }),
        React.createElement("path", { d: "M128.667 22.333l.666.334-.666-.334z", fill: "#284360" }),
        React.createElement("path", { d: "M144.554 32.29c-22.517 11.02 9.467 11.457 7.17 17.71-3.674 10-15.669 14.93-6.424 25.995 1.77 2.119 3.758 4.047 5.7 6.005h1c29.601-30.225 61.78-46.34 105-40.71 13.439 1.75 25.759 7.999 39 9.71-2.405-7.815-18.156-14.214-25.16-17.299-12.851-5.66-26.464-11.036-40.307-12.836-13.844-1.8-28.055-1.957-41.693-.025-13.1 1.855-18.84 3.16-44.286 11.45z", fill: "#44c3e3" }),
        React.createElement("path", { d: "M182.194 198.274c-8.478-1.589-19.457 4.467-28.155 3.402C129.618 198.686 93.399 181.13 90 154l-4.468-10.243c-1.416-17.77-3.716-17.348.318-35.563 1.269-5.732 4.131-13.237 6.504-18.48C104.503 62.877 143.29 27.976 164.745 27c-5.625-3.258-17.408 1.013-22.745 2.946-13.144 4.76-23.779 10.678-36 17.235-18.1 9.71-31.293 22.927-43.819 38.815-4.75 6.025-9.564 14.103-12.804 21.198-14.052 30.769-7.293 68.043 16.793 91.521 22.651 22.079 53.052 26.473 82.83 18.531 10.194-2.719 27.275-9.19 33.194-18.972z", fill: "#c2272d" }),
        React.createElement("path", { d: "M249.667 27.333l.666.334-.666-.334z", fill: "#255061" }),
        React.createElement("path", { d: "M252.667 28.333l.666.334-.666-.334z", fill: "#306376" }),
        React.createElement("path", { d: "M258.667 30.333l.666.334-.666-.334z", fill: "#2a6577" }),
        React.createElement("path", { d: "M264.667 32.333l.666.334-.666-.334z", fill: "#134955" }),
        React.createElement("path", { d: "M294.667 34.333l.666.334-.666-.334z", fill: "#15455b" }),
        React.createElement("path", { d: "M134 42l5-3-5 3z", fill: "#7c4757" }),
        React.createElement("path", { d: "M136 53l13-11 1 5h1l-2-8c-5.728 2.679-10.386 8.294-13 14z", fill: "#562f34" }),
        React.createElement("path", { d: "M308 93c-1.658 18.993-8.33 40.725-16.698 57.871-2.337 4.788-8.582 6.712-9.985 12.168-8.183 31.822 13.82 65.019 35.683 84.961 49.227-32.557 55.605-114.699 22.621-161C315.218 52.744 267.434 35.224 226 40.184c-21.774 2.607-42.698 11.4-59.285 25.715-3.811 3.288-13.534 10.541-13.509 16.101.025 5.532 10.99 13.42 14.794 16.83 16.291 14.607 46.83 25.323 68.96 20.83 5.607-1.139 9.796-6.921 15.04-9.351 16.324-7.561 37.822-16.922 56-17.309z", fill: "#c7f2f9" }),
        React.createElement("path", { d: "M140 51l9-9-9 9z", fill: "#dd9273" }),
        React.createElement("path", { d: "M139 65l-1-7 5 1 7-14c-7.121 1.944-20.58 13.283-11 20z", fill: "#802022" }),
        React.createElement("path", { d: "M148 48v3h1l1-3h-2z", fill: "#4d3046" }),
        React.createElement("path", { d: "M296 49c1.701 5.44 7.864 7.52 13 9l-13-9z", fill: "#6fbac0" }),
        React.createElement("path", { d: "M112 60l12-10-12 10z", fill: "#8b92a5" }),
        React.createElement("path", { d: "M139 50l-3 5 3-5z", fill: "#8e3c48" }),
        React.createElement("path", { d: "M147 51l-4 8-5-1 1 7-3-1 5 6 6-19z", fill: "#642536" }),
        React.createElement("path", { d: "M138.514 46.773l8.171-8.77-10.225 1.407-11.605 7.936C111.62 57.45 99.65 72.995 92.015 90.072 84.378 107.149 81.078 125.76 85.186 142c2.97 11.741 6.554 24 15.354 33.985C113.288 194.343 140.61 202 162 202c8.349 0 18.849-.622 25-7-29.278-.003-52.821-31.113-54.91-59-.65-8.676 7.142-22.829 4.404-30-6.518-17.067-22.031-9.906-22.16 1.044-9.224.874-4.792 15.203-4.585-2.503 1.583-12.906 1.512-7.144 3.87-14.821 1.78-5.798 6.329-8.796 7.208-11.72 1.607-5.348 3.29-10.41 7.173-17 3.088-3.19 5.305-5.896 6.955-8.23 1.65-2.334 2.735-4.296 3.56-5.997z", fill: "#44c3e3" }),
        React.createElement("path", { d: "M135 53c-14.591 16.875-28.031 39.488-25 62l19-18v-6h-1v4c-6.578 1.951-11.711 9.8-14 16h-3c-2.37-14.223 6.186-25.319 13-37 2.047-3.51 4.879-10.53 7-10v-4l5 4-1-11z", fill: "#572c36" }),
        React.createElement("path", { d: "M146 56l-1 3 1-3z", fill: "#6c829a" }),
        React.createElement("path", { d: "M129 63l-8.173 15L110 108v1l1 2h3c3.26-6.073 7.662-12.924 14-16 2.012-9.026 4.725-23.123 1-32z", fill: "#831e24" }),
        React.createElement("path", { d: "M323 69l1 1-1-1z", fill: "#51696b" }),
        React.createElement("path", { d: "M140 70l4 5-4-5z", fill: "#002827" }),
        React.createElement("path", { d: "M324 70l1 1-1-1z", fill: "#657a7f" }),
        React.createElement("path", { d: "M325 71l1 1-1-1z", fill: "#6c7d84" }),
        React.createElement("path", { d: "M342 72l2 3-2-3z", fill: "#083147" }),
        React.createElement("path", { d: "M328 74l1 1-1-1z", fill: "#6b7a81" }),
        React.createElement("path", { d: "M329 75l1 1-1-1z", fill: "#6f8288" }),
        React.createElement("path", { d: "M330 76l1 1-1-1z", fill: "#576c6f" }),
        React.createElement("path", { d: "M119 80l-9 28h2l7-28z", fill: "#ef8f65" }),
        React.createElement("path", { d: "M152 83l1 1-1-1z", fill: "#5f7d87" }),
        React.createElement("path", { d: "M336 83l1 1-1-1z", fill: "#637f82" }),
        React.createElement("path", { d: "M153 84l1 1-1-1z", fill: "#789198" }),
        React.createElement("path", { d: "M337 84l1 1-1-1z", fill: "#4a6868" }),
        React.createElement("path", { d: "M154 85l1 1-1-1z", fill: "#7a9296" }),
        React.createElement("path", { d: "M155 86l1 1-1-1z", fill: "#7d9096" }),
        React.createElement("path", { d: "M156 87l1 1-1-1z", fill: "#8798a0" }),
        React.createElement("path", { d: "M117.333 88.667l.334.666-.334-.666z", fill: "#ca5a34" }),
        React.createElement("path", { d: "M157 88l1 1-1-1z", fill: "#809199" }),
        React.createElement("path", { d: "M158 89l1 1-1-1z", fill: "#84929b" }),
        React.createElement("path", { d: "M159 90l1 1-1-1z", fill: "#8a99a0" }),
        React.createElement("path", { d: "M342 90c2.297 12.886 10.855 24.997 14.105 38 6.648 26.6 1.894 48.08-4.105 74 7.705-3.383 8.905-16.395 8.996-24 .262-21.739.851-38.993-5.996-60 5.977 3.476 6.978 9.532 7 16h1l1-2 3 4c-1.892-15.182-14.55-35.178-25-46z", fill: "#53bbd4" }),
        React.createElement("path", { d: "M161 92l1 1-1-1z", fill: "#899b9f" }),
        React.createElement("path", { d: "M53 96l3-3-3 3z", fill: "#5c121f" }),
        React.createElement("path", { d: "M162 93l1 1-1-1z", fill: "#8c9ea0" }),
        React.createElement("path", { d: "M301 93v1h3l-3-1z", fill: "#d1a9aa" }),
        React.createElement("path", { d: "M243 118c5.279 9.682-.533 29.121-1 40 17.146-12.508 32.019-30.441 46.59-45.826 5.83-6.156 14.994-10.568 17.41-19.174-19.616 3.698-49.354 9.467-63 25z", fill: "#c1262a" }),
        React.createElement("path", { d: "M306 93l-18.246 19.174L242 158l3-39h-2c-1.103 13.505-3.421 36.202-10.649 47.83-10.746 17.289-32.32 27.277-42.351 45.17 7.654-1.976 13.643-7.654 21-10.468 24.873-9.513 58.644-22.546 75.15-44.702C297.604 141.456 305.224 111.795 308 93h-2z", fill: "#831e24" }),
        React.createElement("path", { d: "M369 93l2 3-2-3z", fill: "#285d6f" }),
        React.createElement("path", { d: "M163 94l1 1-1-1z", fill: "#86999d" }),
        React.createElement("path", { d: "M164 95l1 1-1-1z", fill: "#7d9096" }),
        React.createElement("path", { d: "M293 95v1h3l-3-1z", fill: "#cb9ca4" }),
        React.createElement("path", { d: "M165 96l1 1-1-1z", fill: "#6e8084" }),
        React.createElement("path", { d: "M307.333 96.667l.334.666-.334-.666z", fill: "#baafb3" }),
        React.createElement("path", { d: "M166 97l1 1-1-1z", fill: "#5d6f73" }),
        React.createElement("path", { d: "M286.667 97.333l.666.334-.666-.334z", fill: "#d0a09c" }),
        React.createElement("path", { d: "M167 98l1 1-1-1z", fill: "#51646a" }),
        React.createElement("path", { d: "M283.667 98.333l.666.334-.666-.334z", fill: "#c87f86" }),
        React.createElement("path", { d: "M248 138h1c10.363-20.444 27.509-29.028 47-39-8.908-2.47-27.749 9.33-36 13.164-3.309 1.537-8.628 2.773-10.566 6.114C246.425 123.464 248 132.223 248 138z", fill: "#fc8857" }),
        React.createElement("path", { d: "M280.667 99.333l.666.334-.666-.334z", fill: "#b58183" }),
        React.createElement("path", { d: "M306.333 99.667l.334.666-.334-.666z", fill: "#af9096" }),
        React.createElement("path", { d: "M277.667 100.333l.666.334-.666-.334z", fill: "#b06f75" }),
        React.createElement("path", { d: "M171 101l1 1-1-1z", fill: "#8ba0a5" }),
        React.createElement("path", { d: "M274.667 101.333l.666.334-.666-.334z", fill: "#ab777b" }),
        React.createElement("path", { d: "M172 102l1 1-1-1z", fill: "#6a7d81" }),
        React.createElement("path", { d: "M305.333 102.667l.334.666-.334-.666z", fill: "#98747e" }),
        React.createElement("path", { d: "M176 105l1 1-1-1z", fill: "#5f797a" }),
        React.createElement("path", { d: "M304.333 106.667l.334.666-.334-.666z", fill: "#a3868b" }),
        React.createElement("path", { d: "M137 108c-8.552 27.165-2.67 58.39 20.831 76.655 6.741 5.239 19.563 14.312 28.851 9.742 5.843-2.875 1.67-21.003-.598-25.397-8.023-15.549-21.04-29.615-32.165-43-5.027-6.048-10.02-14.049-16.919-18z", fill: "#c7f2f9" }),
        React.createElement("path", { d: "M139 109l1 1-1-1z", fill: "#849e9f" }),
        React.createElement("path", { d: "M253 109v1h3l-3-1z", fill: "#b88186" }),
        React.createElement("path", { d: "M140 110l1 1-1-1z", fill: "#708282" }),
        React.createElement("path", { d: "M251.667 110.333l.666.334-.666-.334z", fill: "#b86c76" }),
        React.createElement("path", { d: "M303.333 110.667l.334.666-.334-.666z", fill: "#c199a2" }),
        React.createElement("path", { d: "M141 111l1 1-1-1z", fill: "#596d6c" }),
        React.createElement("path", { d: "M190.667 112.333l.666.334-.666-.334z", fill: "#abb9bc" }),
        React.createElement("path", { d: "M241 119l8-6c-3.962-.538-6.392 2.658-8 6z", fill: "#994952" }),
        React.createElement("path", { d: "M303 112l-14 42c3.279-2.307 3.536-6.396 5.019-10 3.24-7.876 12.456-23.621 8.981-32z", fill: "#e5eeed" }),
        React.createElement("path", { d: "M192.667 113.333l.666.334-.666-.334z", fill: "#7a9296" }),
        React.createElement("path", { d: "M195.667 114.333l.666.334-.666-.334z", fill: "#819498" }),
        React.createElement("path", { d: "M198.667 115.333l.666.334-.666-.334z", fill: "#778c8f" }),
        React.createElement("path", { d: "M201.667 116.333l.666.334-.666-.334z", fill: "#5d7074" }),
        React.createElement("path", { d: "M301.333 116.667l.334.666-.334-.666z", fill: "#a9868d" }),
        React.createElement("path", { d: "M146 117l1 1-1-1z", fill: "#697e7f" }),
        React.createElement("path", { d: "M206.667 117.333l.666.334-.666-.334z", fill: "#8fa2a6" }),
        React.createElement("path", { d: "M210.667 118.333l.666.334-.666-.334z", fill: "#687b7f" }),
        React.createElement("path", { d: "M355 118c4.393 18.978 6.899 33.375 6.039 53-.372 8.494-.064 19.005-4.314 26.737-6.589 11.987-15.467 25.879-24.775 36.259-2.862 3.191-12.743 9.668-12.743 14.043 0 2.691 2.909 5.087 4.798 6.617 8.918 7.225 19.983-3.547 28.419-7.158 2.559-1.094 7.369.25 7.369 3.683 0 5.372-11.755 11.999-15.793 14.405-13.744 8.191-25.191 17.678-40.424 23.379-4.952 1.853-13.366 5.1-18.412 2.007-2.947-1.806-2.52-4.987.069-6.848 4.659-3.349 19.708-9.854 10.283-17.521-5.271-4.288-14.821 2.368-20.516 3.187-12.531 1.802-24.631-1.88-35.961-6.9-11.527-5.108-22.837-19.266-29.112-29.89-2.977-5.041-3.425-11.042-7.927-15-1.131 9.276-5.037 17.234-3.871 26.91 3.895 32.323 31.983 67.429 66.871 70 10.427.768 22.986 1.337 33-1.964 7.643-2.52 13.903-9.05 22-9.946 3.915-7.198 13.874-10.324 19.787-15.433 4.764-4.116 8.839-11.581 12.599-16.737C381 231.589 388.882 175.045 364 132l-1 2h-1c-.005-6.333-.257-13.43-7-16z", fill: "#44c3e3" }),
        React.createElement("path", { d: "M216 119v1h4l-4-1z", fill: "#7a8c8c" }),
        React.createElement("path", { d: "M220 119v1h4l-4-1z", fill: "#a7bcbd" }),
        React.createElement("path", { d: "M238.667 119.333l.666.334-.666-.334z", fill: "#d0a6aa" }),
        React.createElement("path", { d: "M192 208c7.809-3.813 13.68-13.04 19.665-19.242 6.591-6.83 15.514-12.963 20.686-21.044C239.625 156.348 242.419 132.335 243 119c-9.619 2.098-7.922 20.453-11.133 27.985-2.806 6.579-10.183 12.206-14.489 18.015-9.362 12.629-19.805 28.271-25.378 43z", fill: "#c2272b" }),
        React.createElement("path", { d: "M224 120v1l14 1-14-2z", fill: "#2c3f45" }),
        React.createElement("path", { d: "M236 122l-3 6 5-6h-2z", fill: "#df9386" }),
        React.createElement("path", { d: "M151 123l1 1-1-1z", fill: "#6c7f85" }),
        React.createElement("path", { d: "M152 124l1 1-1-1z", fill: "#54696c" }),
        React.createElement("path", { d: "M236 125l-5 4-17 38h-1v-3h-1l-8 22h1c8.615-16.184 26.105-30.096 29.25-48 .725-4.128 2.946-8.958 1.75-13z", fill: "#f29049" }),
        React.createElement("path", { d: "M298 125v3h1l-1-3z", fill: "#b08e8d" }),
        React.createElement("path", { d: "M156 129l1 1-1-1z", fill: "#728687" }),
        React.createElement("path", { d: "M365.333 129.667l.334.666-.334-.666z", fill: "#377a83" }),
        React.createElement("path", { d: "M157 130l1 1-1-1z", fill: "#4d5f61" }),
        React.createElement("path", { d: "M230 130c-12.469 20.022-29.509 44.373-34 68 7.645-6.505 14.232-24.199 16-34h1v3h1l18-37h-2z", fill: "#e59465" }),
        React.createElement("path", { d: "M295.333 134.667l.334.666-.334-.666z", fill: "#bf9ba5" }),
        React.createElement("path", { d: "M367.333 134.667l.334.666-.334-.666z", fill: "#396d83" }),
        React.createElement("path", { d: "M380.333 136.667l.334.666-.334-.666z", fill: "#15505e" }),
        React.createElement("path", { d: "M164 139l1 1-1-1z", fill: "#6f878b" }),
        React.createElement("path", { d: "M165 140l1 1-1-1z", fill: "#5e7376" }),
        React.createElement("path", { d: "M292.333 142.667l.334.666-.334-.666z", fill: "#bba7a8" }),
        React.createElement("path", { d: "M370.333 142.667l.334.666-.334-.666z", fill: "#265b6d" }),
        React.createElement("path", { d: "M168 144l1 1-1-1z", fill: "#789092" }),
        React.createElement("path", { d: "M359 144v27h1l-1-27z", fill: "#94e2f6" }),
        React.createElement("path", { d: "M290.333 147.667l.334.666-.334-.666z", fill: "#bc9fa4" }),
        React.createElement("path", { d: "M171 148l1 1-1-1z", fill: "#7f9193" }),
        React.createElement("path", { d: "M172 149l1 1-1-1z", fill: "#59686b" }),
        React.createElement("path", { d: "M288.333 152.667l.334.666-.334-.666z", fill: "#bbaab2" }),
        React.createElement("path", { d: "M373.333 152.667l.334.666-.334-.666z", fill: "#275e73" }),
        React.createElement("path", { d: "M175 153l1 1-1-1z", fill: "#697e83" }),
        React.createElement("path", { d: "M90 156l6 10-6-10z", fill: "#893946" }),
        React.createElement("path", { d: "M236 166l45-8v-2c-11.411.094-39.024-.769-45 10z", fill: "#530e13" }),
        React.createElement("path", { d: "M374.333 156.667l.334.666-.334-.666z", fill: "#36667d" }),
        React.createElement("path", { d: "M178 157l1 1-1-1z", fill: "#56686a" }),
        React.createElement("path", { d: "M180 160l1 1-1-1z", fill: "#576f73" }),
        React.createElement("path", { d: "M375.333 160.667l.334.666-.334-.666z", fill: "#235a6e" }),
        React.createElement("path", { d: "M280 162v14h1l-1-14z", fill: "#aabec5" }),
        React.createElement("path", { d: "M182 163l1 1-1-1z", fill: "#67797d" }),
        React.createElement("path", { d: "M376 164v4h1l-1-4z", fill: "#3c6d7e" }),
        React.createElement("path", { d: "M184 166l1 1-1-1z", fill: "#5b6a6d" }),
        React.createElement("path", { d: "M208.333 168.667l.334.666-.334-.666z", fill: "#a2623f" }),
        React.createElement("path", { d: "M377 173v3h1l-1-3z", fill: "#4c8191" }),
        React.createElement("path", { d: "M188.333 174.667l.334.666-.334-.666z", fill: "#374b4c" }),
        React.createElement("path", { d: "M188.333 176.667l.334.666-.334-.666z", fill: "#90a8ac" }),
        React.createElement("path", { d: "M280 176v6h1l-1-6z", fill: "#5a6c6e" }),
        React.createElement("path", { d: "M189.333 180.667l.334.666-.334-.666z", fill: "#394b4d" }),
        React.createElement("path", { d: "M109 181l5 4-5-4z", fill: "#829da6" }),
        React.createElement("path", { d: "M378 181c0 22.882-1.033 41.821-9.938 63-4.13 9.822-13.963 18.583-16.062 29h1c15.56-21.898 26-43.924 26-71 0-6.42 1.497-15.049-1-21z", fill: "#7cb5d0" }),
        React.createElement("path", { d: "M189 182v11h1l-1-11z", fill: "#5e7072" }),
        React.createElement("path", { d: "M281 185v3h1l-1-3z", fill: "#90a3a7" }),
        React.createElement("path", { d: "M379 187v5h1l-1-5z", fill: "#184f54" }),
        React.createElement("path", { d: "M198.333 190.667l.334.666-.334-.666z", fill: "#946345" }),
        React.createElement("path", { d: "M282.333 192.667l.334.666-.334-.666z", fill: "#4f5e61" }),
        React.createElement("path", { d: "M379 192v16h1l-1-16z", fill: "#347988" }),
        React.createElement("path", { d: "M283 195v3h1l-1-3z", fill: "#829599" }),
        React.createElement("path", { d: "M195 197l-4 11h1c1.99-3.594 5.355-7.098 3-11z", fill: "#be7b6b" }),
        React.createElement("path", { d: "M284 199v3h1l-1-3z", fill: "#76898f" }),
        React.createElement("path", { d: "M285.333 203.667l.334.666-.334-.666z", fill: "#435559" }),
        React.createElement("path", { d: "M286.333 206.667l.334.666-.334-.666z", fill: "#38474a" }),
        React.createElement("path", { d: "M190 208l-1 4h1l2-4h-2z", fill: "#974f52" }),
        React.createElement("path", { d: "M287.333 208.667l.334.666-.334-.666z", fill: "#6c7677" }),
        React.createElement("path", { d: "M207 210v1h11l-11-1z", fill: "#667578" }),
        React.createElement("path", { d: "M292 263v-2c-19.541-14.834-37.202-34.014-59-45.676-5.511-2.948-20.659-7.84-26.397-3.296-8.785 6.958 7.179 27.747 11.282 32.971C236.055 268.133 265.801 269.796 292 263z", fill: "#c7f2f9" }),
        React.createElement("path", { d: "M221 211v1h3l-3-1z", fill: "#768d93" }),
        React.createElement("path", { d: "M288.333 211.667l.334.666-.334-.666z", fill: "#2b4045" }),
        React.createElement("path", { d: "M226.667 212.333l.666.334-.666-.334z", fill: "#5a6c6e" }),
        React.createElement("path", { d: "M204 213c-10.444 15.561-8.416 37.244-2 54h1l-4.946-24.015L202 218l3 2-1-7z", fill: "#79b2c6" }),
        React.createElement("path", { d: "M231 214l1 1-1-1z", fill: "#7c9091" }),
        React.createElement("path", { d: "M200 216l-2 8h1l3-8h-2z", fill: "#7d3f58" }),
        React.createElement("path", { d: "M236 217l1 1-1-1z", fill: "#677679" }),
        React.createElement("path", { d: "M239 219l1 1-1-1z", fill: "#5c7176" }),
        React.createElement("path", { d: "M197 220c-7.976 12.068-13.219 26.07-16.857 40-12.498 47.851 28.04 93.478 74.857 96.91 42.202 3.093 75.606-27.294 96.576-60.91 7.484-11.996 16.382-25.415 16.424-40-6.004 1.972-9.899 12.831-13.599 17.907-7.992 10.965-19.926 20.684-30.686 28.808-23.502 17.742-61.411 21.656-86.676 4.47-15.324-10.424-27.024-26.792-36.448-42.47-4.314-7.177-5.311-18.497-4.305-26.715.713-5.819 3.078-12.367.714-18z", fill: "#c2272d" }),
        React.createElement("path", { d: "M240 220l1 1-1-1z", fill: "#879c9f" }),
        React.createElement("path", { d: "M293 221l1 1-1-1z", fill: "#809a99" }),
        React.createElement("path", { d: "M243 222l1 1-1-1z", fill: "#7d8f91" }),
        React.createElement("path", { d: "M294 223l1 1-1-1z", fill: "#668081" }),
        React.createElement("path", { d: "M246 224l1 1-1-1z", fill: "#63787d" }),
        React.createElement("path", { d: "M295 224l1 1-1-1z", fill: "#809898" }),
        React.createElement("path", { d: "M377 224c-2.876 14.625-11.332 28.809-18 42l9-10v5h1c2.804-9.828 11.633-26.871 8-37z", fill: "#9a3642" }),
        React.createElement("path", { d: "M296 226l1 1-1-1z", fill: "#4e6263" }),
        React.createElement("path", { d: "M297 227l1 1-1-1z", fill: "#6b7e82" }),
        React.createElement("path", { d: "M251 228l1 1-1-1z", fill: "#849698" }),
        React.createElement("path", { d: "M298 228l1 1-1-1z", fill: "#869ba0" }),
        React.createElement("path", { d: "M255 231l1 1-1-1z", fill: "#7d9597" }),
        React.createElement("path", { d: "M302 233l1 1-1-1z", fill: "#607276" }),
        React.createElement("path", { d: "M259 234l1 1-1-1z", fill: "#677b7c" }),
        React.createElement("path", { d: "M303 234l1 1-1-1z", fill: "#6e7d82" }),
        React.createElement("path", { d: "M260 235l1 1-1-1z", fill: "#768b8e" }),
        React.createElement("path", { d: "M304 235l1 1-1-1z", fill: "#798a91" }),
        React.createElement("path", { d: "M196 236v12h1l-1-12z", fill: "#7c8a95" }),
        React.createElement("path", { d: "M305 236l1 1-1-1z", fill: "#84969a" }),
        React.createElement("path", { d: "M306 237l1 1-1-1z", fill: "#8c9ea0" }),
        React.createElement("path", { d: "M265 239l1 1-1-1z", fill: "#758386" }),
        React.createElement("path", { d: "M309 240l1 1-1-1z", fill: "#86979e" }),
        React.createElement("path", { d: "M310 241l1 1-1-1z", fill: "#818f98" }),
        React.createElement("path", { d: "M269 242l1 1-1-1z", fill: "#536265" }),
        React.createElement("path", { d: "M311 242l1 1-1-1z", fill: "#809199" }),
        React.createElement("path", { d: "M270 243l1 1-1-1z", fill: "#75878b" }),
        React.createElement("path", { d: "M314 245l1 1-1-1z", fill: "#7f989c" }),
        React.createElement("path", { d: "M274 246l1 1-1-1z", fill: "#4e6165" }),
        React.createElement("path", { d: "M315 246l1 1-1-1z", fill: "#6f8c92" }),
        React.createElement("path", { d: "M352 246l8 4-6 8c8.213-4.311 7.429-10.975-2-12z", fill: "#71b4c7" }),
        React.createElement("path", { d: "M275 247l1 1-1-1z", fill: "#5c7174" }),
        React.createElement("path", { d: "M316 247l1 1-1-1z", fill: "#597b84" }),
        React.createElement("path", { d: "M276 248l1 1-1-1z", fill: "#72858b" }),
        React.createElement("path", { d: "M342 255l14-5-4 8c16.219-7.098-6.497-15.026-10-3z", fill: "#903556" }),
        React.createElement("path", { d: "M197 249v7h1l-1-7z", fill: "#8c7086" }),
        React.createElement("path", { d: "M336 263c10.665 2.869 16.34-3.402 20-13-6.495.864-17.855 6.488-20 13z", fill: "#c4282c" }),
        React.createElement("path", { d: "M359.333 250.667l.334.666-.334-.666z", fill: "#566487" }),
        React.createElement("path", { d: "M331 259v2c6.257 1.017 15.016-5.349 19-10l-19 8z", fill: "#fe8966" }),
        React.createElement("path", { d: "M281 252l1 1-1-1z", fill: "#5c7474" }),
        React.createElement("path", { d: "M282 253l1 1-1-1z", fill: "#798e8f" }),
        React.createElement("path", { d: "M331 258v1l11-6-11 5z", fill: "#6b5351" }),
        React.createElement("path", { d: "M231 256c4.981 13.136 34.178 15.966 46 13.621 4.556-.904 9.429-4.164 14.075-4.214 6.008-.064 6.996 6.12 5.925 10.593 5.661-4.368 3.07-11.248-4.039-12.771-6.155-1.318-14.586 3.242-20.961 3.681-15.069 1.038-28.224-3.219-41-10.91z", fill: "#4dbdcb" }),
        React.createElement("path", { d: "M287 257l1 1-1-1z", fill: "#5d7577" }),
        React.createElement("path", { d: "M288 258l1 1-1-1z", fill: "#6a787b" }),
        React.createElement("path", { d: "M328 258v1l3 1-3-2z", fill: "#717b87" }),
        React.createElement("path", { d: "M348 263l6-5-6 5z", fill: "#7faaba" }),
        React.createElement("path", { d: "M289 259l1 1-1-1z", fill: "#88979c" }),
        React.createElement("path", { d: "M361 260l-3 6 3-6z", fill: "#795e6d" }),
        React.createElement("path", { d: "M335 261c.87 4.97 5.758 7.969 9 3l-9-3z", fill: "#903e42" }),
        React.createElement("path", { d: "M344 266l4-2-4 2z", fill: "#8787a3" }),
        React.createElement("path", { d: "M357 266l-3 6 3-6z", fill: "#844e68" }),
        React.createElement("path", { d: "M378.333 267.667l.334.666-.334-.666z", fill: "#295570" }),
        React.createElement("path", { d: "M320 268c-3.703 11.753-28.082 10.74-32 22 11.655-.008 28.002-6.822 38-12.838 3.813-2.294 8.497-3.761 10-8.162l-16-1z", fill: "#b5292c" }),
        React.createElement("path", { d: "M323 268v1h17l-17-1z", fill: "#6f2824" }),
        React.createElement("path", { d: "M306 269v1h5l-5-1z", fill: "#935c3e" }),
        React.createElement("path", { d: "M287 287c11.77-1.741 24.101-10.691 34-17-12.413-5.209-26.83 8.191-34 17z", fill: "#f78b5a" }),
        React.createElement("path", { d: "M336 271l3-1-3 1z", fill: "#665366" }),
        React.createElement("path", { d: "M288 285l17-14c-6.003 1.681-14.157 8.428-17 14z", fill: "#7b4d40" }),
        React.createElement("path", { d: "M355 271c-8.968 7.42-16.021 17.086-25 24.7-18.71 15.864-41.828 23.355-66 20.3 3.451 3.95 14.006 2.108 19 1.985 21.653-.53 43.593-13.403 58.115-29.075 3.803-4.104 14.674-12.1 13.885-17.91z", fill: "#8d384d" }),
        React.createElement("path", { d: "M328 275v1l6-2-6 1z", fill: "#8093a4" }),
        React.createElement("path", { d: "M343 284l9-11c-4.773 1.526-7.458 6.417-9 11z", fill: "#80b1bf" }),
        React.createElement("path", { d: "M322 279v1l6-4-6 3z", fill: "#86a8b4" }),
        React.createElement("path", { d: "M208 277l10 11c-1.885-4.435-5.309-9.501-10-11z", fill: "#70bbd0" }),
        React.createElement("path", { d: "M299 288v1l21-8c-5.23-2.034-16.056 4.51-21 7z", fill: "#8a3948" }),
        React.createElement("path", { d: "M318 281v1l4-1h-4z", fill: "#899aaa" }),
        React.createElement("path", { d: "M304 287v1c5.442-.448 10.283-1.868 14-6l-14 5z", fill: "#8da9be" }),
        React.createElement("path", { d: "M297 292v-1l-13-2 4-5c-4.168-1.129-7.911 4.103-3.678 6.972 3.135 2.125 9.06 1.028 12.678 1.028z", fill: "#73b0cf" }),
        React.createElement("path", { d: "M284 289h4v-5l-4 5z", fill: "#7c5f64" }),
        React.createElement("path", { d: "M216 288l7 6-7-6z", fill: "#809ca7" }),
        React.createElement("path", { d: "M290 290v1l14-3-14 2z", fill: "#8194a3" }),
        React.createElement("path", { d: "M356 288l-4 8h1l3-8z", fill: "#561114" }),
        React.createElement("path", { d: "M320 303l16-13c-4.804.781-14.492 8.395-16 13z", fill: "#6fb0c6" }),
        React.createElement("path", { d: "M223 294l6 5-6-5z", fill: "#7eb0b9" }),
        React.createElement("path", { d: "M233 303c2.519 5.042 7.625 7.045 13 8v-1l-13-7z", fill: "#962f42" }),
        React.createElement("path", { d: "M302 312l18-8c-5.584-2.151-14.387 3.859-18 8z", fill: "#77b3cb" }),
        React.createElement("path", { d: "M237 305l3 2-3-2z", fill: "#868597" }),
        React.createElement("path", { d: "M239 305c3.721 5.894 12.339 8.336 19 9v-1l-19-8z", fill: "#77a9c4" }),
        React.createElement("path", { d: "M296 313v1l7-1h-7z", fill: "#82525e" }),
        React.createElement("path", { d: "M258 314v1l7 1-7-2z", fill: "#8c899a" }),
        React.createElement("path", { d: "M288 315v1l8-1h-8z", fill: "#79545c" }),
        React.createElement("path", { d: "M265 315v1h23l-23-1z", fill: "#76b5c7" }),
        React.createElement("path", { d: "M267 316v1h16l-16-1z", fill: "#888495" }),
        React.createElement("path", { d: "M342 326v2l2-2h-2z", fill: "#1f5b73" }),
        React.createElement("path", { d: "M336 346l10-10-10 10z", fill: "#4490a0" }),
        React.createElement("path", { d: "M300 348v1l4-1h-4z", fill: "#4e0d13" }),
        React.createElement("path", { d: "M93 369l3 2-3-2z", fill: "#235b6c" }),
        React.createElement("path", { d: "M128.667 377.333l.666.334-.666-.334z", fill: "#234956" }),
        React.createElement("path", { d: "M110.667 379.333l.666.334-.666-.334z", fill: "#0c494e" }),
        React.createElement("path", { d: "M126.667 386.333l.666.334-.666-.334z", fill: "#1a4f61" })));
};
export default HyperJump;
