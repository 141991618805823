var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import { isValidAddress } from '../utils';
var useStyles = makeStyles(function (theme) {
    return createStyles({
        reducedMargin: {
            margin: theme.spacing(3)
        }
    });
});
;
var DefaultCustomInvestment = { tokenAddress: '', name: '', amount: '', link: '' };
var EditCustomInvestmentDialog = function (props) {
    var _a, _b, _c, _d;
    var classes = useStyles();
    var t = useTranslation().t;
    var _e = React.useState(DefaultCustomInvestment), customInvestment = _e[0], setCustomInvestment = _e[1];
    var isEditing = (Boolean((_a = props.customInvestment) === null || _a === void 0 ? void 0 : _a.tokenAddress) ||
        Boolean((_b = props.customInvestment) === null || _b === void 0 ? void 0 : _b.name) ||
        Boolean((_c = props.customInvestment) === null || _c === void 0 ? void 0 : _c.amount) ||
        Boolean((_d = props.customInvestment) === null || _d === void 0 ? void 0 : _d.link));
    useEffect(function () {
        setCustomInvestment(props.customInvestment || DefaultCustomInvestment);
    }, []);
    var handleChange = function (field) { return function (event) {
        var _a;
        setCustomInvestment(__assign(__assign({}, (customInvestment || {})), (_a = {}, _a[field] = event.target.value, _a)));
    }; };
    var handleConfirm = function () {
        props.onClickConfirm(customInvestment, props.userData);
    };
    var hasTokenError = !isValidAddress(customInvestment.tokenAddress);
    var hasNameError = !customInvestment.name;
    var hasAmountError = !customInvestment.amount;
    var hasErrors = hasTokenError || hasNameError || hasAmountError;
    return (React.createElement(Dialog, { open: true, fullWidth: true, classes: { paper: classes.reducedMargin }, onClose: props.onCloseDialog },
        React.createElement(DialogTitle, null, (isEditing) ? t('Edit Custom Investment') : t('Add Custom Investment')),
        React.createElement(DialogContent, null,
            React.createElement(Grid, { container: true, spacing: 1 },
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(TextField, { required: true, error: hasTokenError, className: classes.input, id: "tokenAddress", label: t('Token Address'), onChange: handleChange('tokenAddress'), variant: "outlined", value: customInvestment.tokenAddress, fullWidth: true })),
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(TextField, { required: true, error: hasNameError, className: classes.input, id: "name", label: t('Name'), onChange: handleChange('name'), variant: "outlined", value: customInvestment.name, fullWidth: true })),
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(TextField, { required: true, error: hasAmountError, className: classes.input, id: "amount", label: t('Amount'), onChange: handleChange('amount'), variant: "outlined", value: customInvestment.amount, fullWidth: true })),
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(TextField, { className: classes.input, id: "link", label: t('Link'), onChange: handleChange('link'), variant: "outlined", value: customInvestment.link, fullWidth: true })))),
        React.createElement(DialogActions, null,
            React.createElement(Button, { onClick: props.onCloseDialog, color: "secondary" }, t('Cancel')),
            React.createElement(Button, { onClick: handleConfirm, color: "primary", disabled: hasErrors }, t('Ok')))));
};
export default EditCustomInvestmentDialog;
