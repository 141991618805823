var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDialog } from 'muibox';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DescriptionBox from './DescriptionBox';
import CustomInvestmentsTable from './CustomInvestmentsTable';
import EditCustomInvestmentDialog from './EditCustomInvestmentDialog';
var useStyles = makeStyles(function (theme) {
    return createStyles({
        flexColumn: {
            display: 'flex',
            flexDirection: 'column',
        },
        flexRow: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
        },
        bottomMargin: {
            marginBottom: theme.spacing(2),
        },
    });
});
var CustomInvestments = function (props) {
    var classes = useStyles();
    var dialog = useDialog();
    var t = useTranslation().t;
    var customInvestments = props.customInvestments;
    var _a = useState(), editingCustomInvestment = _a[0], setEditingCustomInvestment = _a[1];
    var handleClickAddCustomInvestment = function () {
        setEditingCustomInvestment({
            customInvestment: { tokenAddress: '', name: '', amount: '', link: '' },
            index: -1
        });
    };
    var handleClickEditCustomInvestment = function (customInvestment, index) {
        setEditingCustomInvestment({ customInvestment: customInvestment, index: index });
    };
    var handleEditCustomInvestment = function (customInvestment, userData) {
        var newCustomInvestments = __spreadArray([], customInvestments);
        if (userData >= 0) {
            newCustomInvestments[userData] = customInvestment;
        }
        else {
            newCustomInvestments.push(customInvestment);
        }
        props.onChange(newCustomInvestments);
        setEditingCustomInvestment(undefined);
    };
    var handleDeleteCustomInvestment = function (customInvestment, userData) {
        dialog.confirm({
            title: t('Remove Custom Investment'),
            message: t('CONFIRM_REMOVE', { name: customInvestment.name })
        })
            .then(function () {
            var newCustomInvestments = __spreadArray([], customInvestments);
            newCustomInvestments.splice(userData, 1);
            props.onChange(newCustomInvestments);
        });
    };
    var handleCloseEditCustomInvestmentDialog = function () {
        setEditingCustomInvestment(undefined);
    };
    return (React.createElement(React.Fragment, null,
        Boolean(editingCustomInvestment) &&
            React.createElement(EditCustomInvestmentDialog, { customInvestment: editingCustomInvestment === null || editingCustomInvestment === void 0 ? void 0 : editingCustomInvestment.customInvestment, userData: editingCustomInvestment === null || editingCustomInvestment === void 0 ? void 0 : editingCustomInvestment.index, onCloseDialog: handleCloseEditCustomInvestmentDialog, onClickConfirm: handleEditCustomInvestment }),
        React.createElement(Box, { className: classes.flexColumn },
            React.createElement(Box, { className: classes.flexRow },
                React.createElement(DescriptionBox, { text: t('Custom Investments'), description: t('Configure investments that cannot be tracked automatically'), className: classes.bottomMargin }),
                React.createElement(IconButton, { onClick: handleClickAddCustomInvestment },
                    React.createElement(AddCircleOutlineIcon, null))),
            React.createElement(CustomInvestmentsTable, { customInvestments: customInvestments, onClickEdit: handleClickEditCustomInvestment, onClickDelete: handleDeleteCustomInvestment }))));
};
export default CustomInvestments;
