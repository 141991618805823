import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
var useStyles = makeStyles(function (theme) {
    return createStyles({
        footer: {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(2)
        }
    });
});
var Copyright = function () {
    var classes = useStyles();
    return (React.createElement(Typography, { className: classes.footer, variant: "body2", color: "textSecondary", align: "center" },
        'Copyright © ',
        React.createElement(Link, { color: "inherit", href: "https://walletnow.net/" }, "WalletNow"),
        ' ',
        new Date().getFullYear(),
        '.'));
};
export default Copyright;
