var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
var PCS = function (props) {
    return (React.createElement(SvgIcon, __assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 40 40", xmlSpace: "preserve", width: 40, height: 40 }, props, { key: "pcs" }),
        React.createElement("style", { type: "text/css", id: "style833" }, ".pancakeswap_style{fill:#633001}"),
        React.createElement("circle", { id: "pancakeswap_path852", cx: 20, cy: 20, r: 20, fill: "#4fd9de", stroke: "#fff", strokeWidth: 0, fillOpacity: 1 }),
        React.createElement("g", { id: "pancakeswap_g861", transform: "translate(4.15 4)" },
            React.createElement("path", { d: "M5.7 5c-.5-2.6 1.5-5 4.1-5C12.1 0 14 1.9 14 4.2v5.2c.6 0 1.2-.1 1.8-.1.6 0 1.1 0 1.7.1V4.2c0-2.3 1.9-4.2 4.2-4.2 2.6 0 4.6 2.4 4.2 5l-1.1 6.1c3.9 1.7 6.9 4.7 6.9 8.4v2.3c0 3.1-2 5.7-4.9 7.4-2.9 1.8-6.7 2.8-11 2.8s-8.1-1-11-2.8C2 27.5 0 24.9 0 21.8v-2.3c0-3.7 2.9-6.7 6.8-8.4zm17.6 6.9l1.3-7.2c.3-1.8-1-3.5-2.9-3.5-1.6 0-2.9 1.3-2.9 2.9v6.6c-.4-.1-.9-.1-1.3-.1-.6 0-1.1-.1-1.7-.1-.6 0-1.2 0-1.8.1-.4 0-.9.1-1.3.1V4.2c0-1.6-1.3-2.9-2.9-2.9C8 1.3 6.6 3 7 4.8L8.3 12c-4.2 1.6-7 4.4-7 7.6v2.3c0 4.9 6.5 8.9 14.5 8.9s14.5-4 14.5-8.9v-2.3c.1-3.3-2.7-6.1-7-7.7z", id: "pancakeswap_path835", fillRule: "evenodd", clipRule: "evenodd", fill: "#633001" }),
            React.createElement("path", { d: "M30.4 21.8c0 4.9-6.5 8.9-14.5 8.9s-14.5-4-14.5-8.9v-2.3h29.1v2.3z", id: "pancakeswap_path837", fill: "#fedc90" }),
            React.createElement("path", { d: "M7 4.8C6.6 3 8 1.3 9.8 1.3c1.6 0 2.9 1.3 2.9 2.9v6.6c1-.1 2-.2 3.1-.2 1 0 2 .1 3 .2V4.2c0-1.6 1.3-2.9 2.9-2.9 1.8 0 3.2 1.7 2.9 3.5L23.3 12c4.2 1.6 7.1 4.4 7.1 7.6 0 4.9-6.5 8.9-14.5 8.9s-14.5-4-14.5-8.9c0-3.2 2.8-6 7-7.6z", id: "pancakeswap_path839", fillRule: "evenodd", clipRule: "evenodd", fill: "#d1884f" }),
            React.createElement("path", { className: "pancakeswap_style", d: "M11.8 18.9c0 1.3-.7 2.4-1.6 2.4-.9 0-1.6-1.1-1.6-2.4 0-1.3.7-2.4 1.6-2.4.9 0 1.6 1.1 1.6 2.4z", id: "pancakeswap_path841" }),
            React.createElement("path", { className: "pancakeswap_style", d: "M22.9 18.9c0 1.3-.7 2.4-1.6 2.4-.9 0-1.6-1.1-1.6-2.4 0-1.3.7-2.4 1.6-2.4.9 0 1.6 1.1 1.6 2.4z", id: "pancakeswap_path843" }))));
};
export default PCS;
